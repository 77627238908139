import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthStatus } from "../interface/auth-status";
import { WelcomeModel } from "../interface/welcome-model";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { EventsService } from "../services/events.service";
import moment from 'moment';
import { Badge } from '@ionic-native/badge/ngx';
enum APPLICATION_MODES {DEBUG,PRODUCTION};
enum CONSOLE_LOCATION {BROWSER,DATABASE};
@Injectable({
  providedIn: "root",
})
export class AppserviceService {
  private applicationName: string = null;
  private pathSeparator: string = "/";
  private userNodeName: string = "users";
  private toastControllerDuration: number = 3000;
  private authModel: AuthStatus;
  private welcomeModel: WelcomeModel = null;
  private greatRestaurantQuote: string = null;
  public user_devices: string = "user_devices";
  private uuid: string = null;
  public hookahAddItemPage:any;
  public liquorAddItemPage:any;
  // isDebugMode:boolean = false;
  private defaultCountryCode:string = "91";
  isDebugMode:boolean = false;

  loginStatus:boolean = false; ///\brief used as a global login status flag

  areweconnected:boolean=false;

  isMobile:boolean = false; ///\brief global flag to detect whether or not we are in a mobile device
  currentUser:any = null;
  private LoginPageStateMap:object;
  private loginTextString:string = "login";
  private logoutTextString:string = "logout";
  private notificationSoundURL = 'https://freesound.org/data/previews/254/254819_4597795-lq.mp3';
  private notificationTitleDefault = "Restaurant notification";
  private networkDisconnectMessage = "network was disconnected :-(";
  private networkTimeOutObject:any;
  private countryCode = null;
  private tenDigitPhoneNumber:number = null;
  private nameRegex:RegExp = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/ ;
  private phoneRegex:RegExp = /^[\d]{10}$/;
  private emailRegex:RegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  private fourDigitNumberRegex:any =/\d{4}$/;
  private queryHighCode:string = '\uf8ff'
  private spaceTabReplacementRegex = /^\s+|\s+$/g;
  private timeoutDuration = 5000;
  // private InfoList:Observable<any>;
  // private displayList:Observable<any>;
  // private List:Observable<any>;
  public isPlatformNativeMobile:boolean = false;
  public isPlatformMobile:boolean = false;
  public isWeb:boolean;
  private itemsListArray:Array<any> = [];
  private firebaseProjectName:any  = 'asansolcollege-6bdb4_firebaseapp_com';
  private RestaurantinfoNode:string='';
  private userPathName:string = "users";
  private userNode:any = null;
  private InsertMenuNode:any = null;
  public waiters:string="waiters";
  public appUser:string="app_user";
  private	table:string="Tables";
  private	rooms:string="Rooms";
  public resto_info:string="Resto_Info";
  private Cashier:string="Cashier";
  private cashier_details:string="Cashier_details";
  private OrderBillpaid:string="Orders-Bill-paid";
  private FinalReceiptBill:string="final-receipt-bill";
private  menu_items:string="Menu_items";
 private vacant_table:string="vacant-tables";
  private allocated_table:string= "Allocated-tables";
private table_info:string="table-Info";
  private CurrentOrders:string="Current-Orders";
  private BookOrderForBill:string="Booked-Orders-for-Bill";
private BillCounter:string="Bill_Counter";
  private qty:number;
  private rate:number;
  private amount:number;
  private totalAmount:number;
  private orderList:Array<any> = [];
  private dataRegistrationStore:Array<any> = [];
  private InfoRegistrationStore:Array<any> = [];
  private dataRoomStore:Array<any> = [];
  private dataTableStore:Array<any> = [];
  private OrderedListStore:Array<any> = [];
  private calculatedAmount:Array<any> = []; // define Array for calculate Amount
  private dataInfoRegistrationStore:Array<any>=[];
  private db:firebase.database.Database;
  private basePath:string = "";
  private applicationVersion:string ="0.0.0";
  private applicationPackageName:string ="";
  private appStateTag:string = "app_state"
  private configPath:string = null;
  private wordSeparator:string = "_";
  private transactionIdPrefix = "KR-"
  private userNodeRef:any;
  private recaptchaValue:string;
  private isPhoneNumberObtainedFromSim:boolean = false;
  private completePhoneNumberObtainedFromSim:string = null;
  private countryCodeObtainedFromSim:string = null;
  private simSerialNumber:string = null;
  private deviceId:string = null;
  private subscriberId:string = null;
  private carrierName:string = null;
  private simMCC:string = null;
  private simMNC:string = null;
  private OrderListConfirm:boolean;
  private appName:string;
  private gstDefaultPolicy:boolean = false;
  private gstDefaultRate:number = null;
  private newUserSignupModel:string;
  private homePage:any;
  private longPressInterval:number=400;
  public adminHome:any;
  public hookahMenuEditPage:any;
  private InfoList:Observable<any>;
  private displayList:Observable<any>;
  private List:Observable<any>;
  public liquorMenuEditPage:any;
  private APPLICATION_MODE = APPLICATION_MODES.PRODUCTION;
  private logMode:number = CONSOLE_LOCATION.BROWSER;
  public hookahAddItemModalPage:any;
  public addProduct:any;
  public AddStockPage:any;
  public additemModalPage:any;
  public adminDisplayCashier:any;
  public adminMenuAddTable:any;
  public adminMenuCardAddItem:any;
  public adminMenuCardDeleteItem:any;
  public adminMenuCardEditItem:any;
  public adminReceivedBill:any;
  public adminReports:any;
  public adminWaiterInfoPage:any;
  public adminRestoInfoPage:any;
  public cashierLoginPage:any;
  public changeModule:any;
  public consumtionDetails:any;
  public deviceRegister:any;
  public disPlayDeviceRequest:any;
  public displayTable:any;
  public loginHardPage:any;
  public makebill:any;
  public menuBarCodeScanner:any;
  public menuPrint:any;
  public orderHistory:any;
  public orderFinish:any;
  public print:any;
  public qrPage:any;
  public resetPasswordPage:any;
  public restaurantInfo:any;
  public restoInfoDelete:any;
  public restoInfoDisplay:any;
  public restoInfoEdit:any;
  public settingsPage:any;
  public signupPage:any;
  public stockDetailsPage:any;
  public waiterHome:any;
  public waiterLogin:any;
  public waiterModule:any;
  public adminMenuCardAddMember:any;
  public database_json:any=null;
  public cloudant_auth:any=null;
  public get_uid:string=null;
  public getFirebase:any;


  public tipsJson:any=null;
  public setComplimentaryItemArray:any;
  constructor(
    private badge:Badge,
    private platform: Platform,
    private storage: Storage,
    private uniqueDeviceID: UniqueDeviceID,
    private afDB: AngularFireDatabase,
    private events:EventsService
  ) {
    this.cloudant_auth = environment.cloudantAuth;
    this.longPressInterval = environment.longPressInterval;
    this.gstDefaultPolicy = environment.gstDefaultPolicy;
    this.gstDefaultRate = environment.gstDefaultRate;
    this.newUserSignupModel = environment.pageConfigs.loginPage.newUserSignupModel;
    this.gstDefaultPolicy = environment.gstDefaultPolicy;
    this.newUserSignupModel = environment.pageConfigs.loginPage.newUserSignupModel;
    this.homePage = environment.pageConfigs.homePage;
    this.database_json = environment.databaseConfig;
    this.defaultCountryCode = String(environment.defaultCountryCode);
    this.LoginPageStateMap = new Object();
    this.storage.get("app_name").then((data)=>{
      if(data){
        this.applicationName = data;
      }else {
        this.applicationName = environment.appName;
        this.storage.set("app_name",this.applicationName).then(()=>{
          console.log("successfully stored data");
        }).catch((e)=>{
          console.log("caught error",e);
        });
      }
    }).catch((e)=>{
      console.log("error retrieving data from local storage",e);
    });

    this.initializeAppServiceProvider();
    this.db = firebase.database();
  /** tips add */
     this.tipsJson = {
       title:"Show your support with a small tip",
       description:"It's not just extra earning - it feels nice to be appreciated once in a while for the good work done daily",
       amount:[30,50,70,100]
      }
    /**
     *Read the configuration variables from the database
     */
    ///This is the global config path
    this.configPath = environment.appPaths.config;
    //This is the global word separator path
    let globalVarsPathWordSeparator =  this.configPath + this.pathSeparator + environment.appPaths.wordSeparator;
    ///Readng global configuration variables from firebase database
    let ref = firebase.database().ref(globalVarsPathWordSeparator);

    ref.once("value",(snapshot)=>{
      this.wordSeparator = snapshot.val();
      console.log("my global wordSeparator:",this.wordSeparator);
    }).then((success)=>{
      console.log("success handler");
    }).catch((err)=>{
   //   console.log("error handler",err);
    });

    ///Get application name dynamically
    let appNamePath =  this.configPath + this.pathSeparator + environment.appPaths.appName;
    let refAppName = this.db.ref(appNamePath);

    // refAppName.once("value",(snapshot)=>{
    //   this.applicationName = snapshot.val();
    //   this.userPathName = this.applicationName + this.pathSeparator  +  this.userNodeName;
    //   this.userNodeRef = this.afDB.database.ref(this.userPathName);
    // }).then(()=>{
    // }).catch((e)=>{
    // });

    this.adminHome = environment.pageConfigs.adminHome;
    this.greatRestaurantQuote = "Treat your taste buds to cuisines from all around the world. Enjoy a mesmerising array of dishes on your holiday. Pamper yourself with some mouthwatering savory dishes and sinful desserts at our restaurant. The soulful ambience and our attentive staff along with amazing food gurantees a comfortable and enjoyable experience for you.";
  
  
    //Get application version dynamically
    let appVersionPath = this.configPath + this.pathSeparator + environment.appPaths.appVersion;
    let refAppVersion = firebase.database().ref(appVersionPath);
    refAppVersion.once("value",(snapshot)=>{
      this.applicationVersion = snapshot.val();
      console.log("applicationVersion",this.applicationVersion);
    });
    let packageNamePath = this.configPath + this.pathSeparator + environment.appPaths.packageName
    let refPackageName = firebase.database().ref(packageNamePath);
    refPackageName.once("value",(snapshot)=>{
      this.applicationVersion = snapshot.val();
      console.log("packageName",this.applicationVersion);
    });
    let toastControllerDurationPath = this.configPath + this.pathSeparator + environment.appPaths.toastControllerDuration;
    let refToastControllerDuration = firebase.database().ref(toastControllerDurationPath);
    refToastControllerDuration.once("value",(snapshot)=>{
      this.toastControllerDuration = snapshot.val();
      console.log("toastControllerDuration",this.toastControllerDuration);
    });
    this.dataRegistrationStore = new Array<any>();
    this.OrderedListStore = new Array<any>();
    this.dataInfoRegistrationStore= new Array<any>();

    //used for hard codes removed
    this.addProduct = environment.pageConfigs.addProduct;
    this.AddStockPage = environment.pageConfigs.AddStockPage;
    this.additemModalPage = environment.pageConfigs.additemModalPage;
    this.adminDisplayCashier = environment.pageConfigs.adminDisplayCashier;
    this.adminHome = environment.pageConfigs.adminHome;
    this.adminMenuAddTable = environment.pageConfigs.adminMenuAddTable;
    this.adminMenuCardAddItem = environment.pageConfigs.adminMenuCardAddItem;
    this.adminMenuCardDeleteItem = environment.pageConfigs.adminMenuCardDeleteItem;
    this.adminMenuCardEditItem = environment.pageConfigs.adminMenuCardEditItem;
    this.adminReceivedBill = environment.pageConfigs.adminReceivedBill;
    this.adminReports = environment.pageConfigs.adminReports;
    this.adminWaiterInfoPage = environment.pageConfigs.adminWaiterInfoPage;
    this.adminRestoInfoPage = environment.pageConfigs.adminRestoInfoPage;
    this.cashierLoginPage = environment.pageConfigs.cashierLoginPage;
    this.changeModule = environment.pageConfigs.changeModule;
    this.consumtionDetails = environment.pageConfigs.consumtionDetails;
    this.deviceRegister = environment.pageConfigs.deviceRegister;
    this.disPlayDeviceRequest = environment.pageConfigs.disPlayDeviceRequest;
    this.displayTable = environment.pageConfigs.displayTable;
    this.loginHardPage = environment.pageConfigs.loginHardPage;
    this.makebill = environment.pageConfigs.makebill;
    this.menuBarCodeScanner = environment.pageConfigs.makebill;
    this.menuPrint = environment.pageConfigs.menuPrint;
    this.orderHistory = environment.pageConfigs.orderHistory;
    this.orderFinish = environment.pageConfigs.orderFinish;
    this.print = environment.pageConfigs.print;
    this.qrPage = environment.pageConfigs.qrPage;
    this.resetPasswordPage = environment.pageConfigs.resetPasswordPage;
    this.restaurantInfo = environment.pageConfigs.restaurantInfo;
    this.restoInfoDelete = environment.pageConfigs.restoInfoDelete;
    this.restoInfoDisplay = environment.pageConfigs.restoInfoDisplay;
    this.restoInfoEdit = environment.pageConfigs.restoInfoEdit;
    this.settingsPage = environment.pageConfigs.settingsPage;
    this.signupPage = environment.pageConfigs.signupPage;
    this.stockDetailsPage = environment.pageConfigs.stockDetailsPage;
    this.waiterHome = environment.pageConfigs.waiterHome;
    this.waiterLogin = environment.pageConfigs.waiterLogin;
    this.waiterModule = environment.pageConfigs.waiterModule;
    this.adminMenuCardAddMember = environment.pageConfigs.adminMenuCardAddMember;
    this.hookahAddItemPage = environment.pageConfigs.HookahAddItemPage;
    this.hookahAddItemModalPage = environment.pageConfigs.HookahAddItemModalPage;
    this.hookahMenuEditPage = environment.pageConfigs.HookahMenuEditPage;
    this.liquorAddItemPage = environment.pageConfigs.LiquorAddItemPage;
    this.liquorMenuEditPage = environment.pageConfigs.LiquorMenuEditPage;
  }

  initializeAppServiceProvider() {
    this.authModel = {loginStatus:"login"};
    this.welcomeModel = {message:"Restora"};
    this.events.subscribe('user:login', (user) => {
      this.loginStatus = true;
      // user and time are the same arguments passed in `events.publish(user, time)`
      console.log('Welcome', user);
      this.authModel.loginStatus = "logout";
    });
    this.events.subscribe('user:logout', (user) => {
      this.loginStatus = false;
      this.authModel.loginStatus = "login";
      // user and time are the same arguments passed in `events.publish(user, time)`
      console.log('bye', user);
    });
  }
getTips(){
  return this.tipsJson;
}
  getadminMenuCardAddItem(){
    return this.adminMenuCardAddItem;
  }

  getGSTDefaultPolicy(){
    //    return this.gstDefaultPolicy;
    console.log(this.gstDefaultPolicy);
    return (this.gstDefaultPolicy);

  }
  console_err(...messages: any[]){
    if(this.APPLICATION_MODE == APPLICATION_MODES.DEBUG){
    if(this.logMode == CONSOLE_LOCATION.BROWSER){
    this.console_err(messages);
    }
    if(this.logMode == CONSOLE_LOCATION.DATABASE){
    this.log_to_database(messages);
    }
    }
    }
  getwaiterModule(){
    return this.waiterModule;
  }
  generateItemIndex():number{
    return Math.floor(1000 + Math.random() * 9000);
  }
  getcashierLoginPage(){
    return this.cashierLoginPage;
  }
  getLongPressInterval(){
    return this.longPressInterval;
    }
    generateWaiterCode():number{
      return Math.floor(1000 + Math.random() * 9000);
    }

  getAppName(): string {
    this.applicationName = environment.appName;
    console.log(this.applicationName);
    return this.applicationName;
  }

  public getNameRegex = ()=>{
    return this.nameRegex;
  }

  getadminDisplayCashier(){
    return this.adminDisplayCashier;
  }
  getconsumtionDetails(){
    return this.consumtionDetails; 
  }
  getsignupPage(){
    return this.signupPage;
  }
  getloginHardPage(){
    return this.loginHardPage;
  }
  getDefaultCountryCode():string{
    return this.defaultCountryCode;
  }
  public getPhoneRegex():RegExp{
    return this.phoneRegex;
  }
  gethomePageButtonName(){
    return this.homePage;
  }
  getNewUserSignupModel():string{
    return this.newUserSignupModel;
  }
  getTimeoutDuration() {
    return this.timeoutDuration;
  }
  getadminWaiterInfoPage(){
    return this.adminWaiterInfoPage;
  }
  getFirebaseDB():firebase.database.Database{
    return this.db;
  }

  badgeRaise(num:number = 1){
    this.badge.increase(num);
  }
  getresetPasswordPage(){
    return this.resetPasswordPage;
  }
  getadminHome(){
    return this.adminHome;
  }

  getPathSeparator(): string {
    return this.pathSeparator;
  }
  getdeviceRegister(){
    return this.deviceRegister;
  }
  getUserNodeName(): string {
    return this.userNodeName;
  }

  getdisPlayDeviceRequest(){
    return this.disPlayDeviceRequest;
  }
  getToastControllerDuration(): number {
    return this.toastControllerDuration;
  }

  getGreatRestaurantQuote() {
    return this.greatRestaurantQuote;
  }

  getAuthModel(): AuthStatus {
    return this.authModel;
  }
  getliquorAddItemPage(){
    return this.liquorAddItemPage;
  }
  getWelcomeModel(): WelcomeModel {
    /*let restro= this.authProvider.getRestroInfo();
  console.log(restro);*/
  console.log("this.welcomeModel :", this.welcomeModel);
  
    return this.welcomeModel;

  }

  getUserDevices() {
    return this.user_devices;
  }
  getadminMenuCardAddMember(){
    return this.adminMenuCardAddMember;
  }
  log_to_database(...messages:any[]){
  }
  console_log(...messages: any[]){
    if(this.APPLICATION_MODE == APPLICATION_MODES.DEBUG){
      if(this.logMode == CONSOLE_LOCATION.BROWSER){
        this.console_log(messages);
      }
      if(this.logMode == CONSOLE_LOCATION.DATABASE){
        this.log_to_database(messages);
      }
    }
  }

  /**
   *userDevice function is to get uuid of user **
   */

  userDevice() {
    return new Promise<any>((resolve, reject) => {
      if (this.platform.is("ios") || this.platform.is("android")) {
        this.storage
          .get("uuid")
          .then((uid) => {
            if (!uid) {
              this.uniqueDeviceID
                .get()
                .then((uuid: any) => {
                  this.storage.set("uuid", uuid);
                  this.uuid = uuid;
                  resolve(this.uuid);
                })
                .catch((e) => {
                  reject(e);
                });
            } else if (uid) {
              this.uuid = uid;
              resolve(this.uuid);
            }
          })
          .catch((e) => {
            reject(e);
          });
      } 
      else {
        // ********* uuid for windows **************
        this.storage
          .get("uuid")
          .then((uid) => {
            if (!uid) {
              console.log("uidexistnot");
              // let uuidv1 = require("uuid/v1");
              // let id = uuidv1();
              let id = "windowa"
              this.storage.set("uuid", id);
              this.uuid = id;
              resolve(this.uuid);
            } else if (uid) {
              this.uuid = uid;
              resolve(this.uuid);
              console.log("uidexist");
            }
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }
  getresto():string{
    return this.resto_info;
  }
  badgeLower(num:number = 1){
    this.badge.decrease(num);
  }
  getappUser():string{
    return this.appUser;
  }
  getWaiters():string{
    return this.waiters;
  }
  getMenuItems():string{
    return this.menu_items;
  }
  getCashierDetails():string{
    return this.cashier_details;
  }
  getTables():string{
    return this.table;
  }
  getRooms():string{
    return this.rooms;
  }
  getTableInfo():string{
    return this.table_info;
  }
  getVacantTables():string{
    return this.vacant_table;
  }
  getAllocatedTables():string{
    return this.allocated_table;
  }
  public getEmailRegex():RegExp{
    return this.emailRegex;
  }

  getLoginStatus():boolean{
    // console.log(this.loginStatus);
   return this.loginStatus;
 }
 setLoginStatus(loginStatus){
  console.log(loginStatus);
  this.loginStatus = loginStatus;
}
setAuthModel(model){
  this.authModel.loginStatus = model;
  console.log("model :",this.authModel.loginStatus);
}
setUser(user:any):void {
  this.currentUser = user;
}
getUserNodePath():string{
  return this.userPathName;
}
stringCleanLower(s):string{
  if(s){
    return this.stringClean(s).toLowerCase();
  }
  return s;
}
stringClean(s):string{
  if(s){
    return s.replace(/\s/g,"");
  }
  return s;
}


getadminMenuCardDeleteItem(){
  return this.adminMenuCardDeleteItem;
}
getOrderBillpaid():string{
  return this.OrderBillpaid;
}
getadminReceivedBill(){
  return this.adminReceivedBill;
}
getadminRestoInfoPage(){
  return this.adminRestoInfoPage;
}

getprint(){
  return this.print;
}

getrestaurantInfo(){
  return this.restaurantInfo;
}
getrestoInfoDelete(){
  return this.restoInfoDelete;
}
getmakebill(){
  return this.makebill;
}
getorderFinish(){
  return this.orderFinish;
}

getorderHistory(){
  return this.orderHistory;
}
getBillCounter(){
  return this.BillCounter;
    }

  getmenuPrint(){
    console.log(this.menuPrint);
    
    return this.menuPrint;
  }

// getadminMenuCardEditItem(){
//   return this.adminMenuCardEditItem;
// }

getchangeModule(){
  return this.changeModule;
}
getstockDetailsPage(){
  return this.stockDetailsPage;
}
getBookOrderForBill():string{
  return this.BookOrderForBill;
}
 ///Transaction ID factory
 getTransactionId(){
  let now =  moment().millisecond();
  let data = moment().format('YYYYMMDD');
  let times = moment().format('HHmmss');
  return this.transactionIdPrefix + times + now;
}
getOrderListConfirm(){
  return this.OrderListConfirm;
}
getUserNodeRef(){
  return this.userNodeRef;
}

getadminMenuAddTable(){
  return this.adminMenuAddTable;
}
getCurrentUser(){
  //return this.afAuth.user;
  return firebase.auth().currentUser;
}

getCurrentOrders():string{
  return this.CurrentOrders;
}
getaddProduct(){
  return this.addProduct;
}

getAddStockPage(){
  return this.AddStockPage;
}
//cashier generate code
generateCashierCode():number{
  return Math.floor(1000 + Math.random() * 9000);
}
getrestoInfoEdit(){
  return this.restoInfoEdit;
}
getrestoInfoDisplay(){
  return this.restoInfoDisplay;
}

getappVersion(){

  let version=null; //this.appVersion.getVersionNumber();
  console.log(version);
  return version;
 }
getCashier():string{
    return this.Cashier;
  }
  getadditemModalPage(){
    return this.additemModalPage;
  }
  getSpaceTabReplacementRegex():RegExp{
    if(this.spaceTabReplacementRegex)
{
    return  this.spaceTabReplacementRegex;
    }
    else{
      console.log("error");
    }
  }

  getQueryHighCode():string{
    return this.queryHighCode;
  }
  getFinalReceiptBill():string{
    return this.FinalReceiptBill;
  }
 getwaiterLogin(){
  return this.waiterLogin;
}
getsettingsPage(){
  return this.settingsPage;
}

getdisplayTable(){
  return this.displayTable;
}

registerTables(data){
  this.dataTableStore=data;
}

gethookahAddItemPage(){
  return this.hookahAddItemPage;
}
gethookahAddItemModalPage(){
  return this.hookahAddItemModalPage;
}
gethookahMenuEditPage(){
  return this.hookahMenuEditPage;
}

getliquorMenuEditPage(){
  return this.liquorMenuEditPage;
}

// /**
//    * getDatabaseConfig function is for gettting all database name in json 
//    * @returns { json } -  json object return ,
//    */
  getDatabaseConfig(){
    return this.database_json;
  }

//   /**
//  * getCloudantAuth function is for returning cloudant authantication data ,
//  * @param { void } - a void param.
//  * @return { json }- a sjson of cloudant authantication .
//  */
getCloudantAuth(){
  return this.cloudant_auth;
}

// /**
//  * getUid function ids for get uid of admin ;
//  * after admin login or staff registration uid of admin is storing in localstorage this function return that uid,
//  * @return { string } -  uid of admin as a string.
//  * @param { void } - a void param.
//  * this is for seperate database based on admin uid .
//  */
async getUid(){
  return new Promise<any>(async (resolve,reject)=>{
    this.storage.get('user').then(async(user)=>{
    console.log("user type",user);
   if(user=='external'){
    await   this.storage.get('userFireKey').then((data)=>{
      console.log("get user's admin  uid",data);  
      this.get_uid = data;
      resolve(this.get_uid);
    }).catch((e)=>{
      console.log(e);
      reject(e);
    });

  }
  else { 
        await this.storage.get('adminFireKey').then((data)=>{
          console.log("get admin  uid",data); 
          this.get_uid = data; 
          resolve(this.get_uid);
        }).catch((e)=>{
          console.log(e);
          reject(e);
        });
  }
 
}).catch(e=>{
  console.log(e);
  reject(e);
});
});
}


  setComplimentaryItem(data){
    this.setComplimentaryItemArray = data;

  }

  getComplimentaryItem(){
    return this.setComplimentaryItemArray
  }
  getFirestore(){
    this.getFirebase=firebase.firestore();
    return this.getFirebase;
 }
}
