import {  
  Injectable  
} from '@angular/core';  
import {  
  Observable  
} from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {  
  public permission: Permission;  
  constructor() {  
      //this.permission = this.isSupported() ? 'default' : 'denied';  
    this.permission = 'granted';
  }  
  public isSupported(): boolean {  
      return 'Notification' in window;  
  }  
  requestPermission(): void {  
      let self = this;  
      if ('Notification' in window) {  
          Notification.requestPermission(function(status) {  
              return self.permission = status;  
          });  
      }  
  }  
  create(title: string, options ? : PushNotification): any {  
      let self = this;  
      return new Observable(function(obs) {  
          if (!('Notification' in window)) {  
              console.log('Notifications are not available in this environment');  
              obs.complete();  
          }  
          if (self.permission !== 'granted') {  
              console.log("The user hasn't granted you permission to send push notifications");  
              obs.complete();  
          }  
          let audioObj= new Audio("../../assets/sounds/notification.mp3")
          let _notify = new Notification(title, options);  
          let timer;
          _notify.onshow = function (e) { 
            let count = 0;
              //Audio to be played when a notification will arrive on screen.
            //let audioObj= new Audio("../../assets/sounds/notification.mp3")
            // audioObj.addEventListener("canplaythrough", event => {
                audioObj.play();  
              // timer = setInterval(function () { //Start Here
              //     count++;
                  
              //     if (count == 3) {
              //         audioObj.pause();
              //         clearInterval(timer)
              //     }
              //     else {
              //       audioObj.play();
              //     }
              // }, 5000); //To Here
        //   })
              return obs.next({  
                  notification: _notify,  
                  event: e  
              });  
          };  
          _notify.onclick = function (e) {  
              //clearInterval(timer);
            audioObj.pause();
          window.open("https://food-resto-98e67.web.app/dashboard/incoming-order");
          //window.open("http://localhost:8100/dashboard/incoming-order");
              return obs.next({  
                  notification: _notify,  
                  event: e  
              });  
          };  
          _notify.onerror = function(e) {  
              return obs.error({  
                  notification: _notify,  
                  event: e  
              });  
          };  
          _notify.onclose = function() {  
              return obs.complete();  
          };  
      });  
  }  
  // generateNotification(source: Array < any > ): void {  
  //     let self = this;  
  //     source.forEach((item) => {  
  //         let options = {  
  //             body: item.alertContent,  
  //             icon: "../resource/images/bell-icon.png"  
  //         };  
  //         let notify = self.create(item.title, options).subscribe();  
  //     })  
  // }  
  generateNotification(source): void {  
    let self = this;  
    let options = {  
              body: source.alertContent,  
        icon: "../../assets/imgs/only_logo.png",
        //renotify: true,
        sound: "../../assets/sounds/notification.mp3",
        silent: false,
          };  
      // source.forEach((item) => {  
      //     let options = {  
      //         body: item.alertContent,  
      //         icon: "../resource/images/bell-icon.png"  
      //     };  
      //     let notify = self.create(item.title, options).subscribe();  
      // })  
      let notify = self.create(source.title, options).subscribe(); 
      
  }
}  
export declare type Permission = 'denied' | 'granted' | 'default';  
export interface PushNotification {  
  body ? : string;  
  icon ? : string;  
  tag ? : string;  
  data ? : any;  
  renotify ? : boolean;  
  silent ? : boolean;  
  sound ? : string;  
  noscreen ? : boolean;  
  sticky ? : boolean;  
  dir ? : 'auto' | 'ltr' | 'rtl';  
  lang ? : string;  
  vibrate ? : number[];  
}  
