/************************************** COMPONENT PAGE MODULE ***************************************/
/**
 * Restaurant management component page
 * Component is the entry point of the entire application
 * Everything starts after platform ready event.
 *
 */
 import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
 import { Device } from '@ionic-native/device/ngx';
 import { AlertController, Platform, ToastController,LoadingController, NavController } from '@ionic/angular';
 import { SplashScreen } from '@ionic-native/splash-screen/ngx';
 import { StatusBar } from '@ionic-native/status-bar/ngx';
 import { Observable } from 'rxjs';
 import { UserDevice } from './interface/user-device';
 import { Address } from './interface/address';
 import { UserData } from './interface/user-data';
 import { AppserviceService } from './services/appservice.service';
 import { AngularFireDatabase } from '@angular/fire/database';
 import { EventsService } from './services/events.service';
 import { environment } from '../environments/environment';
 // import { Device } from './interface/device';
 import { AuthserviceService } from './services/authservice.service';
 import { AngularFireAuth } from '@angular/fire/auth';
 import { StorageService } from './services/storage.service';
 import { Storage } from '@ionic/storage';
 import { Printer } from '@ionic-native/printer/ngx';
 import { NavigationExtras, Router } from '@angular/router';
 import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
 import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
 import { NetworkInterface } from '@ionic-native/network-interface/ngx';
 import { Network } from '@ionic-native/network/ngx';
 import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
 import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
 import { Badge } from '@ionic-native/badge/ngx';
 import { BatteryStatus } from '@ionic-native/battery-status/ngx';
 import { FCM } from '@ionic-native/fcm/ngx';
 import { Push, PushObject, PushOptions, Priority } from '@ionic-native/push/ngx';
 import * as UserDeviceInit from '../app/interface/user-device-init.json'
 import moment from 'moment';
 import { Geolocation } from '@ionic-native/geolocation/ngx';
 import * as firebase from 'firebase';
 import {GeoLocationService} from '../../src/app/services/geo-location.service';
 import { PouchdbauthService } from '../../src/app/services/pouchdbauth.service';
 // import * as distance from 'google-distance';
 import { AuthTypes } from "./interface/auth-types";
 import {RoleType } from './interface/RoleType';
 import {ModalController} from '@ionic/angular';
 import { TCPage } from './pages/tc/tc.page';
 import { timer } from 'rxjs';
 @Component({
   selector: 'app-root',
   templateUrl: 'app.component.html',
   styleUrls: ['app.component.scss']
 })
 export class AppComponent {
 
 
   private disconnectSubscription:any;
   // @ViewChild(Nav) nav: Nav;
   @Input() data: string[];
   private userDataObserver: any = null;
   private userDataAccess: any = null;
   xmlItems:any;
   rootPage:  any;
    getlocationWatch:any = '';
   platformKey:string = 'platform';
   UID:any = '';
   uuidWebGenerated:any = '';
   ///This should come from a config file
   appName:any = 'RestaurantManagement';
   ///This should come from a config file
   firebaseProjectName:any  = 'restaurant-27f85';
   userNode:any= '';
   userPhone:any= '';
   userDevicePath:string = '';
   userNodePath:any = '';
   pathSeparator:string = "/";
   userPathName:string = "users";
   uuid:string;
   adminid:string=null;
   uidexist:boolean;
   private notificationText = "";
   /**
    * Additional information about the user is stored here
    */
   userMorePathName:string = "more";
   notificationBadgeCount:number = 10;
   /**
    *
    * Initialize the user device here
    */
   userDevice:UserDevice = <UserDevice>(UserDeviceInit);
    address:Address = <Address>{
     "fullName":null,
     "streetAddress":null,
     "village":null,
     "postOffice":null,
     "city":null,
     "state":null,
     "postalCode":null,
     "country":null
   };
   /**
    * A current device is defined by the fact that the device was used in the last available calendar date
    * */
   userData:UserData = {
     "firstName":null,
     "lastName":null,
     "phoneNumber":null,
     "address":null,
     "gpsLocation":null,
     "deviceIDCurrent":[null],
     "deviceIDHistory":[null],
     "lastAppOpenTime":null,
     "lastActivityTime":null,
     "device":[null],
     "currentDevice":null,
   };
   public staffDisable:boolean;
   public isDeveloper:boolean;
   public adminDisable:boolean;
   public loading:any;
   pages: Array<{title: string, component: any}>;
   private headers: Headers = new Headers();
   private isMobile:boolean = false;
   private mobileType:string = null;
   private userDataObservable:any;
   private values: Array<number> = [];
   private observableData: Observable<number>;
   private anyErrors: boolean;
   private finished: boolean;
   private deviceDetails : any;
   public pagecall: string = null;
   public adminData: any = null;
   private toastControllerDuration:number = 3000;
   private pushNotificationPriority:Priority = 3;
   private pushOptions: PushOptions = {
     android: {
       sound:true
     },
     ios: {
       alert: 'true',
       badge: true,
       sound: 'false'
     },
     windows: {},
     browser: {
       pushServiceURL: 'http://push.api.phonegap.com/v1/push'
     }
   };
   private userNodeRef:any;
   public isWeb:any;
   public msg: string = null;
   public showSplash=true;
   constructor(
     private push: Push,
     public loadingCtrl:LoadingController,
     private platform: Platform,
     private splashScreen: SplashScreen,
     private statusBar: StatusBar,
     private appServiceProvider: AppserviceService,
     private storage: Storage,
     private localNotifications: LocalNotifications,
     private networkInterface:NetworkInterface,
     private network: Network,
     public navCtrl:NavController,
     private modalCtrl: ModalController,
     private firebaseAnalytics: FirebaseAnalytics,
     private ga: GoogleAnalytics,
     public geolocationService:GeoLocationService,
     private afAuth:AngularFireAuth,
     private badge:Badge,
   private printer : Printer,
     private authProvider:AuthserviceService,
     private toastController:ToastController,
     public alertCtrl:AlertController,
     public afDB: AngularFireDatabase,
     private events:EventsService,
     private device:Device,
     private storageServiceProvider:StorageService,
     //private geolocation: Geolocation,
     public fcm: FCM,
     private  batteryStatus: BatteryStatus,
     private uniqueDeviceID: UniqueDeviceID,
     // private http:Http,
     public pouchProvider: PouchdbauthService,
     private changeDetectorRef: ChangeDetectorRef,
   ) {
     // this.userdevice();
     this.appName = this.appServiceProvider.getAppName();
     this.toastControllerDuration = this.appServiceProvider.getToastControllerDuration();
     // this.headers = new Headers();
     // this.headers.append('Content-Type', 'text/xml');
     // this.headers.append('Access-Control-Allow-Methods', 'GET')
     // this.headers.append('Access-Control-Allow-Origin', '*')
     // this.headers.append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method");
     this.initializeApp();
     this.address.fullName = 'John Doe';
     this.address.postOffice = 'Kolkata';
     this.address.country = 'IN';
     this.address.postalCode = '700107';
     console.log("device:",this.userDevice);
     console.log("address",this.address);
     this.userData.currentDevice = this.userDevice;
     this.userData.address = this.address;
     this.userData.firstName = 'John';
     //this.userDevice.spaceLeft = 0;
     //this.userDevice.deviceIP = this.networkInterface.getWiFiIPAddress();
     //this.userDevice.deviceCarrierIP =this.networkInterface.getCarrierIPAddress();
     this.pages = [
       { title: 'Admin', component: 'LoginPage' },
       { title: 'User', component: 'AdminHomePage' },
     ];
     let userNodeName = "RestaurantManagement/users";
     console.log("userNodeName:",userNodeName);
     this.userNodeRef = this.afDB.database.ref(userNodeName);
   }
 
   async initializeApp() {
     this.platform.ready().then(async (readyResources) => {
      // this.pouchProvider.menuRef(); 
       //this.pouchProvider.getReference();
       // self.addEventListener('push', function(event) {
       //   const promiseChain = self.registration.showNotification('Hello, World.');
       
       //   event.waitUntil(promiseChain);
       // });
       
       await this.platform_specific_initializations();
       this.geolocationService.getCurrentLoaction();
       if (this.platform.is('ios') || this.platform.is('android')) {
         this.ga.startTrackerWithId('UA-121810468-1')
       .then(() => {
         console.log('Google analytics is ready now');
         this.ga.trackView('test');
         //alert('analytics is ready');
         // Tracker is ready
         // You can now track pages or set additional information such as AppVersion or UserId
       })
       .catch(e => console.log('Error starting GoogleAnalytics', e));
       }
       this.deviceDetails = readyResources;
       this.statusBar.styleDefault();
       this.splashScreen.hide();
        await this.initializeUrl();
     });
   }
 
 
   /***
    * isPlatformMobile() Determins whether or not the platform is mobile (both underlying and native)
    *@param {void} -A void param.
    *@returns {tupple} -A variable to plateform type check  Return
    */
   isPlatformMobile = () => {
     let platformType: [boolean, boolean] = [false, false]; ///Example of a tuple data type
     const isUnderlyingPlatformMobile = (this.platform.is('ios') || this.platform.is('android'));
     const urlString: string = document.URL;
     const localhostRegexpHttp: RegExp = RegExp("http:\/\/localhost.*");
     const localhostRegexpHttps: RegExp = RegExp("https:\/\/localhost.*");
     const hostnameRegex:RegExp = RegExp("https://food-resto-98e67.firebaseapp.com");
     const localhostRegexpHttpTestResult: boolean = localhostRegexpHttp.test(urlString);
     const localhostRegexpHttpsTestResult: boolean = localhostRegexpHttp.test(urlString);
     const hostedNameResult:boolean = hostnameRegex.test(urlString);
     if ((localhostRegexpHttpTestResult || localhostRegexpHttpsTestResult || hostedNameResult) && isUnderlyingPlatformMobile) {
       platformType = [isUnderlyingPlatformMobile, true]
     } else {
       platformType = [isUnderlyingPlatformMobile, false]
     }
     return platformType;
   }
 
   platform_specific_initializations = () => {
     let platformType: [boolean, boolean] = this.isPlatformMobile();
     this.appServiceProvider.isPlatformMobile = platformType[0];
     this.appServiceProvider.isPlatformNativeMobile = platformType[1];
     this.isWeb = ( document.URL.startsWith('http://localhost:')|| document.URL.search('https://food-resto-98e67.firebaseapp.com'));
     this.appServiceProvider.isWeb = this.isWeb;
   }
 
 async initializeUrl(){
   this.storage.get('user').then(async(user) => {
     console.log(user);
     if (user == RoleType[RoleType.Admin]) {
       let AuthObj = this.afAuth.authState.subscribe((usr) => {
         console.log(usr);
         if (usr && usr.isAnonymous != true) {
           this.appServiceProvider.setUser(usr);
           this.appServiceProvider.setLoginStatus(true);
           this.appServiceProvider.setAuthModel("logout");
          
           if (usr.email && usr.email != null) {
             console.log(usr.email);
             this.authProvider.nodepath_insideuser_path(usr.email);
           } else if (usr.phoneNumber && usr.phoneNumber != null) {
             console.log(usr.phoneNumber);
             this.authProvider.nodepath_insideuser_path(usr.phoneNumber);
           }
        //   this.pouchProvider.getReference();
           AuthObj.unsubscribe();
 
         } else {
           this.storage.set('userEmail', null);
           this.storage.set('userPhone', null);
           this.appServiceProvider.setUser(null);
           this.appServiceProvider.setAuthModel("login");
           this.appServiceProvider.setLoginStatus(false);
          // this.pouchProvider.getReference();
           AuthObj.unsubscribe();
 
         }
       });
       console.log(this.appServiceProvider.getLoginStatus());
     }
     else if (user ==RoleType[RoleType.developer]) {
       //this.navCtrl.navigateRoot("DeveloperDashboardPage");
     }
     else if (user ==RoleType[RoleType.Super_Admin]) {
       //this.navCtrl.navigateRoot("DeveloperDashboardPage");
     }
     else if (user == "external") {
       let   adminData:any=null;
       this.storage.get('moduleid').then((moduleid) => {
         // ***** fire query ****
         if (moduleid && moduleid.adminid != null && moduleid.uid != null) {
           //this.pouchProvider.getReference();
          
         }
        
       }).catch(e => {
         let line = 335;
         let error = "error on initializeUrl";
         //this.pouchProvider.registerError(e, line, error);
       });
     }else{
       console.log('ia m gwrw');
       // this.pouchProvider.getReference();
      //await this.pouchProvider.menuRef();
     }
   }).catch(e => {
     let line = 340;
     let error = "error on initializeUrl";
     //this.pouchProvider.registerError(e, line, error);
   });
 
 }
 //   openPage(page) {
 //     this.nav.setRoot(page.component);
 // }
 // Delete a named push notification channel
 deletePushNotificationChannel(channelName){
   // Delete a channel (Android O and above)
   this.push.deleteChannel(channelName).then(() => console.log('Channel', channelName, 'deleted'));
 }
 createPushNotificationChannel(){
   // Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
   this.push.createChannel({
     id: 'testchannel1',
     description: this.appName + ' push notification',
     // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
     importance: this.pushNotificationPriority
   }).then(() => console.log('Channel created'));
   this.push.listChannels().then(channels => {
     for (const channel of channels) {
       console.log(`ID: ${channel.id} Description: ${channel.description}`);
 
     }
   });
   // Return a list of currently configured channels
   const pushObject: PushObject = this.push.init(this.pushOptions);
   pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));
   pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
   pushObject.on('registration').subscribe((data: any) => {
     console.log('device token -> ' + data.registrationId);
     const token = data.registrationId;
     this.uniqueDeviceID.get()
       .then((uuid: any) => {
         console.log("uuid:",uuid);
         this.afAuth.authState.subscribe((user)=>{
           if(user) {
             let email = user.email;
             let phone = user.phoneNumber;
            if(email){
               this.userNodeRef.orderByChild("email").equalTo(email).once("value",(snapshot)=>{
                 if(snapshot.val()){
                   console.log("snapshot:",snapshot.val());
                   let json:object = snapshot.val();
                   for(let key in json) {
                              if(!snapshot.child(key).child("devices").exists()){
                                        let newDevice = snapshot.child(key).child("devices").ref.push();
                                    newDevice.update({"uuid":uuid,"token":token,details:this.device}).then(()=>{
                                    }).catch(()=>console.log("device registration unsuccessful"))
                     }else {
                       console.log("device already exist...reading value");
                       //Check if this particular device exist or not
                       let newNode = snapshot.child(key).child("devices").ref;
                       newNode.orderByChild("uuid").equalTo(uuid).once("value",(deviceSnapshot)=>{
                         if(deviceSnapshot.val() == null){
                           console.log("device:",uuid,"does not exist...creating one");
                           let newDevice = snapshot.child(key).child("devices").ref.push();
                           newDevice.update({"uuid":uuid,"token":token,details:this.device}).then(()=>{});
                         }else{
                           console.log("existing device:",deviceSnapshot.val());
                         }
                       })
                       newNode.once("value",(childSnapshot)=>{
                         console.log("all devices:",childSnapshot.val());
                       });
                     }
                   }
                 }else {
                   console.log("email match not found:",email);
                 }
               }).catch((e)=>{
                 console.log("caught error while searching for email",email,e);
               });
             }else {
               //No valid user so do something else
               console.log("sorry email does not match",email);
             }
           }else {//Phone or other auths
           }
         })})
     //TODO - send device token to server
   });
   pushObject.on('notification').subscribe(async (data: any) => {
     console.log('message -> ' + data.message);
     //if user using app and push notification comes
     if (data.additionalData.foreground) {
       // if application open, show popup
       let confirmAlert = await this.alertCtrl.create({
         header: 'New Notification',
         message: data.message,
         buttons: [{
           text: 'Ignore',
           role: 'cancel'
         }, {
           text: 'View',
           handler: () => {
             //TODO: Your logic here
             //this.nav.push(DetailsPage, { message: data.message });
           }
         }]
       });
       await confirmAlert.present();
     } else {
       //if user NOT using app and push notification comes
       //TODO: Your logic on click of push notification directly
       //this.nav.push(DetailsPage, { message: data.message });
       console.log('Push notification clicked');
     }
   });
   pushObject.on('error').subscribe(error => console.error('Error with Push plugin' + error));
 }
 checkPushNotificationPermission(){
   // to check if we have permission
   this.push.hasPermission()
     .then((res: any) => {
       if (res.isEnabled) {
         console.log('We have permission to send push notifications');
       } else {
         console.log('We do not have permission to send push notifications');
       }
     });
 }
 
 triggerLocalNotification():void {
   let date = new Date();
   ///Determine if it is AM or PM
   let formattedTime = moment(date, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A');
   this.notificationText = "Good ";
   console.log("formattedTime:",formattedTime);
   let formattedTimeArray = formattedTime.split(" ");
   console.log(formattedTimeArray[0],formattedTimeArray[1]);
   let isAM = (formattedTimeArray[1] == "AM"? true: false)
   console.log("isAM:",isAM);
   let hourArray = formattedTimeArray[0].split(":");
   let hour:number = Number(hourArray[0]);
   console.log(hour);
   console.log(!isAM);
   ///Create the salutation based on the hour of the day
   if(!isAM){
     if(hour<6){
       this.notificationText += "afternoon";
     }
     else if(hour>6 && hour <9){
       this.notificationText += "evening";
     }else {
       this.notificationText += "night";
     }
   }
   if(isAM){
     if(hour<4){
       this.notificationText += "night";
     }
     else if(hour>4 && hour<12){
       this.notificationText += "Morning!!!!!!!!";
     }
   }
   ///Local notification currently works only on mobile devices
   if(this.isMobile) {
     console.log("isphone",this.isMobile);
     //Here are the different kinds of callbacks
     this.localNotifications.on('trigger').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'Received notification ',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     this.localNotifications.on('click').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'notification clicked',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     this.localNotifications.on('schedule').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'notification scheduled',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     //click, update, clear, clearall, cancel, cancelall.
     this.localNotifications.on('update').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'notification updated',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     this.localNotifications.on('clear').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'notification cleared',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     this.localNotifications.on('cancel').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'Notification cleared',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
     this.localNotifications.on('cancelall').subscribe(async (data) =>{
       let toast = await this.toastController.create({
         message: 'All notifications cleared',
         duration: this.toastControllerDuration,
         position: 'top'
       });
       await toast.present();
     });
   }
 }
 
 
   gelocationUpdate():void{
     // this.geolocation.getCurrentPosition().then((resp) => {
     //   resp.coords.latitude
     //   resp.coords.longitude
     //   console.log("got geolocation of user",resp);
     //   if(this.userDevice.gpsLocationCurrent){
     //     console.log("updatinge geolocation of device");
     //     this.userDevice.gpsLocationLast = this.userDevice.gpsLocationCurrent ;
     //     console.log("locationLast",this.userDevice.gpsLocationLast);
     //   }
     //   this.userDevice.gpsLocationCurrent.longitude = resp.coords.longitude;
     //   this.userDevice.gpsLocationCurrent.latitude = resp.coords.latitude;
     //   console.log("updating user",this.userDevice.gpsLocationCurrent);
     //   this.updateUser();
     // }).catch((error) => {
     //   console.log('Error getting location', error);
     // });
   }
   batteryStatusCheck(){
     const subscription = this.batteryStatus.onChange().subscribe(batteryStatus => {
       this.userData.currentDevice.batteryStatus= batteryStatus;
       this.updateUser();
     });
     // stop watch
     subscription.unsubscribe();
   }
   bookKeepingOperations():void{//Doigng some book keeping opeartions after initial boot
     console.log("perforning book keeping operations");
     this.observableData = new Observable<number>(observer => {
       setTimeout(() => {
         observer.next(42);
       }, 1000);
       setTimeout(() => {
         observer.next(43);
       }, 2000);
       setTimeout(() => {
         observer.complete();
       }, 3000);
     });
     let subscription = this.observableData.subscribe(
       (value) => {
         this.values.push(value);
         console.log("value:",value);
         console.log("values:",this.values);
       },
       error => this.anyErrors = true,
       () => this.finished = true
     );
     ///Checking the status of the battery
     this.batteryStatusCheck();
     setTimeout(()=>{
       this.userData.firstName = "Boon";
     },5000);
     //test section ends
     //firebase messaging
     const messaging = firebase.messaging();
     messaging.requestPermission().then(() =>{
       console.log('Notification permission granted.');
       this.fcm.getToken().then(token => {
         this.userData.currentDevice.firebaseToken = token;
         console.log("firebase token:",token);
         this.updateUser();
         // Your best bet is to here store the token on the user's profile on the
         // Firebase database, so that when you want to send notifications to this
         // specific user you can do it from Cloud Functions.
       });
       // TODO(developer): Retrieve an Instance ID token for use with FCM.
       // gg...
       //
     }).catch(function(err) {
       //alert("no token "+ err);
       console.log('Unable to get permission to notify.', err);
     });
     // Get Instance ID token. Initially this makes a network call, once retrieved
     // subsequent calls to getToken will return from cache.
     messaging.getToken().then(function(currentToken) {
       if (currentToken) {
         //We have to fill these pseudo codes
         //sendTokenToServer(currentToken);
         //updateUIForPushEnabled(currentToken);
       } else {
         // Show permission request.
         console.log('No Instance ID token available. Request permission to generate one.');
         // Show permission UI.
         //updateUIForPushPermissionRequired();
         //setTokenSentToServer(false);
       }
     }).catch(function(err) {
       console.log('An error occurred while retrieving token. ', err);
       //showToken('Error retrieving Instance ID token. ', err);
       //setTokenSentToServer(false);
     });
     /*****************************************************************/
     // this.getlocationWatch = this.geolocation.watchPosition();
     this.getlocationWatch.subscribe((data) => {
       let origin = data.longitude + "," + data.latitude;
       let destination = data.longitude + "," + data.latitude;
       //Jus tfor test setting origin and destination same
       console.log("origin:",origin);
       console.log("destination:",destination);
     
       console.log("geolocation changed",data);
         });
   
      }
 //   //Update he userData node when new information is available
   updateUser():void{
    this.afAuth.authState.subscribe((usr)=>{
       console.log("user:",usr);
       if(usr) {
         console.log("user already logged in..");
         let userMorePath = this.appName + this.pathSeparator + this.userNodePath + this.pathSeparator + this.userMorePathName;
             }else {
         console.log("user NOT logged in...not updating user state");
       }
     });
     let user  = firebase.auth().currentUser;
     console.log("updating currentuser:",user);
     if (user) { //If there is a current user
       this.userNodePath = this.appName + this.pathSeparator + this.userPathName;
       if(user.phoneNumber){
         this.userNodePath += this.pathSeparator + user.phoneNumber;
       }else if(user.email){
         this.userNodePath += this.pathSeparator + user.email;
       }else{//check other conditions here
       }
       //if userNode is not initialized initialize here
       if(!this.userNode)
           this.userNode = this.afDB.database.ref(this.userNodePath);
           this.userNode.update(this.userData);
     } else if(this.isMobile){//Do stuff fore m
      //need for an anonymous user
       //
       this.uniqueDeviceID.get()
         .then((uuid: any) => {
           console.log(uuid);
           this.userNodePath = this.appName + this.pathSeparator + this.userPathName  + this.pathSeparator + "uuid-" + uuid;
           if(!this.userNode)
             this.userNode = this.afDB.database.ref(this.userNodePath);
         //  this.userNode.update(this.userData);
         })
         .catch((error: any) => console.log(error));
     }else{
       this.userNodePath = this.appName + this.pathSeparator + this.userPathName  + this.pathSeparator + "web-uuid-" + this.uuidWebGenerated;
       console.log("user node path:",this.userNodePath);
       this.userNode = this.afDB.database.ref(this.userNodePath);
     }
   }
   private saveTokenToFirestore(token) {
     if (!token) return;
     const devicesRef = this.afDB.database.ref(this.userDevicePath)
     const docData = { token, userId: 'testUser' }
     return devicesRef.update(docData)
   }
   async buttonTC(){
     console.log("T@C");
     let customerModal = await this.modalCtrl.create({
       component: TCPage,
       backdropDismiss: false
     });
     await customerModal.present();
   }
   // refresh() {
   //   this.changeDetectorRef.detectChanges();
   // }
   // admin() {
   //   this.adminDisable=true;
   //   console.log("enable after 5 seconds");
 
   //   let userval = "admin";
   //   this.storage
   //     .set("user", userval)
   //     .then(() => {
   //       this.LogIn();
   //       console.log("login");
   //     })
   //     .catch((e) => {
   
   //       console.log("param", e);
 
   //     });
   // }
   // async LogIn() {
 
   //   this.loading = await this.loadingCtrl.create({
   //     spinner: 'bubbles',
   //     message: 'Please wait...',
   //     cssClass: 'my-custom-class',
   //   });
   //   await this.loading.present();
 
   //   this.storage.get('user').then((user) => {
   //     console.log(user);
   //     if (user == "admin") {
   //       let AuthObj = this.afAuth.authState.subscribe((usr) => {
   //         console.log(usr);
   //         if (usr && usr.isAnonymous != true) {
   //           this.appServiceProvider.setUser(usr);
   //           this.appServiceProvider.setLoginStatus(true);
   //           this.appServiceProvider.setAuthModel("logout");
   //           console.log("get into : ", environment.loginPage);
   //           this.navCtrl.navigateRoot("/dashboard").then(() => {
   //             this.loading.dismiss();
   //           }).catch(e => {
   //             let line = 153;
   //             let error = "error on push auth Landing Page";
   //             this.pouchProvider.registerError(e, line, error);
   //           });
   //           // if (usr.email && usr.email != null) {
   //           //   console.log(usr.email);
   //           //   this.authProvider.nodepath_insideuser_path(usr.email);
   //           // } else if (usr.phoneNumber && usr.phoneNumber != null) {
   //           //   console.log(usr.phoneNumber);
   //           //   this.authProvider.nodepath_insideuser_path(usr.phoneNumber);
   //           // }
 
   //           AuthObj.unsubscribe();
 
   //         } else {
   //           this.storage.set('userEmail', null);
   //           this.storage.set('userPhone', null);
   //           this.appServiceProvider.setUser(null);
   //           this.appServiceProvider.setAuthModel("login");
   //           this.appServiceProvider.setLoginStatus(false);
   //           console.log("get into : ", environment.loginPage);
   //           this.navCtrl.navigateRoot(environment.loginPage).then(() => {
   //             this.loading.dismiss();
   //           }).catch(e => {
   //             let line = 175;
   //             let error = "error on push auth login Page";
   //             this.pouchProvider.registerError(e, line, error);
   //           });
   //           AuthObj.unsubscribe();
 
   //         }
   //       });
   //       console.log(this.appServiceProvider.getLoginStatus());
   //     }
   //     else if (user == "developer") {
   //       this.navCtrl.navigateRoot("DeveloperDashboardPage");
   //     }
   //     else if (user == "external") {
   //       console.log("external user");
   //       this.storage.get('moduleid').then((moduleid) => {
   //         // ***** fire query ****
   //         if (moduleid && moduleid.adminid != null && moduleid.uid != null) {
   //           let regexEmail = this.appServiceProvider.getEmailRegex();
 
   //           //**** do Anonimas login after checking uuid******
   //           let adminid = moduleid.adminid;
   //           let uuid = moduleid.uid;
   //           let page = moduleid.pagecall;
   //           console.log("moduleid", moduleid);
   //           let isValid = regexEmail.test(adminid);
   //           if (isValid) {
   //             this.adminData = {
   //               "authType": AuthTypes.email,
   //               "authValue": adminid
   //             }
   //           }
   //           else {
   //             this.adminData = {
   //               "authType": AuthTypes.phone,
   //               "authValue": adminid
   //             }
   //           }
   //           //***** get admin firebase key by functions
   //           this.authProvider.getauthData(this.adminData).then((fire) => {
   //             console.log("firekey", fire);
   //             let firekey = fire.adminId;
   //             let userDeviceNode: string;
   //             if (firekey) {
   //               console.log(firekey);
   //               userDeviceNode = "user_devices";
   //               //**** check user uid is present or not by functions if present then check allow flag if it is true  login anonimas
   //               let param = {
   //                 "path": userDeviceNode,
   //                 "uuid": uuid
   //               }
   //               this.authProvider.getusersUUID(param).then(snap => {
   //                 console.log("snap data", snap);
   //                 if (snap != null && snap != false) {
 
   //                   if (snap.devicefor == "Waiter" && snap.allow == true) {
   //                     let log = {
   //                       'login_user': 'waiter',
   //                       'login_type': 'waiter'
   //                     }
   //                     this.storage.set('login_info', log).then(() => {
   //                       // this.navCtrl.setRoot("WaiterModulePage");
   //                     }).catch(e => {
   //                       let line = 235;
   //                       let error = "error on set login info";
   //                       this.pouchProvider.registerError(e, line, error);
   //                     });
   //                     this.pagecall = environment.waiterModule;
   //                     this.AnonymousLogin();
   //                     this.loading.dismiss();
   //                   }
 
   //                   else if (snap.devicefor == "Cashier" && snap.allow == true) {
   //                     this.pagecall = environment.cashierModule;
   //                     console.log("pagecall :", this.pagecall);
                       
   //                     this.AnonymousLogin();
   //                     this.loading.dismiss();
   //                   }
 
   //                   // else if(snap.devicefor=="Kitchen"&&snap.allow==true){
   //                   //   this.pagecall=environment.kitchenModule;
   //                   //   this.AnonymousLogin();
   //                   //   loading.dismiss();
   //                   // }
 
   //                   else if (snap.allow == false) {
   //                     console.log("snap val ",snap);
   //                     this.msg = "Please wait till your request accept by admin ";
   //                     this.pagecall = null;
   //                     let arlt: any = this.alertMsg();
                      
   //                   }
   //                   if (this.pagecall != null) {
   //                     let moduleid = {
   //                       "uid": uuid,
   //                       "adminid": adminid,
   //                       "pagecall": this.pagecall
   //                     }
   //                     this.storage.set('moduleid', moduleid);
   //                   }
   //                   //****** page call
   //                 }
   //                 else if (!snap) {
   //                   let moduleid = {
   //                     "uid": null,
   //                     "adminid": null,
   //                     "pagecall": null
   //                   }
   //                   this.storage.set('moduleid', moduleid).then(() => {
   //                     this.navCtrl.navigateForward(environment.deviceRegister).then(() => {
   //                       this.loading.dismiss();
   //                     }).catch(e => {
   //                       let line = 284;
   //                       let error = "error on push device Register page";
   //                       this.pouchProvider.registerError(e, line, error);
   //                     });
   //                     //this.pagecall=environment.deviceRegister;
   //                   }).catch(e => {
   //                     let line = 289;
   //                     let error = "error on set module id";
   //                     this.pouchProvider.registerError(e, line, error);
   //                   });
 
   //                 }
 
   //                 else {
   //                   console.log("not exicuted");
   //                 }
 
   //               }).catch(e => {
   //                 let line = 302;
   //                 let error = "error on set uuid";
   //                 this.pouchProvider.registerError(e, line, error);
   //               });
 
   //             }
   //             else {
 
   //               console.log("removed");
   //               this.msg = "Admin Not Found"
   //               this.alertMsg();
   //               this.storage.remove('moduleid');
   //             }
   //           }).catch(e => {
   //             let line = 315;
   //             let error = "error on get auth data";
   //             this.pouchProvider.registerError(e, line, error);
   //           });
 
   //         }
   //         else {
   //           console.log("device register call ");
   //           //****Module info  not register in local storage *********
   //           this.navCtrl.navigateForward(environment.deviceRegister).then(() => {
   //             this.loading.dismiss();
 
   //           }).catch(e => {
   //             let line = 327;
   //             let error = "error on push device register";
   //             this.pouchProvider.registerError(e, line, error);
   //           });
 
   //         }
   //       }).catch(e => {
   //         let line = 335;
   //         let error = "error on get module id";
   //         this.pouchProvider.registerError(e, line, error);
   //       });
   //     }
   //   }).catch(e => {
   //     let line = 340;
   //     let error = "error on get user";
   //     this.pouchProvider.registerError(e, line, error);
   //   });
   //   setTimeout(() => {
   //     this.loading.dismiss();
   //   }, 10000);
 
   // }
   //  setup(){
   //   this.storage.get('user').then((user) => {
   //     if (user && user == "developer") {
   //       this.isDeveloper = true;
   //       console.log(user);
   //       this.changeDetectorRef.detectChanges();
   //     }
   //   }).catch((e) => {
   //     console.log(e);
   //     let line = 123;
   //     let error = "error in admin-home";
   //     this.pouchProvider.registerError(e, line, error);
   //   });
   //  }
 
 
   // async alertMsg() {
   //   if (this.msg) {
   //     console.log("errr", this.msg);
   //     //****** if uuid not present or allow is false then call device reg page
   //     let alert = await  this.alertCtrl.create({
   //       message: this.msg,
   //       buttons: [
   //         {
   //           text: "OK",
   //           role: "cancel",
   //           handler: () => {
   //             this.navCtrl.navigateForward('/deviceregister').then(() => {
   //               console.log("deviceRegister page");
 
   //             });
   //           },
   //         },
   //       ],
   //       cssClass: "alertCustomCss",
   //     });
 
   //     await  alert.present().then(() => {
   //       if(this.loading){
   //       this.loading.dismiss();
   //       }
   //     }).catch((e) => {
   //       console.log(e);
   //       let line = 285;
   //       let error = "error in admin-home";
   //       this.pouchProvider.registerError(e, line, error);
   //     });
   //   }
   // }
 
 //  /**
 //    * AnonymoousLogin is for users Login
 //    */
 //   AnonymousLogin() {
 
 //     let uuid = this.appServiceProvider.userDevice().then((uuid) => {
 //       this.uuid = uuid;
 //       console.log("ANONYMOUS LOGIN");
 //       //****** lonin users
 //       let AuthObj = this.afAuth.authState.subscribe((usr) => {
 //         console.log(usr);
 //         if (usr) {
 //           // don't hv login per.as admin already logedin *******
 //           this.authProvider.signoutUser().then(() => {
 //             this.authProvider.nodepath_insideuser_path(this.adminData.authValue);
 
 //             this.authProvider.createAnonymousUser().then((user) => {
 //               let navigationExtras: NavigationExtras = {
 //                 queryParams: {
 //                   frompage: "home"
 //                 }
 //               };
 //               console.log("navigationExtras :", navigationExtras);
 //               this.navCtrl.navigateForward(this.pagecall, navigationExtras);
 //               console.log(user.user.isAnonymous, "anony");
 
 //             }).catch((e) => {
             
 
 //             });
 
 //             AuthObj.unsubscribe();
 //           }).catch((e) => {
 
 //           });
 //         }
 //         else {
 //           this.authProvider.nodepath_insideuser_path(this.adminData.authValue);
 //           this.authProvider.createAnonymousUser().then((user) => {
 //             console.log(user.user.isAnonymous, "anony");
 //             console.log(this.adminData, "user", user);
 //             this.navCtrl.navigateForward(this.pagecall);
 //             AuthObj.unsubscribe();
 
 //           }).catch((e) => {
           
 
 //           });
 
 //         }
 //       });
 //     }).catch((e) => {
 
 //       console.log(e);
 //     });
 
 //   }
 
 //   async logoutbtn(){
 //     let alert = await this.alertCtrl.create({
 //       header: 'Hi',
 //       message: 'Do you want to logout ?',
 //       buttons: [
 //         {
 //           text: 'No',
 //           role: 'cancel',
 //           handler: () => {
 //             console.log('Cancel clicked');
 //           }
 //         },
 //         {
 //           text: 'Yes',
 //           handler: () => {
 //            this.logout();
 //           }
 //         }
 //       ],cssClass:'alertCustomCss'
 //     });
 //     alert.present();
   
 //   }
 
 // /**
 //    * user is for selected users module
 //    */
 //   user() {
 //     this.staffDisable=true;
 //     setTimeout(x => {
 //       this.staffDisable=false;
 //     }, 5000)
 //     console.log("enable after 5 seconds");
 //     this.storage.set('fromPage', "home");
 //     let userval = "external";
 //     this.storage.set('user', userval).then(() => {
 //       this.LogIn();
 //     }).catch((e) => {
       
 //       console.log("catch param :",e);
 
 //     });
 
 //   }
 //   logout(){
 //     this.changeDetectorRef.detectChanges();
 //     this.authProvider.signoutUser().then(async()=>{
 //       //*******reset userFireKey from localstoreg********
 //       this.storage.set('userFireKey',null);
 //      console.log("logout");
      
 //       let toast= await this.toastController.create({
 //         message: 'User logged out successfully',
 //         duration: this.toastControllerDuration,
 //         position: 'bottom',
 //         cssClass:'handlerclass',
 //       });
 //        toast.onDidDismiss();
 //        await toast.present().then(()=>{
 //       }).catch((e)=>{
 //         this.appServiceProvider.console_log("Error presenting toast",e);
 //       });
 
 
 //       this.navCtrl.navigateRoot("/home");
 //     }).catch((e)=>{
 //       this.appServiceProvider.console_log("Error signing out",e);
 //     });
 //   } /**
 //   * developers function is for call developer moidule.
 //   */
 //  developers() {
 //    this.navCtrl.navigateRoot("developer-dashboard");
 //  }
 }
