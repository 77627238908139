// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isDebugMode:false,
  clientKey: 'e3cc97c6b89300c2a6c3ab0908e048ae',
  baseUrl: 'https://developers.zomato.com/api/v2.1',
  key: "rzp_live_upFm8PpLNJV0cW",
  firebaseConfig: {
    apiKey: "AIzaSyCJ0wTHkf_hwLgipKY6Xfz3dSKGES1wo2M",
    authDomain: "restaurant-27f85.firebaseapp.com",
    databaseURL: "https://restaurant-27f85.firebaseio.com",
    projectId: "restaurant-27f85",
    storageBucket: "restaurant-27f85.appspot.com",
    messagingSenderId: "143222487121"
  },
  userPath:"users",
  defaultCountryCode:91,
  longPressInterval:40,
  appName:"RestaurantManagement",
  rootPage:"home",
 // authPage:"LoginPage",
  authPage:"home",
 loginPage:"login",
  signupPage:"signup",
  resetPasswordPage:"reset-password",
  superAdminLanding:"superadmin-dashboard",
  mastersuperAdminLanding:"mastersuperadmin-dashboard",
  developerDashboard:"developer-dashboard",
  authLandingPage:"dashboard",
  waiterModule:"waiter-login",
  cashierModule:"cashier-login",
  kitchenModule:"KitchenviewPage",
  deviceRegister:"deviceregister",
  gstDefaultPolicy:true,
  gstDefaultRate:5,
  appPaths:{config:"config",
    wordSeparator:"wordSeparator",
    toastControllerDuration:"toastControllerDuration",
    appName:"appName",
    appVersion:"appVersion",
    packageName:"packageName"
  },
  databaseConfig:{
    tables:'tables',
    cashier:'cashier',
    staff:'staff',
    reject:'reject_order',
    customer:'customer',
    customerInfo:'customerInfo',
    delivaryInfo:'delivaryInfo',
    customerCorporate:'customercorporate',
    waiter:'waiter',
    restaurant:'restaurant',
    menulist:'menulist',
    currentorder:'currentorder',
    currentbotorder:'currentbotorder',
    currenthtorder:'currenthtorder',
    counter:'counter',
    orderfinished:'orderfinished',
    final:'final',
    menu:'menu',
    finalbot:'finalbot',
    finalht:'finalht',
    voidBills:'voidBills',
    errors:'errors',
    stocks:'stocks',
    consumtion:'consumtion',
    wastages:'wastages',
    recipes:'recipes',
    finalstock:'finalstock',
    liquor_item:'liquor-item',
    kitchen_stocks:'kitchen-stocks',
    finalkitchen_stocks:'finalkitchen-stocks',
    hookah_item:'hookah-item',
    liquor_stock:'liquor-stock',
    finalliquor_stock:'finalliquor-stock',
    index:'indexes',
    invoice:'invoices',
    final_consumption:'final_consumption',
    offer:'special-discounts',
    mssg: 'special-massge',
    excelFormat:"excelFormat",
    restaurenInfo: "restaurenInfo",
    reason_reprint: "reason_reprint",
    highest_sale: "highest_sale",
    user_devices:"user_devices",
    reject_order:"reject_order",
    order_categori: "order_categori"
  },
  cloudantAuth:{
    // url:'https://bff09898-113f-41c1-b636-32745cd64c61-bluemix.cloudantnosqldb.appdomain.cloud/',
    // username:'apikey-v2-1ssuxsquenu596tg49s3w47qo98dh3g9c7ciyb9i27rb',
    // password:'5e6737d1b5080242d2454ad9a3a83dea',
    //foodhouse secret key
     //secreat_key:"y9YZvCpAhG5Cd7A2xBH6",

    //secret key for superadmin testing for 2 restaurants

    //Admin:- abhisek.boonsoft@gmail.com
    // secreat_key:'0OUTTgwAVxQVJokDI9uN',

    //Admin:-boonsoftware.paancasa@gmail.com
   //secreat_key:'zysMhE6o86qZYFi32TDj',

    //Admin2:abhisekchatterjee156@gmail.com
    //secreat_key:'kEYv3HsQbWwawWIsK9eQ'
    
   //BoonSoftware.FoodHouse@gmail.com Secreat Key
   // secreat_key: "fsfd1eNSPP1yzKCysXuL"

   //bigbang.pubify@gmail.com
    secreat_key: "HH5NlfVkz8NEJVQHXCKa"

  //secreat_key: "aMeCqK4G0RtmVIVjE9Cd"

  //secreat_key: "dwN5d8ukomQvegqDEzD0"
    },
  pageConfigs:{
    loginPage:{
      newUserSignupModel:"New User? Signup"
    },
    homePage:{
      restaurantDetails:"Restaurant Profile",
      itemsDetails:"Menu",
      addtable:"Add Tables",
      dine_in_order:"Dine in Order",
      waiterDetails:"Waiters Profile",
      tableDetails:"Table Information",
      waiterLogin:"Place Order",
      cashierDetails:"Cashier Profile",
      cashierLogin:"Cashier Login",
      kitchenView:"Kitchen View",
      billing:"Billing",
      place_order:"Place Order",
      stock:"Stock Management",
      staff_profile:"Staff Profile",
      cashier_profile:"Cashier Profile",
      deliveryBoy_profile:"Delivery boy's Profile",
      add_stock:"Add Stock",
      device_request:"Device Request",
      deliveredHistory:"Delivered History",
      consumption:"Consumption",
      stock_reports:"Stock Reports",
      setting:"Settings",
      deliveryBoy:"Delivery Boy Module",
      billingReports:"Billing Reports",
     new_order:"Incoming Orders",
     ongoing_order:"Orders Ongoing",
     bill_history:"Bill History ",
     report:"Billing Report",
     delivery:"Delivery Boy",
     liquor:"liquor",
     hookah:"hookah"
    },
    addProduct:{
      addProduct:"Add Stock",
      pleaseSelectStockType:"Please Select Stock Type",
      itemProductName:"Item Name",
      quantity:"Qty.",
      unit:"Unit",
      pricePerUnit:"P/U",
      tax:"Tax",
      amount:"Amt.",
      allStocks:"All Stocks",
    },

    AddStockPage:{
      addProduct:"Add-Product",
      fillAllDetail:"Please fill out all details accurately.",
      itemProductName:"Item",
      validItemName:"Please enter a valid item name",
      quantity:"Quantity",
      validQuantity:"Please enter a valid quantity",
      unit:"Unit",
      validUnit:"Please enter a valid unit",
      price:"Price",
      validPrice:"Please enter a valid price",
      amount:"Amount",
      amountIsRequired:"amount is required",
      description:"Description",
      addStock:"Add Stock",
      editStock:"Edit Stock",
    },
    additemModalPage:{
      displayItem:"Display Item",
      close:"Close",
      itemname:"Item:",
      itemPrice:"Price:",
      gstFullplatePrice:"GST(Full):",
      itemPriceHalf:"Price(Half):",
      gstHalfPlatePrice:"GST(Half):",
      quantityFullPlate:"Quantity(Full):",
      quantityHalfPlate:"Quantity(Half):",
      itemType:"Type:",
      displayGST:"Display Included GST : ",
      yes:"Yes",
      no:"No",
      edit:"Edit",
      delete:"Delete",

    },
    adminDisplayCashier:{
      displayCashier:"Display Cashier",
      edit:"Edit",
      delete:"Delete",
      addCashier:"Add Cashier", 
      cashierDetails:"Cashier Details",
    },
    adminHome:{
      admin:"Admin",      
      user:"Staff",
      wording:"The soulful ambience and our attentive staff along with amazing food gurantees a comfortable and enjoyable experience for you."
    },

    adminMenuAddTable:{
      menuCard:"Menu",
      menu:"Menu",
      table:"Table: ",
      parcel:"Parcel: ",
      halfPrice:"Price(Half): ",
      full:"Full",
      half:"Half",
      price:"Rs:",
      noOfPlate:" Items |",
      placeOrder:"Place Order",
      viewCart:"View Cart",
      customer_name:"Customer name",
      customer_phone:"Custoner Phone",
      customer_DOB:"DOB",
      createBill:"Generate Bill",
    },
    adminMenuCardAddItem:{
      addItem:"Add Item",
      update:"update",
      menuCard:"Menu Card",
      fieldIsMandatory:"(*) Field is Mandatory", 
      star:"*",
      itemPrice:"Price",
      mark_as_new:"mark as new",
      mark_as_best_signature_dish:"mark as best signature dish",
      mark_as_best_seller:"mark as best seller",
      cuisine:"Cuisine",
      categories:"Categories",
      sub_categories:"Sub-categories",
      addIngredients:"Add ingredients",
      fullPlateGSTPrice:"GST",
      quantity:"Quantity",
      itemPriceHalfPlate:"Price(Half)",
      itemGSTPriceHalfPlate:"GST(Half)",
      quantityHalfPlate:"Quantity(Half)",
      itemType:"Type :",
      veg:"Veg",
      nonVeg:"Non-Veg",
      available:"Available?"
    },
    adminMenuCardDeleteItem:{
      table:"Table",
      room:"Room",
      parcel:"Parcel",
      currentOrder:"Current Order",
    },
    adminMenuCardEditItem:{
      editItem:"Update Item",
      close:"Close",
      priceDisplayWithGST:"Display Price with GST ? ",
      itemName:"Item",
      itemPrice:"Price",
      GSTFullPlatePrice:"GST(Full)",
      pieces:"Pieces",
      cuisine:"Cuisine",
      categories:"Categories",
      sub_categories:"Sub-categories",
      itemPriceHalfPlate:"Price(Half)",
      itemGSTPriceHalfPlate:"GST(Half)",
      piecesHalfPlate:"Pieces(Half)",
      itemType:"Type :",
      veg:"Veg",
      nonVeg:"Non-Veg",
      
    },
    adminMenuCardAddMember:{
      name:"Name:",
      phone:"Contact No. :",
      dob:"Birthday :",
      submit:"Submit",
      update:"update",
      member_info:"Member Information",
      membership_no:"Membership No. :",
      approval_date:"Approval date :",
      type_of_membership:"Member Type :"
      },
      HookahAddItemPage:{
        addItem:"Add Item",
        menu:"Menu",
        fieldIsMandatory:"(*) Field is Mandatory",
        priceDisplayWithGST:"Display Price with GST ? ",
        itemName:"Item",
        star:"*",
        itemPrice:"Price",
        GSTPrice:"GST",
      },
      HookahAddItemModalPage:{
        itemName:"Item",
        price:"Price",
        GSTPrice:"GST",
      },
    adminReceivedBill:{
      receipt:"Receipt",
      mob:"Mob : ",
      email:"Email : ",
      gstin:"GSTIN : ",
      tID : "Transaction_ID : ",
      date:"Date : ",
      time:"Time : ",
      table:"Table : ",
      parcel:"Parcel : ",
      totalAmount:"Total Amount :",
      discount:"Discount :",
      gst:"GST :",
      deliveryCharge:"Delivery Charge :",
      miscellaneous:"Miscellaneous :",
      payableAmount:"Payable Amount:",
      paymentType:"Payment Type:",
    },
    adminReports:{
      couponId: "Coupon Id",
      adminReports:"Billing Reports",
      mob:"Mob : ",
      selectReports:"Select Reports",
      itemWiseSellReport:"Sale Report",
      GSTAmount:"GST",
      discountReport:" Discount",
      totalTips:"Total Tips",
      billWise:"Bill",
      paymentWise:"Payment",
      HighestSale: "Highest Sale",
      sale: "Sale",
      stockReport:"Stock",
      fromDate:"From Date :",
      toDate:"To Date :",
      iemName:"Item Name",
      quantity:"Quantity",
      rate:"Rate",
      gstPerplate:"Gst/plate",
      total:"Total",
      slNo:"Sl No.",
      billNo:"Bill No.",
      price:"Price",
      item:"Item",
      amount:"Amount",
      gst:"GST",
      discount:"Discount",
      charge:"Charge",
      parcel:"Parcel",
      delivery:"Delivery",
      other:"Other",
      sub:"Sub",
      grandTotal:"Grand Total",
      paymentTypeReport:"Payment Type Report",
      tID:"Transaction ID",
      date:"Date",
      time:"Time",
      orderType:"Order Type",
      totalAmount:"Total Amount",
      totalGST:"Total GST",
      GST:"G.S.T",
      billAmount:"Bill Amount",
      billNumber:"Bill NUmber",
      totalBillAmount:"Total Bill Amount",
      totalDiscountRs:"Total Discount ( Rs) :",
      numberOfBills:"Number of Bills",
      msg1:"No Data available",
      msg2:"There is no available data to show.",
      msg3:"Please choose different Date and try again.",
      cancelReport: "Cancel Report",
      CategoriWise: "Categori Wise",
      transactionId: "Transaction Id",
      Categori: "Categori Name",
      TotalPlate: "Total Plate",
      reasonForcancellation: "Reason",
      paymentType: "Payment Type",
      OrderType: "Order Type",
      CouponWise: "Coupon"
    },
    adminWaiterInfoPage:{
      welcomeToAdminDashboard:"Welcome To Admin Dashboard",      
      stockManagement:"Stock Management",
    },
    adminRestoInfoPage:{
      finalBilling:"Final Billing",
      tID : "Transaction_ID : ",
      date:"Date : ",
      time:"Time : ",
      table:"Table : ",
      parcel:"Parcel : ",
      billNo:"Bill No :",
      item:"Item",
      quantity:"Qty.",
      rate:"Rate",
      amount:"Amt.",
      complementaryItem:"select complementary Item",
      gstAmount:"GST",
      totalAmount:"Total Amount",
      totalItemPrice:"Total Item Price:",
      discount:"Discount:",
      totalPricewithParcel:"Total Price with Parcel:",
      serviceCharge:"Service Charge:",
      totalPricewithDelivery:"Total Price with Service Charge :",
      miscellaneous:"Miscellaneous:",
      totalBill:"Total Bill :",
      print:"Print",
      confirm:"Confirm",
      customer_name:"Customer name",
      customer_phone:"Customer phone",
      customer_DOB:"DOB"
    },
    cashierLoginPage:{
      cashierLogin:"Cashier Login",
    },
    changeModule:{
      changeModule:"Change Module",
   },
   consumtionDetails:{
    stockDetails:"Stock-Details",
    statement1:"Click on that item you need to utilized for",
    rawMaterial:"Raw Material",
    existingStock:"Existing Stock",
    unit:"Unit",
   },
   deviceRegister:{
    deviceRegister:"Device Register",
    statementOne:"Register your Device for",
    cashier:"Cashier",
    waiter:"Waiter",
    country_code:"Country code",
    statementTwo:"Enter Admin's Email/Phone",
    enterName:"Enter Your Name",
    removeRequest:"Remove Request",
    register:"Register",
    statementThree:"Click Register after giving your necessary information below.",
    statementFour:"Your job role will be confirmed by the admin.",

    },
    disPlayDeviceRequest:{
      deviceRequest:"Device Request",
      deviceUUID:"Device UUID: ",
      userName:"User Name: ",
      registerFor:"Register for:",
      allow:"Allow",
      statementOne:"Please Remove this Device",
      remove:" Remove",
      deny:"Deny",
    },
    displayTable:{
      tableInformation:"Table Information",
      table:"Table No. :",
      capacity:"Capacity: ",
    },
    LiquorAddItemPage:{
      addItem:"Add Item",
      menu:"Menu",
      fieldIsMandatory:"(*) Field is Mandatory",
      liquorType:"Liquor Type",
      WHISKY:"WHISKY",
      SCOTCH:"SCOTCH",
      WINE:"WINE",
      RUM:"RUM",
      BREEZER:"BREEZER",
      BEER:"BEER",
      VODKA:"VODKA",
      brandName:"Brand",
      unit:"Unit",
      unit2:"30 ml",
      unit3:"60 ml",
      unit4:"100 ml",
      unit5:"180 ml",
      unit6:"330 ml",
      unit7:"375 ml",
      unit8:"500 ml",
      unit9:"650 ml",
      unit10:"750 ml",
      price:"Price"
    },
    loginHardPage:{
      login:"Login",
      EmailOrPhone:" Email or Phone ", 
      password:"Password",
      country_code:"Country Code ",
      forgotPassword:"Forgot Password?",
      clickHere:"Click Here",
    },
    makebill:{
      refreshBillNo:"Reset Bill No.",
      refreshParcelNo:"Reset Parcel No.",
      blockTable:"Booked Tables",
      tableNo:"Table No. : ",
      capacity:"Capacity: ",
      parcel:"Parcel:",
    },
    menuBarCodeScanner:{},
    menuPrint:{
      tNo:"Transaction No: ",
      date:"Date :",
      time:"Time :",
      table:"Table :",
      parcel :"Parcel :",
      itemName:"Item",
      print:"Print",
      qtyOfPlate:"Qty/No of Plate",
      fullHalfPlate:"Full Plate",
      statementOne:"Thank you for your visit!",
    },
    orderHistory:{
      close:"Close",
      table:"Table:",
      room:"Room:",
      parcel:"Parcel: ",
      priceFullPlate:"Price(Full Plate):",
      priceHalfPlate:"Price(half Plate):",
      readyYes:"Ready:Yes",
      items:"Items:",
      plateType:"Plate Type:",
    },
    orderFinish:{
      statusOrderConfirm:"Status: Order Confirm ",
      statusOrderReady:"Status: Order Ready",
      statusOrderDelivered:"Status: Order Delivered",
      tID:"Transaction ID:",
      table:"Table:",
      room:"Room:",
      parcel:"Parcel: ",
      continueOrder:"Continue Order",
      orderHistory:"Order History",
      finishOrder:"Finish Order",
    },
    print:{
     tID:"Transaction No:",
     date:" Date : ",
     time:" Time : ",
     billingNumber:"Billing Number : ",
     orderType:"Order Type : ",
     itemName:"Item",
     qty:"Qty",
     price:"Price",
     value:"Value",
     subTotal:"Subtotal : ",
     gst:"GST :",
     discount:"Discount : ",
     parcel:"Parcel : ", 
     serviceCharge:"service charges : ",
     miscellaneous:"Miscellaneous : ",
     grandTotal:"Grand Total",
     statementOne:"Thank you for your visit",
     print:"Print",
    },
    qrPage:{},
    resetPasswordPage:{
      resetPassword:"Reset Password",
      validEmail:"Enter Valid Email",
      masgOne:"Please Enter a Valid Email !",
    },
    restaurantInfo:{
      restoInfo:"Add Restaurant Information",
      statementOne:"(*) Fields are mandatory",
      restoName:"Restaurant Name",
      validRestoName:"Please enter valid restaurant name",
      adminName:"Admin Name",
      validRestoAdminName:"Please enter valid admin name",
      restoAddress:"Restaurant Address",
      validRestoAddress:"Enter Valid Restaurant Address",
      restoContactNo:"Restaurant Contact Number",
      validRestoContNo:"Enter 10 Digit Contact Number",
      validRestoContactNO:"Please enter valid 10 digit contact number",
      restoEmailID:"Email ID",
      validRestoEmailID:"Please enter valid email",
      restoAltEmailID:"Alternative Email ID",
      validRestoAltEmailID:"Please enter valid alternative email",
      gstn:"GSTIN",
      validgstn:"Please enter valid GST number",
      addInfo:"Add",
    },
    restoInfoDelete:{
      vacant:"Vacant",
      occupied:"Occupied",
      tableNo:"Table no :",
      sittingCapacity:"Sitting Capacity :",
      bookTable:"Book Table",
      order:"Order",
      back:"Back",
    },
    restoInfoDisplay:{
      restoInfo:"Restaurant Information",
      adminName:"Admin Name",
      address:"Restaurant Address",
      contactNo:"Restaurant Contact No.",
      restoEmailID:"Restaurant Email ID",
      restoAltEmailID:"Alternative Email ID",
      gstn:"Restaurant GSTIN No.",
      edit:"Edit",
      delete:"Delete",
     statementOne:"Swipe left to delete and right to edit/display properly.",

    },
    restoInfoEdit:{
      restoInfoEdit:"Edit Restaurant Information",
      close:"Close",
      statementOne:"(*) Fields are mandatory",
      restoName:"Restaurant Name",
      validRestoName:"Please enter valid restaurant name",
      adminName:"Admin Name",
      validRestoAdminName:"Please enter valid admin name",
      restoAddress:"Restaurant Address",
      validRestoAddress:"Please enter valid restaurant address",
      restoContactNo:"Restaurant Contact No.",
      validRestoContNo:"Enter 10 Digit Contact Number",
      restoEmailID:"Restaurant Email ID",
      validRestoEmailID:"Please enter valid email",
      restoAltEmailID:"Alternative Email ID",
      validRestoAltEmailID:"Please enter valid alternative email",
      gstn:"Restaurant GSTIN No.",
      validgstn:"Please enter valid GST number",
      editInfo:"Update",
    },
    settingsPage:{
      settings:"Settings",
      RPC:"Reset Parcel No.",
      RBN:"Reset Bill No.",
      CR:"Clear Record",
      SU:"Switch User",
      DR:"Device Request",
      SetGst:"Set GST",
      SetServiceCharge:"Set Service Charges "
    },
    signupPage:{
      signup:"Signup",
      firstName:"First Name",
      validFirstName:"Firstname must contain atleast 4 letters",
      lastName:"Last Name",
      validLastName:"Lastname must contain atleast 3 letters",
      ep:"Email or Phone",
      cc:"Country code",
      validcc:"Please enter a valid email or phone no.",
      password:"Password",
      validPassword:"Your password needs atleast 6 characters.",
      confirmPassword:"Confirm Password",
      validconfirmPassword:"Confirm Password must be atleast 6 characters",
      passwordMatch:"Please match the password and confirm it.",
    },
    stockDetailsPage:{
      details:" Details",
      itemName:"Item",
      itemQuantity:"Quantity",
      purchasedInUnit:"Purchased In Unit",
      pricePerUnit:"Price Per Unit",
      totalAmount:"Total Amount",
      tax:"Tax",
      timestamp:"Timestamp",
      edit:"Edit",
      save:"Save",
    },
    waiterHome:{
      tID:"Transaction Id:",
      table:"Table:",
      parcel:"Parcel:",
      close:"Close",
      priceFullPlate:"Price(Full):",
      priceHalfPlate:"Price(half):",
      item:"Item:",
      price:"Price:",
      order:"Order",
      more:"More",
      confirm:"Confirm",
    },
    waiterLogin:{
      waiterLogin:"Delivery Boy",
    },
    waiterModule:{
      waiterDetails:"Waiter Details",
    },
    HookahMenuEditPage:{
      editItem:"Edit Item",
      itemName:"Hookah Type",
      price:"Price",
      GstPrice:"GST",
      updateItem:"Update Item"

    },
    LiquorMenuEditPage:{
      editItem:"Edit Item",
      liquorType:"Liquor Type",
      brandName:"Brand",
      price:"Price",
      unit:"Unit",
      updateItem:"Update Item"
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
