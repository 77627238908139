import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {
  public urbanpiper_auth = "apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f";
  constructor(public http: HttpClient) { }

  orderStatusUpdate(statusObj:any, id:any) {
    let httpOptions = {
      "async": true,
      "crossDomain": true,
      "processData": false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f'
      })
    }
    let url = "https://pos-int.urbanpiper.com/external/api/v1/orders/"+id+"/status/";
    
    let data = {
      "new_status": statusObj,
      "message": "Order " + statusObj + " from restaurant"
    }
    this.http.put(url, data, httpOptions).subscribe(
      res => {
        console.log("Urban Piper (orderStatusUpdate API) fired successfully", res);
        //resolve(res);
      },
      error => {
        //reject(error);
        console.log("error", error);
      }
    );
  }

  categoryTimingGroup(title, categoriesFilteredID, daySelected) {
    let httpOptions = {
      "async": true,
      "crossDomain": true,
      "processData": false,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f'
      })
    }
    let url = "https://pos-int.urbanpiper.com/external/api/v1/inventory/categories/timing-groups/";
    
    let data = {
      "timing_groups": [
        {
          "title": title,
          "category_ref_ids": categoriesFilteredID,
          "day_slots": daySelected
        }
      ]
    }
    this.http.post(url, data, httpOptions).subscribe(
      res => {
        console.log("Urban Piper (orderStatusUpdate API) fired successfully", res);
        //resolve(res);
      },
      error => {
        //reject(error);
        console.log("error", error);
      }
    );
  }
  catalogue_management(id1: any, data1: any,categoriesFilteredFull:any,categoriesFilteredID:any,optionsFilteredFull:any,optionsFilteredID:any,optiongroupsFilteredFull:any) {
    //let id: Number = Number(1);
    let id = id1;
    console.log("id",id1)
    let store_id = "680d2253-45c8-42a1-9557-8b73a25311e2"; //The store where this item will be associated. REQUIRED
    let ext_price;
    if (data1.packageTotal) {
      ext_price = data1.fullplate + data1.gstfullplate + data1.packageTotalCost;
    }
    else {
      ext_price = data1.fullplate + data1.gstfullplate;
    }
    console.log("External Price:",data1.fullplate + data1.gstfullplate + data1.packageTotalCost,typeof(parseInt(data1.fullplate + data1.gstfullplate + data1.packageTotalCost)));
    return new Promise((resolve, reject) => {
      
    
      // setTimeout(async () => {
        let data = {
          
              "flush_categories": false,
          "categories": categoriesFilteredFull,
            // [
            //     {
            //       "ref_id": id.toString(),
            //       "name": data1.categories,
            //       //"description": "famous veg and nonveg hyderabadi biryanis",
            //       "sort_order": 1,
            //       "active": true,
            //       // "img_url": "https://static.giantbomb.com/uploads/scale_small/0/6087/2437349-pikachu.png",
            //       // "translations": [
            //       //   { "language": "fr", "name": "...", "description": "..." },
            //       // ]
            //     },
            //   ],
              "flush_items": false,
              "items": [
                {
                  "ref_id": id,
                  "title": data1.itemname,
                  "available": true,
                  "description": data1.ingredients,
                  "weight": 100.0,
                  "sold_at_store": true,
                  "sort_order": 0,
                  "serves": 1,
                  "external_price": ext_price,
                  "price": parseInt(data1.fullplate), "markup_price": ext_price,
                  "current_stock": -1,
                  "recommended": data1.mark_as_best_seller,
                  "food_type": "1",
                  "category_ref_ids": categoriesFilteredID,
                    //[id.toString()],
                  "fulfillment_modes": ["pickup", "delivery"],
                  "images": [
                    {
                      "tag": "default",
                      "url": "data1.image.url"
                    },
                  ],
                  //"img_url": "data1.image",
                  // "translations": [
                  //   {
                  //     "language": "fr",
                  //     "title": "...",
                  //     "description": "..."
                  //   },
                  // ],
                  // "tags": {
                  //   "zomato": ["treat", "deal99"],
                  //   "swiggy": ["pop", "edvm"],
                  //   "amazon": ["cuisine-Japanese"]
                  // },
                  "included_platforms": ["zomato", "swiggy"]
                },
              ],
              // "flush_option_groups": false,
              // "option_groups": [
              //   {
              //     "ref_id": "OG-1",
              //     "title": "Choose Your Biryani size",
              //     "ref_title": "size choice - veg biryani",
              //     "min_selectable": 1,
              //     "max_selectable": 1,
              //     "clear_item_ref_ids": false,
              //     "display_inline": false,
              //     "active": true,
              //     "item_ref_ids": [id],
              //     "translations": [
              //       { "language": "fr", "title": "...", "description": "..." },
              //       { "language": "es", "title": "...", "description": "..." }
              //     ],
              //     "sort_order": 0
              //   },
              // ],
              // "flush_options": false,
          "option_groups": optiongroupsFilteredFull,
          "options": optionsFilteredFull,
            // [
            //     {
            //       "ref_id": "opt-1",
            //       "title": "Small",
            //       "ref_title": "small choice - for veg pizza",
            //       "description": "For Veg Biryani",
            //       "weight": 200,
            //       "available": true,
            //       "recommended": true,
            //       "price": 150.0,
            //       "sold_at_store": true,
            //       "sort_order": 0,
            //       "clear_opt_grp_ref_ids": false,
            //       "opt_grp_ref_ids": ["OG-1"],
            //       "nested_opt_grps": [],
            //       "food_type": "1",
            //       "translations": [
            //         { "language": "fr", "title": "...", "description": "..." },
            //         { "language": "es", "title": "...", "description": "..." }
            //       ]
            //     },
            //   ],
              // "flush_taxes": false,
              // "taxes": [
              //   {
              //     "code": "GST",
              //     "title": "CGST",
              //     //"description": "2.5% CGST on all items",
              //     "active": true,
              //     "structure": { "value": 2.5 },
              //     "item_ref_ids": [id1], //Problem Here
              //     "location_ref_ids": ["d9bf9085-bc67-489d-93d1-54bef2ca44a1"], //store id
              //     "clear_items": false,
              //     "clear_locations": false
              //   },
                // {
                //   "code": "SGST_P",
                //   "title": "SGST",
                //   "description": "2.5% SGST on all items",
                //   "active": true,
                //   "structure": { "value": 2.5 },
                //   "item_ref_ids": ["I-1", "I-2", "I-3", "I-4"],
                //   "location_ref_ids": ["L1", "L2"],
                //   "clear_items": true,
                //   "clear_locations": false
                // }
           //   ],
              // "flush_charges": false,
              // "charges": [
              //   {
              //     "code": "PC_F",
              //     "title": "Packaging Charge",
              //     "description": "Fixed Packing Charge per Item Quantity",
              //     "active": true,
              //     //"structure": { "applicable_on": "item.quantity", "value": data1.packageTotalCost },
              //     "fulfillment_modes": ["delivery"],
              //     "excluded_platforms": [],
              //     "item_ref_ids": [id], //Problem Here
              //     "location_ref_ids": ["all"], //Problem Here
              //     "clear_items": false,
              //     "clear_locations": false
              //   }
              // ]
            
        }


        console.log(data,data1);
      
        let httpOptions = {
          "async": true,
          "crossDomain": true,
          "processData": false,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f'
          })
        }
        this.http.post('https://pos-int.urbanpiper.com/external/api/v1/inventory/locations/'+environment.cloudantAuth.secreat_key+"/", data, httpOptions).subscribe(
          res => {
            console.log(" From Urban Piper (catalogue creation API) added successfully", res);
            //resolve(res);
          },
          error => {
            //reject(error);
            console.log("error", error);
          }
        );
        // });
        resolve("Resolved");
    
      // }, 2000);
      
      
      
    });
  }




  store_actions(id:any,plat:any,val:any) {


    return new Promise((resolve, reject) => {
      let data = {
        // "location_ref_id": "a4f19352-a128-4d27-a354-4330480b539d",
        // "platforms": ["zomato"],
        // "action": "enable"

        "location_ref_id": id,
        "platforms": plat,
        "action": val

        
      }
      console.log("data",data);
      let httpOptions = {
        "async": true,
        "crossDomain": true,
        "processData": false,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': this.urbanpiper_auth
        })
      }

      this.http.post('https://pos-int.urbanpiper.com/hub/api/v1/location/', data, httpOptions).subscribe(
        res => {
          console.log("From Urban Piper (Store Action API) added successfully", res);
          //resolve(res);data._id,"disable")
        },
        error => {
          //reject(error);
          console.log("error", error);
          console.log("Store Action API - Urban-Piper Third Party Service Page");
        }
      );

    });
    
  }
 

/** update menu list data of type item json with id  */
async   updateMenuList(data:any){
  let optionsArray:Array<any>=[];
  let action:string=null;
    await  data.options.forEach(data => {
      console.log("data",data);
       optionsArray.push(data.ref_id); 
    });

    if(data.available){
       action = "enable";
    }
    else{
      action = "disable";
    }
    console.log("this.optionsArray",optionsArray);
  
        
        let dataJson = {
          "location_ref_id": environment.cloudantAuth.secreat_key,
          "item_ref_ids": [data._id],
          "option_ref_ids":optionsArray,
          "action": action
        }
        let httpOptions = {
          "async": true,
          "crossDomain": true,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f'
          })
        }
        this.http.post('https://pos-int.urbanpiper.com/hub/api/v1/items/', dataJson, httpOptions).subscribe(
          res => {
            console.log(" From Urban Piper (store creation API) added successfully", res);
            //resolve(res);
          },
          error => {
            //reject(error);
            console.log("error", error);
            console.log("Management Store API Call -> restaurantinfo Page");
          });
    

  }

async createStore(data:any){
     console.log(data);
    let dataJson = {
      stores: [
        {
          "ref_id": environment.cloudantAuth.secreat_key,
          "city": data.location.city,
          "name": data.rname,
          "min_pickup_time": data.minPickupTime,
          "min_delivery_time": data.minDeliveryTime,
          "contact_phone": data.cnumber,
          "notification_phones": [
            data.cnumber,
          ],
          "min_order_value": data.minOrderValue,
          "hide_from_ui": false,

          "address": data.raddress,
          "notification_emails": [
            data.email,
            data.altemail
          ],

          "zip_codes": [
            data.zipCode
          ],

          "geo_longitude": data.location.position.lng,
          "geo_latitude": data.location.position.lat,


          "active": data.active,
          "ordering_enabled": data.ordering,

          // "translations": [
          //   {
          //     "language": "fr",
          //     "name": "..."
          //   }
          // ],

          "excluded_platforms": [
            "swiggy",

          ],

          "platform_data": [
            // { //Real Swiggy ID for desh bhojyaan
            //   "name": "swiggy",
            //   "url": "https://partner.swiggy.com/menu/details/restaurant/335294",
            //   "platform_store_id": "335294"
            // }
            {
              //FoodHouse cadenceal 
              "name": "zomato",
              "url": "https://www.zomato.com/users/peter-salter-1816583",
              "platform_store_id": "srpl@gmail.com"
            }
          ],
          "timings": data.timings
          //     [
          //   {
          //     "day": "monday",
          //     "slots": [
          //       {
          //         "start_time": "10:00:00",
          //         "end_time": "22:30:00"
          //       }
          //     ]
          //   },
          //   {
          //     "day": "tuesday",
          //     "slots": [
          //       {
          //         "start_time": "10:00:00",
          //         "end_time": "22:30:00"
          //       }
          //     ]
          //   },
          //   {
          //     "day": "friday",
          //     "slots": [
          //       {
          //         "start_time": "10:00:00",
          //         "end_time": "22:30:00"
          //       }
          //     ]
          //   }
          // ]
        }



      ]
    }
    let httpOptions = {
      "async": true,
      "crossDomain": true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'apikey biz_adm_clients_jjfNcIrrnbPP:aa1705f0c5948393e61fb2a5e35f5cabc287258f'
      })
    }
    this.http.post('https://pos-int.urbanpiper.com/external/api/v1/stores/', dataJson, httpOptions).subscribe(
      res => {
        console.log(" From Urban Piper (store creation API) added successfully", res);
        //resolve(res);
      },
      error => {
        //reject(error);
        console.log("error", error);
        console.log("Management Store API Call -> restaurantinfo Page");
      });
 
}


}
