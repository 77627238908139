import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '../app/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'waiter-login',
    loadChildren: () => import('./pages/waiter-login/waiter-login.module').then( m => m.WaiterLoginPageModule)
  },
  {
    path: 'cashier-login',
    loadChildren: () => import('./pages/cashier-login/cashier-login.module').then( m => m.CashierLoginPageModule)
  },
  // {
  //   path: 'resto-info-display',
  //   loadChildren: () => import('./pages/resto-info-display/resto-info-display.module').then( m => m.RestoInfoDisplayPageModule)
  // },
  {
    path: 'deviceregister',
    loadChildren: () => import('./pages/deviceregister/deviceregister.module').then( m => m.DeviceregisterPageModule)
  },
  {
    path: 'admin-waiter-info',
    loadChildren: () => import('./pages/admin-waiter-info/admin-waiter-info.module').then( m => m.AdminWaiterInfoPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'admin-menu-card-add-item',
    loadChildren: () => import('./pages/admin-menu-card-add-item/admin-menu-card-add-item.module').then( m => m.AdminMenuCardAddItemPageModule)
  },
  
  {
    path: 'admin-menu-recipe',
    loadChildren: () => import('./pages/admin-menu-recipe/admin-menu-recipe.module').then( m => m.AdminMenuRecipePageModule)
  },
  
  {
    path: 'restaurantinfo',
    loadChildren: () => import('./pages/restaurantinfo/restaurantinfo.module').then( m => m.RestaurantinfoPageModule)
  },
  {
    path: 'additem-modalpage',
    loadChildren: () => import('./pages/additem-modalpage/additem-modalpage.module').then( m => m.AdditemModalpagePageModule),
    
  },
  {
    path: 'admin-reports',
    loadChildren: () => import('./pages/admin-reports/admin-reports.module').then( m => m.AdminReportsPageModule)
  },
  {
    path: 'stock-management',
    loadChildren: () => import('./pages/stock-management/stock-management.module').then( m => m.StockManagementPageModule)
  },
  {
    path: 'makebill',
    loadChildren: () => import('./pages/makebill/makebill.module').then( m => m.MakebillPageModule)
  },
  {
    path: 'change-module',
    loadChildren: () => import('./pages/change-module/change-module.module').then( m => m.ChangeModulePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'resto-info-edit',
    loadChildren: () => import('./pages/resto-info-edit/resto-info-edit.module').then( m => m.RestoInfoEditPageModule)
  },
  {
    path: 'admin-menu-card-edit-item',
    loadChildren: () => import('./pages/admin-menu-card-edit-item/admin-menu-card-edit-item.module').then( m => m.AdminMenuCardEditItemPageModule)
  },
  {
    path: 'add-product',
    loadChildren: () => import('./pages/add-product/add-product.module').then( m => m.AddProductPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'add-stock',
    loadChildren: () => import('./pages/add-stock/add-stock.module').then( m => m.AddStockPageModule)
  },

  {
    path: 'admin-received-bill',
    loadChildren: () => import('./pages/admin-received-bill/admin-received-bill.module').then( m => m.AdminReceivedBillPageModule)
  },
  {
    path: 'admin-resto-info',
    loadChildren: () => import('./pages/admin-resto-info/admin-resto-info.module').then( m => m.AdminRestoInfoPageModule)
  },
  {
    path: 'stock-details',
    loadChildren: () => import('./pages/stock-details/stock-details.module').then( m => m.StockDetailsPageModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./pages/print/print.module').then( m => m.PrintPageModule)
  },
 
  {
    path: 'resto-info-delete',
    loadChildren: () => import('./pages/resto-info-delete/resto-info-delete.module').then( m => m.RestoInfoDeletePageModule)
  },
 
  {
    path: 'orderfinish',
    loadChildren: () => import('./pages/orderfinish/orderfinish.module').then( m => m.OrderfinishPageModule)
  },
  
  {
    path: 'menu-print',
    loadChildren: () => import('./pages/menu-print/menu-print.module').then( m => m.MenuPrintPageModule)
  },
  {
    path: 'order-history',
    loadChildren: () => import('./pages/order-history/order-history.module').then( m => m.OrderHistoryPageModule)
  },
  {
    path: 'consumtion-details',
    loadChildren: () => import('./pages/consumtion-details/consumtion-details.module').then( m => m.ConsumtionDetailsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'issued-details',
    loadChildren: () => import('./pages/issued-details/issued-details.module').then( m => m.IssuedDetailsPageModule)
  },
  {
    path: 'wastage-info',
    loadChildren: () => import('./pages/wastage-info/wastage-info.module').then( m => m.WastageInfoPageModule)
  },

  {
    path: 'displaydevicerequest',
    loadChildren: () => import('./pages/displaydevicerequest/displaydevicerequest.module').then( m => m.DisplaydevicerequestPageModule)
  },
 
  {
    path: 'add-members',
    loadChildren: () => import('./pages/add-members/add-members.module').then( m => m.AddMembersPageModule)
  },
 
 
  {
    path: 'stock-consumtions',
    loadChildren: () => import('./pages/stock-consumtions/stock-consumtions.module').then( m => m.StockConsumtionsPageModule)
  },
  {
    path: 'view-generated-bills',
    loadChildren: () => import('./pages/view-generated-bills/view-generated-bills.module').then( m => m.ViewGeneratedBillsPageModule)
  },
  {
    path: 'developer-dashboard',
    loadChildren: () => import('./pages/developer-dashboard/developer-dashboard.module').then( m => m.DeveloperDashboardPageModule)
  },
  {
    path: 'admin-info',
    loadChildren: () => import('./pages/admin-info/admin-info.module').then( m => m.AdminInfoPageModule)
  },
  {
    path: 'developer-module',
    loadChildren: () => import('./pages/developer-module/developer-module.module').then( m => m.DeveloperModulePageModule)
  },
  {
    path: 'add-client',
    loadChildren: () => import('./pages/add-client/add-client.module').then( m => m.AddClientPageModule)
  },
  {
    path: 'modify-bills',
    loadChildren: () => import('./pages/modify-bills/modify-bills.module').then( m => m.ModifyBillsPageModule)
  },
  {
    path: 'modify-bills-bot',
    loadChildren: () => import('./pages/modify-bills-bot/modify-bills-bot.module').then( m => m.ModifyBillsBotPageModule)
  },
  {
    path: 'modify-bills-hookah',
    loadChildren: () => import('./pages/modify-bills-hookah/modify-bills-hookah.module').then( m => m.ModifyBillsHookahPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'email-verification',
    loadChildren: () => import('./pages/email-verification/email-verification.module').then( m => m.EmailVerificationPageModule)
  },
 
  {
    path: 'excel-json',
    loadChildren: () => import('./pages/excel-json/excel-json.module').then( m => m.ExcelJsonPageModule)
  },
 
  {
    path: 'add-corporate-member',
    loadChildren: () => import('./pages/add-corporate-member/add-corporate-member.module').then( m => m.AddCorporateMemberPageModule)
  },
  {
    path: 'billwise-items',
    loadChildren: () => import('./pages/billwise-items/billwise-items.module').then( m => m.BillwiseItemsPageModule)
  },
  {
    path: 'charjs',
    loadChildren: () => import('./pages/charjs/charjs.module').then( m => m.CharjsPageModule)
  },

  {
    path: 'billing',
    loadChildren: () => import('./pages/billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'searchitem',
    loadChildren: () => import('./pages/searchitem/searchitem.module').then( m => m.SearchitemPageModule)
  },
  {
    path: 'customer-add-info',
    loadChildren: () => import('./pages/customer-add-info/customer-add-info.module').then( m => m.CustomerAddInfoPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path: 'add-delivary-boy',
    loadChildren: () => import('./pages/add-delivary-boy/add-delivary-boy.module').then( m => m.AddDelivaryBoyPageModule)
  },
  {
      path: 'menu',
      loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'add-offer',
    loadChildren: () => import('./pages/add-offer/add-offer.module').then( m => m.AddOfferPageModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('./pages/discount/discount.module').then( m => m.DiscountPageModule)
  },
  {
    path: 'delivery-boy-dashboard',
    loadChildren: () => import('./pages/delivery-boy-dashboard/delivery-boy-dashboard.module').then( m => m.DeliveryBoyDashboardPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate:[AuthGuard]
        },
   { path: 'view-customer-deatils',
    loadChildren: () => import('./pages/view-customer-deatils/view-customer-deatils.module').then( m => m.ViewCustomerDeatilsPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'cashier-dashboard',
    loadChildren: () => import('./pages/cashier-dashboard/cashier-dashboard.module').then( m => m.CashierDashboardPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'dashboard-delivery-boy',
    loadChildren: () => import('./pages/dashboard-delivery-boy/dashboard-delivery-boy.module').then( m => m.DashboardDeliveryBoyPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'incoming-order',
    loadChildren: () => import('./pages/incoming-order/incoming-order.module').then( m => m.IncomingOrderPageModule)
  },
  {
    path: 'ongoing-order',
    loadChildren: () => import('./pages/ongoing-order/ongoing-order.module').then( m => m.OngoingOrderPageModule)
  },
  {
    path: 'pay-with-razorpay',
    loadChildren: () => import('./pages/pay-with-razorpay/pay-with-razorpay.module').then( m => m.PayWithRazorpayPageModule)
  },
  {
    path: 'delivery-boy-module',
    loadChildren: () => import('./pages/delivery-boy-module/delivery-boy-module.module').then( m => m.DeliveryBoyModulePageModule)
  },
  {
    path: 'view-cart',
    loadChildren: () => import('./pages/view-cart/view-cart.module').then( m => m.ViewCartPageModule)
  },
  {
    path: 'offer-discount',
    loadChildren: () => import('./pages/offer-discount/offer-discount.module').then( m => m.OfferDiscountPageModule)
  },
  {
    path: 'menu-modify',
    loadChildren: () => import('./pages/menu-modify/menu-modify.module').then( m => m.MenuModifyPageModule)
  },
  {
    path: 'menu-modify-modal',
    loadChildren: () => import('./pages/menu-modify-modal/menu-modify-modal.module').then( m => m.MenuModifyModalPageModule)
  },
 
  {
    path: 'razor-pay',
    loadChildren: () => import('./pages/razor-pay/razor-pay.module').then( m => m.RazorPayPageModule)
  },
  {
    path: 'delivery-boy',
    loadChildren: () => import('./pages/delivery-boy/delivery-boy.module').then( m => m.DeliveryBoyPageModule)
  },
  {
    path: 'staff-profile-all',
    loadChildren: () => import('./pages/staff-profile-all/staff-profile-all.module').then( m => m.StaffProfileAllPageModule)
  },
  {
    path: 'create-bill',
    loadChildren: () => import('./pages/create-bill/create-bill.module').then( m => m.CreateBillPageModule)
  },
  {
    path: 'option-group',
    loadChildren: () => import('./pages/option-group/option-group.module').then( m => m.OptionGroupPageModule)
  },
  {
    path: 'options',
    loadChildren: () => import('./pages/options/options.module').then( m => m.OptionsPageModule)
  },
  {
    path: 'add-category',
    loadChildren: () => import('./pages/add-category/add-category.module').then( m => m.AddCategoryPageModule)
  },
  // {
  //   path: 'online-orders',
  //   loadChildren: () => import('./pages/online-orders/online-orders.module').then( m => m.OnlineOrdersPageModule)
  // },
  {
    path: 'add-menu',
    loadChildren: () => import('./pages/add-menu/add-menu.module').then( m => m.AddMenuPageModule)
  },
  {
    path: 'superadmin-dashboard',
    loadChildren: () => import('./pages/superadmin-dashboard/superadmin-dashboard.module').then( m => m.SuperadminDashboardPageModule)
  },
  {
    path: 'all-branches',
    loadChildren: () => import('./pages/all-branches/all-branches.module').then( m => m.AllBranchesPageModule)
  },
  {
    path: 'add-branches',
    loadChildren: () => import('./pages/add-branches/add-branches.module').then( m => m.AddBranchesPageModule)
  },
  
  
  {
    path: 'master-reports',
    loadChildren: () => import('./pages/master-reports/master-reports.module').then( m => m.MasterReportsPageModule)
  },
  {
    path: 'mastersuperadmin-dashboard',
    loadChildren: () => import('./pages/mastersuperadmin-dashboard/mastersuperadmin-dashboard.module').then( m => m.MastersuperadminDashboardPageModule)
  },
  {
    path: 'admin-list',
    loadChildren: () => import('./pages/admin-list/admin-list.module').then( m => m.AdminListPageModule)
  },
  {
    path: 'liquor-add-modal',
    loadChildren: () => import('./pages/liquor-add-modal/liquor-add-modal.module').then( m => m.LiquorAddModalPageModule)
  },
  {
    path: 'hookah-add-modal',
    loadChildren: () => import('./pages/hookah-add-modal/hookah-add-modal.module').then( m => m.HookahAddModalPageModule)
  },
  {
    path: 'edit-liquor-item',
    loadChildren: () => import('./pages/edit-liquor-item/edit-liquor-item.module').then( m => m.EditLiquorItemPageModule)
  },
  {
    path: 'hookah-edit',
    loadChildren: () => import('./pages/hookah-edit/hookah-edit.module').then( m => m.HookahEditPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
