import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
     await  firebase.auth().onAuthStateChanged((user: firebase.User) => {
        if (user) {
          console.log('user is:',user);

          resolve(true);

        } else {
         // this.loginflage.next(false);
          this.router.navigate(['/home']);
          resolve(false);
        }
      });
     
    });
  }
  
}
