import { ElementRef, Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
// import * as firebase from "firebase";
import * as firebase from 'firebase/app';
import 'firebase/storage'
import 'firebase/firestore';
import moment from "moment";
import { AuthTypes } from "../interface/auth-types";
import { AppserviceService } from "./appservice.service";
import { AngularFireDatabase } from "@angular/fire/database";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { AuthInfo } from '../interface/auth-info';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { TableInfo } from '../interface/table-info';
import { EventsService } from './events.service';
interface BasicUser{
  firstName:string,
    lastName:string,
    phone:string,
    email:string,
    authType:string, //phone vs email vs facebook etc
    OTP:string,
    signupIP:string
};

@Injectable({
  providedIn: "root",
})
export class AuthserviceService {

  private insideUserKey:string = "userFireKey";
  private appName: any = null;
  private userNodePath: string = null;
  private pathSeparator: string = null;
  private restoInfo: any;
  private appuserPath: string;
  private roomaddNodeRef: any;
  private userPathName: string = null;
  private errorPathName: string = "error_log";
  private appErrorName: string = "app_auth_errors";
  private addClientPathName:string = "add_client"
  public clientCollection :string ='client';
  private timestamp: string;
  private appVersion: string;
  private currentUser: any = null;
  private tablesArray: any;
  activeUserPathTag: string = "active";
  inactiveUserPathTag: string = "inactive";
  private userNode: any = null;
  public data: any = null;
  private loginDataFromLogin: any = null;
  public insideuserNode: string = null;
  private regexEmail: any;
  private waiters: string = null;
  private items: string = null;
  private tables: string = null;
  private Resto_Info: string = null;
  private cashiers: string = null;
  private rooms: string = null;
  inside: string = "gotit";
  private appUser: string = null;
  private table_info: string = null;
  private allocated_table: string = null;
  private vacant_table: string = null;
  authInfo:AuthInfo = {authType:null,email:null,phone:null};
  firebaseTwoWayBindStore:Array<Object> = null;
  private basicUser:BasicUser;
  public db: any;
  public myRole:string=null;
  public myroleKey:string=null;
  public accountSid="AC441c3ae7d5c3a699d4acb8c6c48ef22f";
  public authToken="f44d5b62c3f25204b962fffdbecaf145";
  public client:any
  public twilioNumber = '+18032915880';
  constructor(
    private appServiceProvider: AppserviceService,
    private storage: Storage,
    public afDB: AngularFireDatabase,
    public http: HttpClient,
    private  alertCtrl:AlertController,
    public afAuth: AngularFireAuth,
    private toastCtrl: ToastController,
    private events: EventsService,
    private navCtrl: NavController
  ) {
    this.appName = this.appServiceProvider.getAppName();
    this.pathSeparator = this.appServiceProvider.getPathSeparator();
    this.userPathName = this.appServiceProvider.getUserNodeName();
    this.Resto_Info = this.appServiceProvider.getresto();
    this.appUser = this.appServiceProvider.getappUser();
    this.waiters = this.appServiceProvider.getWaiters();
    this.items = this.appServiceProvider.getMenuItems();
    this.cashiers = this.appServiceProvider.getCashierDetails();
    this.tables = this.appServiceProvider.getTables();
    this.rooms = this.appServiceProvider.getRooms();
    this.table_info = this.appServiceProvider.getTableInfo();
    this.vacant_table = this.appServiceProvider.getVacantTables();
    this.allocated_table = this.appServiceProvider.getAllocatedTables();
    this.tablesArray = new Array<TableInfo>();
    this.firebaseTwoWayBindStore = new Array<Object>();
    this.regexEmail = this.appServiceProvider.getEmailRegex();
    //Let's make this always available and readymade
    this.userNodePath = this.appName + this.pathSeparator + this.userPathName;
    this.userNode = this.afDB.database.ref(this.userNodePath);

    this.basicUser = {
      firstName:null,
      lastName:null,
      phone:null,
      email:null,
      authType:null, //phone vs email vs facebook etc
      OTP:null,
      signupIP:null
    };
    //******* Firebase node inside user ref key  ***********************
    this.nodepath_insideuser()
    // ***********************end****************************************
    this.insideuserkey(); 
    this.getRole();
    this.getId();
    this.db = firebase.firestore();
  }

  /**
   *fun nodepath_insideuser will return complete path of appuser from database
   */
  nodepath_insideuser() {
    if(this.insideuserNode!='null'){
      this.appuserPath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator;
      return this.appuserPath;
    }
  }

  TimeStamp() {
    let date = JSON.stringify(moment().format("YYYY-MM-DD"));
    let time = JSON.stringify(moment().format("h:mm:ss a"));
    this.timestamp = date + " at " + time;
     this.appServiceProvider.console_log("time", this.timestamp);
    return this.timestamp;
  }

  loginUserWithEmailAndPassword(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signoutUser(): Promise<any> {
    console.error("clearning all local storage keys on user signout");
    // this.storage.clear();
    this.appServiceProvider.loginStatus=false;
    return firebase.auth().signOut().then(()=>{
      this.storage.set('user',null);
    }).catch(e=>{
       this.appServiceProvider.console_log(e);
    });
  }

  //*********user info register for check in local storage*********************************
  nodepath_insideuser_path(user) {
    let regexPhone: any = /^\d{10}/;
    let isPhone = regexPhone.test(user);
    let isEmail = this.regexEmail.test(user);
    //********searching node with email longin user *************
    let param: any;
    if (isEmail) {
      param = {
        authType: AuthTypes.email,
        authValue: user,
      };
    } else {
      param = {
        authType: AuthTypes.phone,
        authValue: user,
      };
      //***** call fun
    }

     this.appServiceProvider.console_log(param);
    //***** call function to check **
    this.getauthData(param)
      .then((res) => {
        //*****user key provided by firebase***********
         this.appServiceProvider.console_log(res);
        this.insideuserNode = res.secreat_key;
        this.storage
          .set("userFireKey", this.insideuserNode)
          .then(() => {
             this.appServiceProvider.console_log("set");
            this.insideuserkey();
          })
          .catch((e) => {
            //*** regi in database *********
            let time = this.TimeStamp();
            let param = {
              page: "AuthProvider",
              error: e,
              timestamp: time,
              lineno: 720,
              appVersion: "",
              details: "storage setting problem ",
            };
            this.authError(param);
          });

        this.appuserPath =
          this.appName +
          this.pathSeparator +
          this.userPathName +
          this.pathSeparator +
          this.insideuserNode +
          this.pathSeparator +
          this.appUser +
          this.pathSeparator;
      })
      .catch((e) => {
        let time = this.TimeStamp();
        let param = {
          page: "AuthProvider",
          error: e,
          timestamp: time,
          lineno: 727,
          appVersion: "",
          details: "admin id not found ",
        };
        this.authError(param);
      });
  }

  createAnonymousUser(): Promise<any> {
    return firebase.auth().signInAnonymously();
  }

  /**
   *errorRegister fn is to store error in database
   */
  errorRegister(e) {
    let errorsNode =
      this.appName +
      this.pathSeparator +
      this.userPathName +
      this.pathSeparator +
      this.insideuserNode +
      this.pathSeparator +
      this.errorPathName;
    let errorNodeRef = this.afDB.database.ref(errorsNode);
    errorNodeRef.push(e);
  }
  getInsideUser() {
    //The following is a promise return
    return  this.storage.get(this.insideUserKey);
  }

  /**
   * getauthData is for fetching authid data from database
   */
  getauthData(data: any) {
    return new Promise<any>((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "secret-key",
        }),
      };

      this.http
        .post(
          "https://us-central1-restaurant-27f85.cloudfunctions.net/getuserId",
          data,
          httpOptions
        )
        .subscribe(
          (res) => {
             console.log("user data exist", res);
            resolve(res);
          },
          (error) => {
            reject(error);
             console.log("error", error);
          }
        );
    });
  }

   /**
   *insideuserkey fun is to fetch admin node id from local storage
   */

  insideuserkey(){
    this.storage.get('userFireKey').then((data)=>{
      this.insideuserNode = String(data);
       this.appServiceProvider.console_log("user firekey " , this.insideuserNode);
    }).catch((e) =>{
      //alert("error retrieving object from local storage:"+e);
    });
    this.storage.get('adminFireKey').then((data)=>{
      this.insideuserNode = String(data);
       this.appServiceProvider.console_log("admin firekey " , data);
    }).catch((e) =>{
      //alert("error retrieving object from local storage:"+e);
    });

  }

  /**@PP NOTE This method is not correct as you can not return a non-promise variable from a promise call*/
  insideuser() {
    let insideuserkey:string;
    this.insideuserkey();
    this.getId();
    this.getRole();
    if(this.insideuserNode!='null'){
       this.appServiceProvider.console_log(this.insideuserNode);
      return this.insideuserNode;
    }
    else{
      //alert("undefined Path");
    }
  }

 async getRole(){
   console.log(this.insideuserNode);
  await this.db.collection("client").where("secreat_key","==",this.insideuserNode).get().then((snap)=>{
     snap.forEach((doc)=>{
         this.myRole = doc.data().RoleType;
         console.log('role is:',this.myRole);
         return this.myRole;
     })
   }).catch((e)=>{
     console.log('error is:',e);
   })
  }
  async getId(){
    await this.db.collection("client").where("secreat_key","==",this.insideuserNode).get().then((snap)=>{
      snap.forEach((doc)=>{
           if(doc.data().RoleType=="master_superAdmin"){
            this.myroleKey = doc.data().secreat_key;
            return this.myroleKey;
           }else if(doc.data().RoleType=="Super_Admin"){
             this.myroleKey = doc.data().masteradminkey;
             return this.myroleKey;
           }
          
      })
    }).catch((e)=>{
      console.log('error is:',e);
    })
   }
  /**
   * auth erroe is to store error while authantication or any other before login
   */
  authError(data: any) {
    let path: string =
      
      this.userPathName +
     
      this.appErrorName +
      this.pathSeparator;
     this.appServiceProvider.console_log(path);
    let param = {
      data: data,
      path: path,
    };
    this.postData(param)
      .then((res) => {
         this.appServiceProvider.console_log("error added successfully", res);
      })
      .catch((e) => {
         this.appServiceProvider.console_log("error", e);
      });
  }

  /**
 * postData is to store post throw functions in  database
 */
postData(data:any){
  return new Promise<any>((resolve,reject)=>{
  let httpOptions={
    headers:new HttpHeaders({
'Content-Type':'application/json',
    'Authorization':'secret-key'
  })
  }

this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/app_auth_error',data,httpOptions).subscribe(
  res=>{
     this.appServiceProvider.console_log(" added successfully",res);
    resolve(res);
  },
  error=>{
    reject(error);
     this.appServiceProvider.console_log("error",error);
  }
);
});

}

/**send sms to a particular number */

sendSms(data:any){
  return new Promise<any>((resolve,reject)=>{
    let httpOptions={
      headers:new HttpHeaders({
      'Content-Type' :'application/json',
      'Authorization':'secret-key'
    })
    }
  this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/sendMessage',data,httpOptions).subscribe(
  res=>{
   this.appServiceProvider.console_log("message send successfully",res);
  resolve(res);
  },
  error=>{
  reject(error);
   this.appServiceProvider.console_log("error",error);
  }
);  
  })
}
  
  /**send email to a particular email id */

sendEmail(data:any){
  return new Promise<any>((resolve,reject)=>{
    let httpOptions={
      headers:new HttpHeaders({
      'Content-Type' :'application/json',
      'Authorization':'secret-key'
    })
    }
  this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/sendMail',data,httpOptions).subscribe(
  res=>{
   this.appServiceProvider.console_log("Email send successfully",res);
  resolve(res);
  },
  error=>{
  reject(error);
   this.appServiceProvider.console_log("error",error);
  }
);  
  })
}




postLoginData(data:any){
  return new Promise<any>((resolve,reject)=>{
  let httpOptions={
    headers:new HttpHeaders({
'Content-Type':'application/json',
    'Authorization':'secret-key'
  })
  }

this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/post_user_data',data,httpOptions).subscribe(
  res=>{
     this.appServiceProvider.console_log(" added successfully",res);
    resolve(res);
  },
  error=>{
    reject(error);
     this.appServiceProvider.console_log("error",error);
  }
);
});

}

/**
 * get users uuid is to search uuis is present or not in admin node
 */
getusersUUID(data:any){
  return new Promise<any>((resolve,reject)=>{
  
    let httpOptions={
      headers:new HttpHeaders({
  'Content-Type':'application/json',
      'Authorization':'secret-key'
    })
    }
     this.appServiceProvider.console_log("data in get usersUUID:", data);
  
  this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/getUsersUUID',data,httpOptions).subscribe(
    res=>{
       this.appServiceProvider.console_log("user data exist",res);
      resolve(res);
    },
    error=>{
      reject(error);
       this.appServiceProvider.console_log("error",error);
    }
  );
  });
  
  }

  setAuthInfo(authInfo):void{
    ///Add a type validation here
    this.authInfo = authInfo;
  }
  setUserNodePath(path):void{
    this.userNodePath = path;
  }

  setloginData(data){
    this.loginDataFromLogin=data;
  }
  resetloginData(data){
    this.loginDataFromLogin = null;
  }
  getloginData(){
    return this.loginDataFromLogin;
  }

  ///Initialize the recaptcha
  initRecaptcha(domElement:ElementRef,recpatchaElement:string){
    domElement.nativeElement.innerText="";
    let recaptchaVerifier:firebase.auth.RecaptchaVerifier = new firebase.auth.RecaptchaVerifier(recpatchaElement,{
      'size': 'normal',
      'callback': (response)=> {
         this.appServiceProvider.console_log("success", response);
      },
      'expired-callback': ()=> {
         this.appServiceProvider.console_log("expired-callback");
      }
    });
    return recaptchaVerifier;
  }

  clearRecaptchaFromDom(recaptchaVerifier:any){
    try{
      if(recaptchaVerifier)
        recaptchaVerifier.clear();
    }catch(e){

      console.error("caught exception during recaptcha clearance",e);
    }
  }

  getErrorAlert(error){
    return this.alertCtrl.create({
      message:"Oops!" + error,
      buttons:[{text:'OK',
        handler:(data)=>{
           this.appServiceProvider.console_log("error:",error);
        }
      }]})
  }
  signupUserWithEmailAndPassword(newEmail: string, newPassword: string): Promise<any> {
    return firebase.auth().createUserWithEmailAndPassword(newEmail, newPassword);
  }
  resetEmailPassword(email: string): Promise<any> {
    return this.afAuth.sendPasswordResetEmail(email)
    // return this.afAuth.auth.sendPasswordResetEmail(email);
  }


  //This is equivalent to signup in case of phone auth
  loginUserWithPhone(phoneNumberString: string, appVerifier: any) :void {
    firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
    .then( async (confirmationResult)=> {
      // SMS sent. Prompt user to type the code f rom the message, then sign the
      // user in with confirmationResult.confirm(code).
      let prompt = await this.alertCtrl.create({
        message: 'Enter the OTP',
        inputs: [{ name: 'confirmationCode', placeholder: 'Enter OTP' }],
        buttons: [
          { text: 'Cancel',
            handler: data => {  this.appServiceProvider.console_log('Cancel clicked'); }
          },
          { text: 'Send',
            handler: data => {
              confirmationResult.confirm(data.confirmationCode)
                .then( (result) =>{
                   this.appServiceProvider.console_log("inside alert");
                  if(this.currentUser){
                    this.basicUser.authType =  String(AuthTypes.phone);
                    this.basicUser.OTP =  String(data.confirmationCode);
                    this.currentUser  = firebase.auth().currentUser;
                    if(this.currentUser.phoneNumber){
                      this.basicUser.phone =  String(this.currentUser.phoneNumber);
                    }
                  }
                }).catch(function (error) {
                  // User couldn't sign in (bad verification code?)
                  let alert = this.alertCtrl.create({
                    headers: 'Oops!',
                    message: error ,
                    buttons: [
                      {
                        text: 'OK',
                        role: 'cancel',
                        handler: () => {
                           this.appServiceProvider.console_log('Cancel clicked');
                        }
                      }						]
                  });
                  alert.present();
                   this.appServiceProvider.console_log("login attempt failed with error",error);
                });
            }
          }
        ]
      });
      await prompt.present();
    })
    .catch( (error)=> {
      let time=this.TimeStamp();
      let param={
        "page":"AuthProvider",
        "error":error,
        "timestamp":time,
        "lineno":227,
        "appVersion":"",
        "details":"signup with phone no "
      }
      this.authError(param);

      console.error("SMS not sent", error);
    });
  }

  updateUser(firstName:string = null,lastName:string = null, phoneNo:string = null, email:string = null,passwordHash:any = null):void{
    let user  = firebase.auth().currentUser;
    if (user) { //If there is a current user
      let uid = user.uid;
      this.userNodePath = this.appName + this.pathSeparator + this.userPathName  ;
      if(!this.userNode)
        this.userNode = this.afDB.database.ref(this.userNodePath);
      let userData={
        "firstName":firstName,
        "lastName":lastName,
        "phoneNo":phoneNo,
        "email":email,
        "UID":uid,
        "passwordHash":passwordHash
      };
      this.userNode.push(userData);
    }
  }

  /**
   * updateCashier is to add cashier information in datatabase
   */
  updateCashier(name:string = null, phone:string = null,cashiercode:number):void{
    if(this.insideuserNode!='null'){
      this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.cashiers ;
      this. userNode = this.afDB.database.ref(this.userNodePath);
      let Cashiers={
        "name":name,
        "phone":phone,
        "cashiercode":cashiercode
      };
      let refnode = this.afDB.database.ref(this.userNodePath).orderByChild("phone").equalTo(phone).once("value",(snapshot)=>{
        if(snapshot.val()==null){
          this.userNode.push(Cashiers);
        }
      });
    }
  }

  /**
   * updateCashierdelete is to delete cashier from database
   */
  updateCashierdelete(child):void{
    this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.cashiers;
    let refnode = this.afDB.database.ref(this.userNodePath).orderByChild("phone").equalTo(child.phone).once("value",(snapshot)=>{
      snapshot.forEach((childSnapshot)=> {
        let childRef = childSnapshot.ref;
        childRef.remove();
      });
    });
  }

  /**
   * updatecashierphone is to update cashier information from database
   */
  updatecashierphone(old_phone:any,new_phone:any,cashier_name:string,code:number):void{
    this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.cashiers ;
    let refnode = this.afDB.database.ref(this.userNodePath).orderByChild('phone').once("value",(snapshot)=>{
      snapshot.forEach((childSnapshot)=> {
        let childData = childSnapshot.val();
        if(childData.phone == old_phone){
          let childRef = childSnapshot.ref;
          childRef.update({phone:new_phone,name:cashier_name,cashiercode:code});
        }
      });
    });
  }

  /**
   * updatetablestreanth is to update table streanth in database
   */
  updatetablestrength(table:any,strength:any):void{
    this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.tables;
    let refnode = this.afDB.database.ref(this.userNodePath).orderByChild('table').once("value",(snapshot)=>{
      snapshot.forEach((childSnapshot)=> {
        let childData = childSnapshot.val();
        if(childData.table == table){
          let childRef = childSnapshot.ref;
          childRef.update({strength:strength});
        }
      });
    });
  }

  removeUUID(data:any){
    return new Promise<any>((resolve,reject)=>{
    
      let httpOptions={
        headers:new HttpHeaders({
    'Content-Type':'application/json',
        'Authorization':'secret-key'
      })
      }
    
    this.http.post('https://us-central1-restaurant-27f85.cloudfunctions.net/removeData',data,httpOptions).subscribe(
      res=>{
         this.appServiceProvider.console_log("removed uuid",res);
        resolve(res);
      },
      error=>{
        reject(error);
        console.log("error",error);
      }
    );
    });
    
    }














    
    /**
     * updateTable is for add table in database
     */
    updateTable(table:number,strength:number) : void{
      let alertmsg:string;
      if(this.insideuserNode!='null'){
        this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.tables ;
        this.userNode = this.afDB.database.ref(this.userNodePath);
        let Tables={
          "table":table,
          "strength":strength,
          "OccupiedStatus":false
  
        };
        let userNode = this.afDB.database.ref(this.userNodePath).orderByChild("table").equalTo(table).once("value",(snapshot)=>{
          if(snapshot.val()==null){
            this.userNode.push(Tables).then(async()=>{
              let toast = await this.toastCtrl.create({
                message: ' Table Added Successfully.',
                duration: 2000,
                position: 'top'
              });
              toast.onDidDismiss();
              await toast.present();
            }).catch((e)=>{
              let time=this.TimeStamp();
              let param={
                "page":"AuthProvider",
                "error":e,
                "timestamp":time,
                "lineno":377,
                "appVersion":"",
                "details":"error on add table"
              }
              this.errorRegister(param);
  
              //alert( "Oppsss" + e);
            });
            alertmsg="Table added Succesfully";
            this.events.publish('alertmsg:table',alertmsg);
          }
          else if(snapshot.val()!=null){
            alertmsg="Table already presenty";
            this.events.publish('alertmsg:table',alertmsg);
            //alert( "Table Present!");
          }
        });
      }
    }
    
    
    /**
     * deleteTable is to delete table from database
     */
    deleteTable(tableno):Promise<any>{
      this.userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+this.appUser+this.pathSeparator+this.tables ;
      let refnode = this.afDB.database.ref(this.userNodePath).orderByChild("table").equalTo(tableno).once("value",(snapshot)=>{
        if(snapshot.val()!=null){
          snapshot.forEach((childSnapshot)=> {
            let childRef = childSnapshot.ref;
            childRef.remove().then(async ()=> {
              let toast = await this.toastCtrl.create({
                message: 'Table deleted Successfully',
                duration: 2000,
                position: 'top'
              });
              await toast.present();
            }).catch(e=>{
              let time=this.TimeStamp();
              let param={
                "page":"AuthProvider",
                "error":e,
                "timestamp":time,
                "lineno":451,
                "appVersion":"",
                "details":"error on delete table "
              }
              this.errorRegister(param);
  
            });
          });
        }
        else if (snapshot.val()==null){
          //alert( "Table does not exist!");
        }
      });
      return;
    }
   
    //Add a new user
    addNewUser(user:object):void{
       this.appServiceProvider.console_log("adding new user",user);
    }
  
     getAlertView(){
      return this.alertCtrl.create({
        header: 'Opssss....',
        message: 'Table is Allocated',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: data => {
               this.appServiceProvider.console_log('Cancel clicked');
            }
          }],
        cssClass:'alertCustomCss'
      });
    }
    /**
     * addItems is for add item in database
     */
  
    addItems(data:any ): Promise<void>{
      return new Promise((resolve, reject) => {
        if(this.insideuserNode!='null'){
          let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.items ;
          let  userNode = this.afDB.database.ref(userNodePath);
          let refnode = this.afDB.database.ref(userNodePath).orderByChild('itemname').equalTo(data.itemname) .once("value",(snapshot)=>{
   this.appServiceProvider.console_log(snapshot.val());
            if( snapshot.val()!=null &&  snapshot.val().itemname == data.itemname){
  
               this.appServiceProvider.console_log("error on item ");
              reject();
            }
            else if(snapshot.val() == null ) {
              let itemsData={
                "itemIndex":data.itemIndex,
                "includedgst":data.includedgst,
                "itemname":data.itemname,
                "fullplate":data.fullplate,
                "gstfullplate":data.gstfullplate,
                "halfplate":data.halfplate,
                "gsthalfplate":data.gsthalfplate,
                "fpics":data.fpics,
                "hpics":data.hpics,
                "itemtype":data.itemtype
              };
              userNode.push(itemsData).then(()=>{
                resolve();
              });
              
            }
          });
        }
  
  
      });
    }
    /**
     * deleteitem is  to delete item from database
     */
    deleteitem(data:any):Promise<void>{
      return new Promise((resolve,reject)=>{
        let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.items ;
        let refnode = this.afDB.database.ref(userNodePath).orderByChild("itemIndex").equalTo(data.itemIndex).once("value",(snapshot)=>{
  if(snapshot.val()){
          snapshot.forEach((childSnapshot)=> {
            if(childSnapshot.val().itemIndex==data.itemIndex){
            let childRef = childSnapshot.ref;
            childRef.remove().then(()=>{
              resolve();
             // let insideuserkey = this.insideuser();
              
            }).catch((e)=>{
              reject();
              let time=this.TimeStamp();
              let param={
                "page":"AuthProvider",
                "error":e,
                "timestamp":time,
                "lineno":825,
                "appVersion":"",
                "details":"error on item pic  delete "
              }
              this.errorRegister(param);
  
            });
            }
          });
  
  }
  else{
  resolve();
  }
        });
      });
    }
    /**
     * updateItem is to update item information in database
     */
    updateItem(data:any,userkey:string):void{
      let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.items;
      let itemsData={
        "itemname":data.itemname,
        "fullplate":data.fullplate,
        "gstfullplate":data.gstfullplate,
        "halfplate":data.halfplate,
        "gsthalfplate":data.gsthalfplate,
        "fpics":data.fpics,
        "hpics":data.hpics,
        "itemtype":data.itemtype,
        "itemIndex":data.itemIndex,
        "includedgst":data.includedgst
      };
      let refnode = this.afDB.database.ref(userNodePath).orderByChild('itemIndex').equalTo(data.itemIndex) .once("value",(snapshot)=>{
        snapshot.forEach((childSnapshot)=> {
          let childData = childSnapshot.val();
          if(childData.itemIndex == data.itemIndex){
            let childRef = childSnapshot.ref;
             this.appServiceProvider.console_log(childData.itemname,data.itemname);
            childRef.update(itemsData).then(()=>{
               this.appServiceProvider.console_log("edited");
              
            }).catch((e)=>{
              let time=this.TimeStamp();
              let param={
                "page":" DeviceregisterPage",
                "error":e,
                "timestamp":time,
                "lineno":886,
                "appVersion":"",
                "details":"error on item Update  "
              }
              this.errorRegister(param);
  
            });
          }
        });
      });
    }
    
    /**
     * addRestoInfo is to add restaurant inforemation in database
     */
    addRestoInfo(data:any ):void{
      if(this.insideuserNode!='null'){
        let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.Resto_Info ;
        let  userNode = this.afDB.database.ref(userNodePath);
        let RestoData={
          "rname":data.rname,
          "adminname":data.adminname,
          "raddress":data.raddress,
          "cnumber":data.cnumber,
          "email":data.email,
          "altemail":data.altemail,
          "gstno":data.gstno
        };
        userNode.set(RestoData);
      }
    }
    /**
     * deleteRestoInfo is to delete restaurant information from database
     */
    deleteRestoInfo(data:any):void{
      let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.Resto_Info ;
      let refnode = this.afDB.database.ref(userNodePath);
      refnode.remove();
    }
    /**
     * updateRstoInfor is to update restaurant information
     */
    updateRestoInfo(data:any):void{
      let userNodePath = this.appName + this.pathSeparator + this.userPathName +this.pathSeparator+this.insideuserNode+this.pathSeparator+ this.appUser+this.pathSeparator+this.Resto_Info;
      let RestoData={
        "rname":data.rname,
        "adminname":data.adminname,
        "raddress":data.raddress,
        "cnumber":data.cnumber,
        //  "kitchennumber":data.kitchennumber,
        "email":data.email,
        "altemail":data.altemail,
        "gstno":data.gstno
      };
      let refnode = this.afDB.database.ref(userNodePath);
      refnode.update(RestoData);
    }
    /**
     * getRestoInfo is to get restaurant information from database
     */
    getRestoInfo() {
  return new Promise<any>((resolve,reject)=>{
      let  userNodePath=this.appuserPath + this.pathSeparator + this.Resto_Info;
       this.appServiceProvider.console_log(userNodePath);
      let userNode = this.afDB.database.ref(userNodePath);
      userNode.orderByChild('Resto_Info').once("value",(snapshot) => {
         this.appServiceProvider.console_log(snapshot.val());
        if(snapshot.val()!=null){
        this.restoInfo=snapshot.val();
        resolve(this.restoInfo);
        }else{
  reject(false);
        }
           });
  });
      }
    getUserNodePath():string{
      return this.userNodePath;
    }
    getAuthInfo():AuthInfo{
      return this.authInfo;
    }
    setUserGlobalPath(userNodePath){
    }
    
  
  
    phoneConfirmationService(navCtrl,confirmationResult,data,recaptchaVerifier){
      confirmationResult.confirm(data.confirmationCode)
        .then( (result) =>{
          this.navCtrl.navigateRoot("/home");
        }).catch( async (error) =>{
          this.clearRecaptchaFromDom(recaptchaVerifier);
          let errorConfirmationPrompt = await this.alertCtrl.create({
            message:"Oops!" + error,
            buttons:[{
              text:"OK",
              handler:(handlerData) =>{
                console.error("OK pressed");
              }}]                    });
              await errorConfirmationPrompt.present();
          let time=this.TimeStamp();
          let param={
            "page":"AuthProvider",
            "error":error,
            "timestamp":time,
            "lineno":1044,
            "appVersion":"",
            "details":"error on phoneConfirmationService "
          }
          this.authError(param);
  
  
        });
    }
  
  
    getConfirmationPrompt(navCtrl,recaptchaVerifier,confirmationResult){
      return this.alertCtrl.create({
        message: 'Enter the Confirmation code',
        inputs: [{ name: 'confirmationCode', placeholder: 'Confirmation Code' }],
        buttons: [
          { text: 'Cancel',
            handler: data => {
               this.appServiceProvider.console_log('Cancel clicked');
              this.clearRecaptchaFromDom(recaptchaVerifier);
                        }
          },
          { text: 'Send',
            handler: data => {
              this.phoneConfirmationService(navCtrl,confirmationResult,data,recaptchaVerifier);
              this.clearRecaptchaFromDom(recaptchaVerifier);
            }
          }
        ]
      });
    }

  update_users_login(uuid:any,login:boolean){
   this.appServiceProvider.console_log("uid",uuid);
  return new Promise<any>((resolve,reject)=>{
  let insideuser = this.insideuserkey();
   let userNodePath = this.appName + this.pathSeparator + this.userPathName + this.pathSeparator + this.insideuserNode + this.pathSeparator + "user_devices" ;
   let userNode = this.afDB.database.ref(userNodePath);
     this.appServiceProvider.console_log(userNodePath);
   userNode .orderByChild("uuid").equalTo(uuid).once("value",(snapshot)=>{
   this.appServiceProvider.console_log(snapshot.val());
     if(snapshot.val()!==null){
              snapshot.forEach((snap)=> {
                if(snap.val().uuid===uuid ){
                  const childRef = snap.ref;
                  childRef.update({"login":login}).then(()=>{
                      resolve(true);
                  }).catch((e)=>{
              reject(e);
                  });
                }
              });
            }
    else{
     reject(false);
  
       }
  });
  
  })
  
  }

  getUserDetails(){

    //let uid = firebase.auth().currentUser.uid;
     this.appServiceProvider.console_log(this.insideuserNode);
    // let userNodeRef= firebase.database().ref('RestaurantManagement/users');
    // userNodeRef.once("value").then((snapShot)=>{
    //    this.appServiceProvider.console_log(snapShot.val());
    // }
    
  
  
  }
  /**
   * getClientInfo function is for fetching client information by thair secreatkey .
   * @param id - clients secreate key to get client information 
   * @return { json } - this function will return client information .
   * 
   */
  getClientInfo(secreat_key:string){
    return new Promise<any>((resolve,reject)=>{
      let  userNodePath=this.clientCollection;
       this.appServiceProvider.console_log(userNodePath,secreat_key);
      let userNode = this.db.collection(this.clientCollection);
      userNode.where("secreat_key","==",secreat_key).get().then((querySnapshot)=>{
        if(querySnapshot.size>0){
          querySnapshot.forEach((doc)=>{
            let info = doc.data();
            resolve(info);
          })
        }else{
          reject();
        }
      })
  
    });
  
  }

  
  /** add client information in firebase database in client node
   * @param {object} - json object that contains client information.
   * @return { promise }- a promise return ;
   */
  // addClientInfo(data:any ){
  //   return new Promise<any>((resolve,reject)=>{
  //   let  userNodePath=this.appName + this.pathSeparator + this.clientCollection;
  //    this.appServiceProvider.console_log(userNodePath);
    
  //   let clientData={
  //     "secreat_key":data.clientId,
  //     "firstName":data.clientFName,
  //     "lastName":data.clientLName,
  //     "email":data.email,
  //     "phone":data.cnumber,
  //     "address":data.clientaddr,
      
  //   };
  //  let userNode = this.afDB.database.ref(userNodePath);
  //      this.appServiceProvider.console_log("client data",clientData);
  //     userNode.push(clientData).then((snap)=>{
  //       resolve();
      
  //   }).catch(e=>{
  //     reject(e);
  //      this.appServiceProvider.console_log(e);
  //   });
  
  //   });
    
  // }

  async addClientInfo(data:any ){
    this.data ={
          //"secreat_key":data.clientId,
          "RoleType":data.RoleType,
          "clientName":data.clientName,
          "clientaddr":data.clientaddr,
          "email":data.email,
          "number":data.cnumber,
          "branches":data.no_of_branches
    }
    if(data.RoleType==="master_superAdmin"){
      this.appServiceProvider.console_log("Data",this.data);
      await this.db.collection("client").add(this.data).then((docRef) => {
        this.appServiceProvider.console_log("Document written with ID: ", docRef.id);
        docRef.update({
          secreat_key: docRef.id,
          masteradminkey:docRef.id
        }).then(()=>{
             this.appServiceProvider.console_log("Client updated");
      }).catch((e)=>{
             this.appServiceProvider.console_log("error is:",e);
          
        });
  }).catch((error) => {
          console.error("Error adding document: ", error);
      });
    }else{
      this.appServiceProvider.console_log("Data",this.data);
      await this.db.collection("client").add(this.data).then((docRef) => {
        this.appServiceProvider.console_log("Document written with ID: ", docRef.id);
        docRef.update({
          secreat_key: docRef.id,
        }).then(()=>{
             this.appServiceProvider.console_log("Client updated");
      }).catch((e)=>{
             this.appServiceProvider.console_log("error is:",e);
          
        });
  }).catch((error) => {
          console.error("Error adding document: ", error);
      });
    }
   
    }

  async sendMsg(data:any){
       let msg = {
         to:data.toNumber,
         from:data.from,
         body:data.message
       }
       return this.client.messages.create(msg).then(()=>{
         this.appServiceProvider.console_log('success');
       }).catch((e)=>{
         this.appServiceProvider.console_log('error is:',e);
       })
    }


    EditAdminInfo(id, datas){
      return new Promise((resolve, reject)=>{
        this.db.collection("client").doc(id).update(datas).then((result)=>{
          console.log(result);
          resolve("updated");
        }).catch(e=>{
          reject(e)
        })
      });
    }


    async addAdminInfo(data:any){

      //   this.data = {
      //     "secrateKey":data.secretKey,
      //     "fullName": data.fullName,
      //     "email":data.email,
      //     "phoneNumber":data.phone,
      //     "country": data.country,
      //     "restaurantAddress":data.restaurantAddress,
      //     "rollType":data.role
      //   }
       console.log('email:',data);
       if(this.myRole=="master_superAdmin"){
        await this.db.collection("client").add(data).then(async(docRef)=>{
          console.log("Document written with ID: ", docRef.id);
          docRef.update({
           secreat_key : docRef.id,
           masteradminkey:this.myroleKey
          }).then(()=>{
           console.log("Client updated");
          }).catch((e)=>{
           console.log("error is update:",e);
          })
          }).catch((e)=>{
          console.log("error is:",e);
          }) 
       }else if(this.myRole=="Super_Admin"){
        await this.db.collection("client").add(data).then(async(docRef)=>{
          console.log("Document written with ID: ", docRef.id);
          docRef.update({
           secreat_key : docRef.id,
           masteradminkey:this.myroleKey
          }).then(()=>{
           console.log("Client updated");
          }).catch((e)=>{
           console.log("error is update:",e);
          })
          }).catch((e)=>{
          console.log("error is:",e);
          }) 
       }else{
          console.log('i am here');
       }
     
  
      }


     DeleteAdminInfo(id){
      return new Promise((resolve, reject)=>{
        this.db.collection("client").doc(id).delete().then(result=>{
          resolve("deleted");
        }).catch(e=>console.log(e)
        )
      }); 
    }


    async authorlist(){
      console.log(this.myRole);
      let clientArray = [];
      if(this.myRole =="master_superAdmin"){
        return new Promise<any>(async(resolve,reject)=>{
          this.db.collection("client").where('masteradminkey','==',this.insideuserNode).where("RoleType","==","Admin").get().then(async (snap)=>{
            await snap.forEach((data)=>{
              clientArray.push(data.data());
            });
            console.log(clientArray);
            resolve(clientArray);
          }).catch((e)=>{
            reject(e);
      });
    });
  } 
  else if(this.myRole=="Super_Admin")
  {
    return new Promise<any>(async(resolve,reject)=>{
      console.log(this.insideuserNode);
      this.db.collection("client").where('secreat_key','==',this.insideuserNode).get().then(async (snap)=>{
        await snap.forEach(async(doc)=>{
          // clientArray.push(data.data());
          let key = doc.data().masteradminkey;
           await this.db.collection("client").where("masteradminkey","==",key).where("RoleType","==","Admin").get().then(async(snap)=>{
             snap.forEach((doc)=>{
               console.log(doc.data());
               clientArray.push(doc.data());
             })
             resolve(clientArray);
           }).catch((e)=>{

           })
        });
      }).catch((e)=>{
        reject(e);
  });
  });
  }
  else
  {

  }
  
  }


 async clientList(){
      let clientArray = [];
      return new Promise<any>(async(resolve,reject)=>{
        this.db.collection("client").where('masteradminkey','==',this.insideuserNode).get().then(async (snap)=>{
          await snap.forEach((data)=>{
            clientArray.push(data.data().secreat_key);
          });
          console.log(clientArray);
          resolve(clientArray);
        }).catch((e)=>{
          reject(e);
    });
  });
  }

}