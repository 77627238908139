import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DatePipe } from '@angular/common'
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule,
 //  Storage 
  } from "@ionic/storage";
import { AngularFireModule } from "@angular/fire";
import { environment } from 'src/environments/environment';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from '@angular/fire/auth';
import { AppserviceService } from './services/appservice.service';
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { HttpClientModule } from "@angular/common/http";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { PouchdbauthService } from './services/pouchdbauth.service';
import { File } from '@ionic-native/file/ngx';
import { FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Printer } from '@ionic-native/printer/ngx';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Network } from '@ionic-native/network/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Push } from '@ionic-native/push/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { PayPal, PayPalPayment, PayPalConfiguration } from '@ionic-native/paypal/ngx';
 
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    File,
    Push,
    FileTransferObject,
    Printer,
    FirebaseAnalytics,
    StatusBar,
    NetworkInterface,
    SplashScreen,
    LocalNotifications,
    WebIntent,
    FCM,
    Device,
    GoogleAnalytics,
    Network,
    BatteryStatus,
    Badge,
    DatePipe,
    Geolocation,
    SpeechRecognition,
    AngularFirestore,
    AngularFireStorage,
    AngularFireAuth,
    UniqueDeviceID,
    PayPal,
  AppserviceService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PouchdbauthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
