import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage:Storage
  ) { 
    console.log('Hello StorageServiceProvider Provider');
  }

  setStoredObject(key:string,value:string):Promise<void>{
		return this.storage.set(key,value);
	}
	getStoredObject(key:string):Promise<string>{
		return this.storage.get(key);
	}
	deleteStoredObject(key:string):Promise<void>{
		return this.storage.remove(key);
	}
}
