import { Injectable } from '@angular/core';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { HttpClient } from '@angular/common/http';
import {NgZone} from '@angular/core';
import { LocationInfo } from '../interface/location_info';
 declare var google;
@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  public locationObj:LocationInfo={
    position:null,
    city:null,
    description:null,
    state:null,
    country:null,
    zip_code:null
  };
  public autocomplete:any;
  public geocoder:any;
  public GoogleAutocomplete:any;
  public current_country:string;
  public country_code:Array<any>=[];
  public selected_country_name:string=null;
  public autocompleteItemsState:any;
  public selected_country_code:string=null;
  public autocompleteItemsCountry:any;
  public currentLocation:any;
  constructor(
    public http: HttpClient,
    public zone: NgZone,
    public geolocation: Geolocation
  ) {
    console.log('Hello GeoLocationProvider Provider');
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
    this.autocomplete = { input: '' };
    this.autocompleteItemsState = [];
    this.autocompleteItemsCountry =[];
     this.geocoder = new google.maps.Geocoder;
     this.getCurrentCountryCode().then((country)=>{
       this.current_country = country;
      
     }).catch((e)=>{
      console.log(e);
     });
   }


   async getCurrentLoaction(){
    return new Promise(async (resolve,reject)=>{
    await this.geolocation.getCurrentPosition().then((resp) => {
      console.log("res",resp);
      this.currentLocation = {
        lat:resp.coords.latitude,
        lng: resp.coords.longitude
      }
      resolve(this.currentLocation);
      // this.latlng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
      // this.distance(position2,this.orderDetails.customer.location.position);
  
    }).catch((error) => {
      reject(error);
      console.log('Error getting location', error);
    });

  });

   }
 getcurrentLocation(){
   return this.currentLocation;
 }

  async  distance(position1, position2) {
    console.log(position1,position2);
    return new Promise(async (resolve,reject)=>{
    const bounds = new google.maps.LatLngBounds();
    let  markersArray:any;
  
    // const origin1 = { lat: 55.93, lng: -3.118 };
    // const origin2 = "Greenwich, England";
    // const destinationA = "Stockholm, Sweden";
    // const destinationB = { lat: 50.087, lng: 14.421 };
  
    // const destinationIcon =
    //   "https://chart.googleapis.com/chart?" +
    //   "chst=d_map_pin_letter&chld=D|FF0000|000000";
    // const originIcon =
    //   "https://chart.googleapis.com/chart?" +
    //   "chst=d_map_pin_letter&chld=O|FFFF00|000000";
    // const map = new google.maps.Map(
    //   document.getElementById("map") as HTMLElement,
    //   {
    //     center: { lat: 55.53, lng: 9.4 },
    //     zoom: 10,
    //   }
    // );
    const geocoder = new google.maps.Geocoder();
  
    const service = new google.maps.DistanceMatrixService();
  await  service.getDistanceMatrix(
      {
        origins: [position1],
        destinations: [position2],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
      console.log("location", response,status);
         if (status !== "OK") {
         // alert("Error was: " + status);
        } else {
      let distance = (response.rows[0].elements[0].distance.text);
          console.log("distance", distance);
          resolve(distance);
        //   const originList = response.originAddresses;
        //   const destinationList = response.destinationAddresses;
        //   const outputDiv = document.getElementById("output") as HTMLDivElement;
        //   outputDiv.innerHTML = "";
        //  // deleteMarkers(markersArray);
  
        //   const showGeocodedAddressOnMap =  (asDestination: boolean)=> {
        //     const icon = asDestination ? destinationIcon : originIcon;
  
        //     return ( (
        //       results: any,
        //       status: any
        //     )=> {
        //       console.log(status);
        //       // if (status === "OK") {
        //       //   map.fitBounds(bounds.extend(results[0].geometry.location));
        //       //   markersArray.push(
        //       //     new google.maps.Marker({
        //       //       map,
        //       //       position: results[0].geometry.location,
        //       //       icon: icon,
        //       //     })
        //       //   );
        //       // } else {
        //       //   alert("Geocode was not successful due to: " + status);
        //       // }
        //     });
        //   };
  
        //   for (let i = 0; i < originList.length; i++) {
        //     const results = response.rows[i].elements;
        //     geocoder.geocode(
        //       { address: originList[i] },
        //       showGeocodedAddressOnMap(false)
        //     );
  
        //     for (let j = 0; j < results.length; j++) {
        //       geocoder.geocode(
        //         { address: destinationList[j] },
        //         showGeocodedAddressOnMap(true)
        //       );
        //       outputDiv.innerHTML +=
        //         originList[i] +
        //         " to " +
        //         destinationList[j] +
        //         ": " +
        //         results[j].distance.text +
        //         " in " +
        //         results[j].duration.text +
        //         "<br>";
        //     }
        //   }
         }
      }
    );
    // var distance_in_meter = google.maps.geometry.spherical.computeDistanceBetween(position1,position2);
    // console.log("distance in meter",distance_in_meter);
    // let R = 6371.0710; // Radius of the Earth in km
    // let R = 3958.8; //radious in miles;
    //  let rlat1 = position1.lat * (Math.PI/180); // Convert degrees to radians
    //  let rlat2 = position2.lat * (Math.PI/180);
    //  let difflat = rlat2-rlat1; 
    //  let difflon = (position1.lng - position2.lng) * (Math.PI/180); // Radian difference (longitudes)
    //  let d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    //   console.log("distance in km :", d);
     //return distance_in_meter;
  });
   }

 /*** Location using google map api*/

 updateSearchResults(input:string){
  return new Promise((resolve,reject)=>{
    let selected_country:Array<any>=[];
    this.autocompleteItemsState = [];
  if (input == '') {
    this.autocompleteItemsState = [];
    resolve(this.autocompleteItemsState);
  }
  else{
    if(this.current_country){
      selected_country = ["IN","US","UY",this.current_country];
    }else{
      selected_country = ["IN","US","UY"];
    }
    console.log(this.current_country);
   this.GoogleAutocomplete.getPlacePredictions({  componentRestrictions: {country: selected_country},input: input },
 async  (predictions, status) => {
       this.autocompleteItemsState = [];
   await this.zone.run(() => {
      if(predictions){
        console.log(predictions);
      predictions.forEach((prediction) => {
          this.autocompleteItemsState.push(prediction);
          
      });
      resolve(this.autocompleteItemsState);
    }
    else{
      console.log("no prediction");
      reject();
    }
    });
  });
  //});
}
 });
} 

getSelectedCoutry(){
  return new Promise<any>((resolve,reject)=>{
  this.country_code.forEach(data=>{
    if(data.country==this.selected_country_name){
      this.selected_country_code = data.code;
      resolve(this.selected_country_code);
    }
  });
  console.log("selected county", this.selected_country_name,this.selected_country_code);
});
}

   getCurrentCountryCode(){
    return new Promise<any>((resolve , reject)=>{
    this.geolocation.getCurrentPosition().then(async (resp) => {
      console.log(resp);
        if(resp){
          let lat = resp.coords.latitude;
          let lng = resp.coords.longitude;
      var latlng = new google.maps.LatLng(lat, lng);
    await this.geocoder.geocode({'latLng': latlng},async (results, status)=> {
        if (status == google.maps.GeocoderStatus.OK) {
       // this.appServiceService.console_log(results);
       results.forEach(data=>{
        // console.log("country",data);
          if(data.types[0]=="country"){
            console.log(data);
         this.current_country = data.address_components[0].short_name;
        // this.appServiceService.console_log(data.address_components[0].short_name);
            // resolve(this.current_country);
          }
        })
        }
        else{
            console.log("usa");
          //reject("USA");
        }
      });
      resolve(this.current_country);
      }
    }).catch(e=>{
      console.log(e);
      reject(e);
    });
  });
  }
/**
 * 
 * @param place  get selected loaction description
 */
async selectStateResult(place ){
  return new Promise<any>(async (resolve,reject)=>{
  this.autocompleteItemsState = [];
 
  this.locationObj.description = place.description;
  console.log("result",place,this.locationObj);
  await this.geocoder.geocode({'placeId': place.place_id},async  (results, status) => {
    if(status === 'OK' && results[0]){
      let position :any = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
      };
      this.locationObj.position = position;
      // this.place_description = place.description ;
    // this.location_details = results[0];
    }
    /*** get country and state  */
    if (status == google.maps.GeocoderStatus.OK) {
      if (results[0]) {
        let count:any;
                     var country = null, state = null, countryCode = null, city = null, cityAlt = null;

          var c, lc,r,rl, component;
          let resultArr = results[0].address_components;
          
          for ( r = 0; r < resultArr.length;  r ++) {
              var result = resultArr[r];
              
              if (!city && result.types[0] === 'locality') {
                this.locationObj.city = result.long_name;
                
              }
              else if (result.types[0] === 'administrative_area_level_2') {
                this.locationObj.city = result.long_name;

              }
              else if (!city && !cityAlt && result.types[0] === 'administrative_area_level_1') {
                this.locationObj.state = result.long_name 

              } else if (!country && result.types[0] === 'country') {
                this.locationObj.country = result.long_name;
                this.selected_country_name = result.short_name;

              }
              else if( result.types[0]==='postal_code'){
                this.locationObj.zip_code = result.long_name;

              }
              if (city && country) {
                  break;
              }
          }
      }
      }
      console.log("result",this.locationObj);
      resolve(this.locationObj);
  });
  // resolve(this.locationObj);
});
}
  getSelectedCountry(){
    return new Promise<any>((resolve,reject)=>{
      this.selected_country_code=null;
    this.country_code.forEach(data=>{
      if(data.country==this.current_country){
        this.selected_country_code = data.code;
        resolve(this.selected_country_code);
      }
    });
    console.log("selected county", this.selected_country_name,this.selected_country_code);
  });
  
  }

  async getCountryCode(){
    this.country_code=[];
    return new Promise<any>((resolve,reject)=>{
      this.country_code.push({"country":"India","code":"+91"});
      this.country_code.push({"country":"United Kingdom","code":"+44"});
      this.country_code.push({"country":"USA","code":"+1"});
      // this.country_code.push({"country":"Afghanistan","code":"+93"});
      // this.country_code.push({"country":"Albania","code":"+355"});
      // this.country_code.push({"country":"Algeria","code":"+213"});
      // this.country_code.push({"country":"Andorra","code":"+376"});
      // this.country_code.push({"country":"Angola","code":"+244"});
      // this.country_code.push({"country":"Antigua And Barbuda","code":"+1-268"});
      // this.country_code.push({"country":"Argentina","code":"+54"});
      // this.country_code.push({"country":"Armenia","code":"+374"});
      // this.country_code.push({"country":"Australia","code":"+61"});
      // this.country_code.push({"country":"Austria","code":"+43"});
      // this.country_code.push({"country":"Azerbaijan","code":"+994"});
      // this.country_code.push({"country":"Bahamas","code":"+1-242"});
      // this.country_code.push({"country":"Bahrain","code":"+973"});
      // this.country_code.push({"country":"Bangladesh","code":"+880"});
      // this.country_code.push({"country":"Barbados","code":"+1-246"});
      // this.country_code.push({"country":"Belarus","code":"+375"});
      // this.country_code.push({"country":"Belgium","code":"+32"});
      // this.country_code.push({"country":"Belize","code":"+501"});
      // this.country_code.push({"country":"Benin","code":"+229"});
      // this.country_code.push({"country":"Bhutan","code":"+975"});
      // this.country_code.push({"country":"Bolivia","code":"+591"});
      // this.country_code.push({"country":"Bosnia & Herzegovina","code":"+387"});
      // this.country_code.push({"country":"Botswana","code":"+267"});
      // this.country_code.push({"country":"Brazil","code":"+55"});
      // this.country_code.push({"country":"Brunei Darussalam","code":"+673"});
      // this.country_code.push({"country":"Bulgaria","code":"+359"});
      // this.country_code.push({"country":"Burkina Faso","code":"+226"});
      // this.country_code.push({"country":"Burundi","code":"+257"});
      // this.country_code.push({"country":"Cambodia","code":"+855"});
      // this.country_code.push({"country":"Cameroon","code":"+237"});
      // this.country_code.push({"country":"Canada","code":"+1"});
      // this.country_code.push({"country":"Cape Verde","code":"+238"});
      // this.country_code.push({"country":"Central African Republic","code":"+236"});
      // this.country_code.push({"country":"Chad","code":"+235"});
      // this.country_code.push({"country":"Chile","code":"+56"});
      // this.country_code.push({"country":"China","code":"+86"});
      // this.country_code.push({"country":"Colombia","code":"+57"});
      // this.country_code.push({"country":"Comoros","code":"+269"});
      // this.country_code.push({"country":"Democratic Republic Of Congo","code":"+243"});
      // this.country_code.push({"country":"Republic of the Congo","code":"+242"});
      // this.country_code.push({"country":"Costa Rica","code":"+506"});
      // this.country_code.push({"country":"Cote d'Ivoire","code":"+225"});
      // this.country_code.push({"country":"Croatia","code":"+385"});
      // this.country_code.push({"country":"Cuba","code":"+53"});
      // this.country_code.push({"country":"Cyprus","code":"+357"});
      // this.country_code.push({"country":"Czech Republic","code":"+420"});
      // this.country_code.push({"country":"Denmark","code":"+45"});
      // this.country_code.push({"country":"Djibouti","code":"+253"});
      // this.country_code.push({"country":"Dominica","code":"+1-767"});
      // this.country_code.push({"country":"Dominican Republic","code":"+1-809 & +1-829"});
      // this.country_code.push({"country":"Ecuador","code":"+593"});
      // this.country_code.push({"country":"Egypt","code":"+20"});
      // this.country_code.push({"country":"El Salvador","code":"+503"});
      // this.country_code.push({"country":"Equatorial Guinea","code":"+240"});
      // this.country_code.push({"country":"Eritrea","code":"+291"});
      // this.country_code.push({"country":"Estonia","code":"+372"});
      // this.country_code.push({"country":"Ethiopia","code":"+251"});
      // this.country_code.push({"country":"Fiji","code":"+679"});
      // this.country_code.push({"country":"Finland","code":"+358"});
      // this.country_code.push({"country":"France","code":"+33"});
      // this.country_code.push({"country":"Gabon","code":"+241"});
      // this.country_code.push({"country":"Gambia","code":"+220"});
      // this.country_code.push({"country":"Georgia","code":"+995"});
      // this.country_code.push({"country":"Germany","code":"+49"});
      // this.country_code.push({"country":"Ghana","code":"+233"});
      // this.country_code.push({"country":"Greece","code":"+30"});
      // this.country_code.push({"country":"Grenada","code":"+1-473"});
      // this.country_code.push({"country":"Guatemala","code":"+502"});
      // this.country_code.push({"country":"Guinea","code":"+224"});
      // this.country_code.push({"country":"Guinea-Bissau","code":"+245"});
      // this.country_code.push({"country":"Guyana","code":"+592"});
      // this.country_code.push({"country":"Haiti","code":"+509"});
      // this.country_code.push({"country":"Honduras","code":"+504"});
      // this.country_code.push({"country":"Hungary","code":"+36"});
      // this.country_code.push({"country":"Iceland","code":"+354"});
    
      // this.country_code.push({"country":"Indonesia","code":"+62"});
      // this.country_code.push({"country":"Iran","code":"+98"});
      // this.country_code.push({"country":"Iraq","code":"+964"});
      // this.country_code.push({"country":"Ireland","code":"+353"});
      // this.country_code.push({"country":"Israel","code":"+972"});
      // this.country_code.push({"country":"Italy","code":"+39"});
      // this.country_code.push({"country":"Jamaica","code":"+1-876"});
      // this.country_code.push({"country":"Japan","code":"+81"});
      // this.country_code.push({"country":"Jordan","code":"+962"});
      // this.country_code.push({"country":"Kazakhstan","code":"+7"});
      // this.country_code.push({"country":"Kenya","code":"+254"});
      // this.country_code.push({"country":"Kiribati","code":"+686"});
      // this.country_code.push({"country":"North Korea","code":"+850"});
      // this.country_code.push({"country":"South Korea","code":"+82"});
      // this.country_code.push({"country":"Kuwait","code":"+965"});
      // this.country_code.push({"country":"Kyrgyzstan","code":"+996"});
      // this.country_code.push({"country":"Lao ","code":"+856"});
      // this.country_code.push({"country":"Latvia","code":"+371"});
      // this.country_code.push({"country":"Lebanon","code":"+961"});
      // this.country_code.push({"country":"Lesotho","code":"+266"});
      // this.country_code.push({"country":"Liberia","code":"+231"});
      // this.country_code.push({"country":"Libya","code":"+218"});
      // this.country_code.push({"country":"Liechtenstein","code":"+423"});
      // this.country_code.push({"country":"Lithuania","code":"+370"});
      // this.country_code.push({"country":"Luxembourg","code":"+352"});
      // this.country_code.push({"country":"Macedonia","code":"+389"});
      // this.country_code.push({"country":"Madagascar","code":"+261"});
      // this.country_code.push({"country":"Malawi","code":"+265"});
      // this.country_code.push({"country":"Malaysia","code":"+60"});
      // this.country_code.push({"country":"Maldives","code":"+960"});
      // this.country_code.push({"country":"Mali","code":"+223"});
      // this.country_code.push({"country":"Malta","code":"+356"});
      // this.country_code.push({"country":"Marshall Islands","code":"+692"});
      // this.country_code.push({"country":"Mauritania","code":"+222"});
      // this.country_code.push({"country":"Mauritius","code":"+230"});
      // this.country_code.push({"country":"Mexico","code":"+52"});
      // this.country_code.push({"country":"Micronesia","code":"+691"});
      // this.country_code.push({"country":"Moldova","code":"+373"});
      // this.country_code.push({"country":"Monaco","code":"+377"});
      // this.country_code.push({"country":"Mongolia","code":"+976"});
      // this.country_code.push({"country":"Montenegro","code":"+382"});
      // this.country_code.push({"country":"Morocco","code":"+212"});
      // this.country_code.push({"country":"Mozambique","code":"+258"});
      // this.country_code.push({"country":"Myanmar","code":"+95"});
      // this.country_code.push({"country":"Namibia","code":"+264"});
      // this.country_code.push({"country":"Nauru","code":"+674"});
      // this.country_code.push({"country":"Nepal","code":"+977"});
      // this.country_code.push({"country":"Netherlands","code":"+31"});
      // this.country_code.push({"country":"New Zealand","code":"+64"});
      // this.country_code.push({"country":"Nicaragua","code":"+505"});
      // this.country_code.push({"country":"Niger","code":"+227"});
      // this.country_code.push({"country":"Nigeria","code":"+234"});
      // this.country_code.push({"country":"Norway","code":"+47"});
      // this.country_code.push({"country":"Oman","code":"+968"});
      // this.country_code.push({"country":"Pakistan","code":"+92"});
      // this.country_code.push({"country":"Palau","code":"+680"});
      // this.country_code.push({"country":"Panama","code":"+507"});
      // this.country_code.push({"country":"Papua New Guinea","code":"+675"});
      // this.country_code.push({"country":"Paraguay","code":"+595"});
      // this.country_code.push({"country":"Peru","code":"+51"});
      // this.country_code.push({"country":"Philippines","code":"+63"});
      // this.country_code.push({"country":"Poland","code":"+48"});
      // this.country_code.push({"country":"Portugal","code":"+351"});
      // this.country_code.push({"country":"Qatar","code":"+974"});
      // this.country_code.push({"country":"Romania","code":"+40"});
      // this.country_code.push({"country":"Russian Federation","code":"+7"});
      // this.country_code.push({"country":"Rwanda","code":"+250"});
      // this.country_code.push({"country":"Saint Kitts And Nevis","code":"+1-869"});
      // this.country_code.push({"country":"Saint Lucia","code":"+1-758"});
      // this.country_code.push({"country":"Saint Vincent And The Grenadines","code":"+1-784"});
      // this.country_code.push({"country":"Samoa","code":"+685"});
      // this.country_code.push({"country":"San Marino","code":"+378"});
      // this.country_code.push({"country":"Sao Tome And Principe","code":"+239"});
      // this.country_code.push({"country":"Saudi Arabia","code":"+966"});
      // this.country_code.push({"country":"Senegal","code":"+221"});
      // this.country_code.push({"country":"Serbia","code":"+381"});
      // this.country_code.push({"country":"Seychelles","code":"+248"});
      // this.country_code.push({"country":"Sierra Leone","code":"+232"});
      // this.country_code.push({"country":"Singapore","code":"+65"});
      // this.country_code.push({"country":"Slovakia","code":"+421"});
      // this.country_code.push({"country":"Slovenia","code":"+386"});
      // this.country_code.push({"country":"Solomon Islands","code":"+677"});
      // this.country_code.push({"country":"Somalia","code":"+252"});
      // this.country_code.push({"country":"South Africa","code":"+27"});
      // this.country_code.push({"country":"Spain","code":"+34"});
      // this.country_code.push({"country":"Sri Lanka","code":"+94"});
      // this.country_code.push({"country":"Sudan","code":"+249"});
      // this.country_code.push({"country":"Suriname","code":"+597"});
      // this.country_code.push({"country":"Swaziland","code":"+268"});
      // this.country_code.push({"country":"Sweden","code":"+46"});
      // this.country_code.push({"country":"Switzerland","code":"+41"});
      // this.country_code.push({"country":"Syrian Arab Republic","code":"+963"});
      // this.country_code.push({"country":"Tajikistan","code":"+992"});
      // this.country_code.push({"country":"Tanzania","code":"+255"});
      // this.country_code.push({"country":"Thailand","code":"+66"});
      // this.country_code.push({"country":"East Timor","code":"+670"});
      // this.country_code.push({"country":"Togo","code":"+228"});
      // this.country_code.push({"country":"Tonga","code":"+676"});
      // this.country_code.push({"country":"Trinidad And Tobago","code":"+1-868"});
      // this.country_code.push({"country":"Tunisia","code":"+216"});
      // this.country_code.push({"country":"Turkey","code":"+90"});
      // this.country_code.push({"country":"Turkmenistan","code":"+993"});
      // this.country_code.push({"country":"Tuvalu","code":"+688"});
      // this.country_code.push({"country":"Uganda","code":"+256"});
      // this.country_code.push({"country":"Ukraine","code":"+380"});
      // this.country_code.push({"country":"UAE","code":"+971"});
      // this.country_code.push({"country":"United Kingdom","code":"+44"});
      // this.country_code.push({"country":"USA","code":"+1"});
      // this.country_code.push({"country":"Uruguay","code":"+598"});
      // this.country_code.push({"country":"Uzbekistan","code":"+998"});
      // this.country_code.push({"country":"Vanuatu","code":"+678"});
      // this.country_code.push({"country":"Vatican City State","code":"+379"});
      // this.country_code.push({"country":"Venezuela","code":"+58"});
      // this.country_code.push({"country":"Vietnam","code":"+84"});
      // this.country_code.push({"country":"Yemen","code":"+967"});
      // this.country_code.push({"country":"Zambia","code":"+260"});
      // this.country_code.push({"country":"Zimbabwe","code":"+263"});
      // this.country_code.push({"country":"Georgia","code":"+995"});
      // this.country_code.push({"country":"Taiwan","code":"+886"});
      // this.country_code.push({"country":"Azerbaijan","code":"+374-97"});
      // this.country_code.push({"country":"Cyprus","code":"+90-392"});
      // this.country_code.push({"country":"Moldova","code":"+373-533"});
      // this.country_code.push({"country":"Somalia","code":"+252"});
      // this.country_code.push({"country":"Christmas Island","code":"+61"});
      // this.country_code.push({"country":"Cocos (Keeling) Islands","code":"+61"});
      // this.country_code.push({"country":"Norfolk Island","code":"+672"});
      // this.country_code.push({"country":"New Caledonia","code":"+687"});
      // this.country_code.push({"country":"French Polynesia","code":"+689"});
      // this.country_code.push({"country":"Mayotte","code":"+262"});
      // this.country_code.push({"country":"Guadeloupe","code":"+590"});
      // this.country_code.push({"country":"Saint Pierre and Miquelon","code":"+508"});
      // this.country_code.push({"country":"Wallis and Futuna","code":"+681"});
      // this.country_code.push({"country":"Cook Islands","code":"+682"});
      // this.country_code.push({"country":"Niue","code":"+683"});
      // this.country_code.push({"country":"Tokelau","code":"+690"});
      // this.country_code.push({"country":"Guernsey","code":"+44"});
      // this.country_code.push({"country":"Isle of Man","code":"+44"});
      // this.country_code.push({"country":"Jersey","code":"+44"});
      // this.country_code.push({"country":"Anguilla","code":"+1-264"});
      // this.country_code.push({"country":"Bermuda","code":"+1-441"});
      // this.country_code.push({"country":"British Indian Ocean Territory","code":"+246"});
      // this.country_code.push({"country":"British Virgin Islands","code":"+1-284"});
      // this.country_code.push({"country":"Cayman Islands","code":"+1-345"});
      // this.country_code.push({"country":"Falkland Islands (Islas Malvinas)","code":"+500"});
      // this.country_code.push({"country":"Gibraltar","code":"+350"});
      // this.country_code.push({"country":"Montserrat","code":"+1-664"});
      // this.country_code.push({"country":"Saint Helena,Ascension and Tristan da Cunha","code":"+290"});
      // this.country_code.push({"country":"Turks and Caicos Islands","code":"+1-649"});
      // this.country_code.push({"country":"Northern Mariana Islands","code":"+1-670"});
      // this.country_code.push({"country":"Puerto Rico","code":"+1-787 & +1-939"});
      // this.country_code.push({"country":"American Samoa","code":"+1-684"});
      // this.country_code.push({"country":"Guam","code":"+1-671"});
      // this.country_code.push({"country":"U.S. Virgin Islands","code":"+1-340"});
      // this.country_code.push({"country":"Hong Kong","code":"+852"});
      // this.country_code.push({"country":"Macao","code":"+853"});
      // this.country_code.push({"country":"Faroe Islands","code":"+298"});
      // this.country_code.push({"country":"Greenland","code":"+299"});
      // this.country_code.push({"country":"French Guiana","code":"+594"});
      // this.country_code.push({"country":"Martinique","code":"+596"});
      // this.country_code.push({"country":"Réunion","code":"+262"});
      // this.country_code.push({"country":"Åland Islands","code":"+358-18"});
      // this.country_code.push({"country":"Aruba","code":"+297"});
      // this.country_code.push({"country":"Netherlands Antilles","code":"+599"});
      // this.country_code.push({"country":"Eswatini","code":"+47"});
      // this.country_code.push({"country":"Ascension Island","code":"+247"});
      // this.country_code.push({"country":"Czechoslovakia","code":"+381"});
      // this.country_code.push({"country":"Palestine","code":"+970"});
      // this.country_code.push({"country":"Western Sahara","code":"+212"});
  
      let sorted_array = this.country_code.sort(this.sortByProperty("country")); //sort according to country
      console.log("Sorted Array:",sorted_array);
       resolve(sorted_array);
    });
    
     }

     sortByProperty(property){
      return function(a,b){
         if(a[property] > b[property])
            return 1;
         else if(a[property] < b[property])
            return -1;
  
         return 0;
      }
   }
}
