import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import moment from 'moment';
import PouchDB from 'pouchdb';
import PouchdbFind from 'pouchdb-find';
import { PageStore } from '../interface/page-store';
import { AppserviceService } from './appservice.service';
import { EventsService } from './events.service';
import { TableStatus } from '../interface/table-status';
import { reject } from 'q';
import { from, Observable, of } from 'rxjs';
import { PushNotificationsService } from './push-notifications.service';
import { ThirdPartyService } from './third-party.service';
import { TableInfo } from '../interface/table-info';
//import { resolve } from 'dns';

@Injectable({
  providedIn: 'root'
})
export class PouchdbauthService {
  data: Array<any> = [];
  db_tables: any;
  remote_tables: any;



  offer_data:any;
  db_offer:any;
  remote_offer:any;
  
  cashier_data: any;
  db_cashier: any;
  remote_cashier: any;

  staff_data:any;
  db_staff:any;
  remote_staff:any;

  customer_data: any;
  db_customer: any;
  remote_customer: any;

  customer_info: any;
  db_customer_info: any;
  remote_customer_info: any;

  add_delivary_info: any;
  db_add_delivary_info: any;
  remote_add_delivary_info: any;


  customer_data_corporate:any;
  db_customer_corporate:any;
  remote_customer_corporate: any;


  bill_data_kot: any;
  bill_data_bot: any;
  bill_data_hookah: any;

  waiter_data: any;
  db_waiter: any;
  remote_waiter: any;

  resto_data: any;
  db_resto: any;
  public db_restoInfo:any;
  remote_resto: any;
  public restaurenInfo:any;

  item_data: any;
  public item: Array<any> = [];
  db_item: any;
  remote_item: any;
  /** current order node for kot ,bot,hot */
public currentOrderObj:Observable<any>;
  current_order: any;
  db_currentOrder: any;
  remote_currentOrder: any;

  currentBot_order: any;
  db_currentBotOrder: any;
  remote_currentBotOrder: any;

  currentHt_order: any;
  db_currentHtOrder: any;
  remote_currentHtOrder: any;
  /** end */
  counter_data: any;
  counter_all_data: any;
  db_counter: any;
  remote_counter: any;

  Orderfinish_data: any;
  db_Orderfinish: any;
  remote_Orderfinish: any;

  public getFinalBills: Array<any> = [];

  final_data: any;
  db_final: any;
  remote_final: any;

  // finalBot_data: any;
  // db_finalBot: any;
  // remote_finalBot: any;

  // finalHt_data: any;
  // db_finalHt: any;
  // remote_finalHt: any;

  void_data: any;
  db_void: any;
  remote_void: any;



  stock_data: any;
  db_stock: any;
  remote_stock: any;

  p_counter: number = 0;
  bill_counter: number = 0;
  private appVersion: string = null;
  private timestamp: string;
  username: any;
  password; any;

  consumtion_data: any;
  db_consumtion: any;
  remote_consumtion: any;

  finalconsumption_data:any;
  db_finalconsumption:any;
  remote_finalconsumption:any;

  invoice_data:any;
  db_invoice:any;
  remote_invoice:any;

  wastage_data: any;
  db_wastage: any;
  remote_wastage: any;

  recipes_data: any;
  db_recipes: any;
  remote_recipes: any;

  allStocks_data: any;
  db_allStocks: any;
  remote_allStocks: any;

  kitchenstocks_data: any;
  db_kitchen: any;
  remote_kitchen: any;

  finalkitchenstocks_data: any;
  db_finalkitchen: any;
  remote_finalkitchen: any;

  liquorstock_data: any;
  db_liquor: any;
  remote_liquor: any;

  // finalliquor_data: any;
  // db_finalliquor: any;
  // remote_finalliquor: any;

  db_liquor_add_item: any;
  remote_liquor_add_item: any;
  liquor_data: any;
  public liquor_item: Array<any> = [];

  // db_hookah_add_item: any;
  // remote_hookah_add_item: any;
  // hookah_data: any;
  // public hookah_item: Array<any> = [];

  error_data: any;
  db_error: any;
  remote_error: any;

  public cloudant_auth:any=null;
  public url:any =null;
  public database_json:any=null;
  public uid:string=null;
  public get_uid:string=null;
  public cuisineList: Array<any> = [];
public remote_resoInfo:any;
  constructor(public http: HttpClient,
    private toastCtrl: ToastController,
    private _notificationService: PushNotificationsService,
    private third_party:ThirdPartyService,
    public events: EventsService,
    private appServiceProvider : AppserviceService,
    public storage:Storage,
    public alertCtrl: AlertController
    // private changeDetectorRef: ChangeDetectorRef
  ) {
    PouchDB.plugin(PouchdbFind);
    console.log('Hello PouchDbAuthProvider Provider');
    
    this.getReference();

  }
  async getReferenceUid(){
    return new Promise(async (resolve)=>{

    
    /** databasename and cloudant auth id's get from appservice  */
    this.database_json = this.appServiceProvider.getDatabaseConfig();
    this.cloudant_auth = this.appServiceProvider.getCloudantAuth();
    /** cloudant authantication id's */
    this.url = this.cloudant_auth.url;
    this.username=this.cloudant_auth.username;
    this.password=this.cloudant_auth.password;
    /** getUid function return admin's id from localstorage  */
   await  this.getUid().then(data=>{
      /** converting admin uid to lowercase bcs cloudant does not support Capitallatter . but firebase id's return mixing both */
     console.log("user data",data);
   if(data && data !=null){
    this.uid =data.toLowerCase();
    console.log("UID:",this.uid,data.toLowerCase());
    resolve(this.uid);
    this.getReference();
   }
  
  }).catch(e=>{
  console.log(e);
  });
})
  
  }


  async getReference(){
    return new Promise(async (resolve)=>{
    /** databasename and cloudant auth id's get from appservice  */
    this.database_json = await this.appServiceProvider.getDatabaseConfig();
    this.cloudant_auth = await this.appServiceProvider.getCloudantAuth();
    /** cloudant authantication id's */
    this.url = this.cloudant_auth.url;
    this.username=this.cloudant_auth.username;
    this.password=this.cloudant_auth.password;
   
    await this.initialisePouchReferences();
    resolve("Executed");

  });
}


  initialisePouchReferences(){
    // return new Promise(async (resolve)=>{
    console.log("UID in initialisePouchReferences:");
  /** pouchDb database path */
    this.db_tables = new PouchDB(this.database_json.tables);
    console.log('uid is:',this.uid);
    console.log('table is:',this.db_tables);
    this.db_cashier = new PouchDB(this.database_json.cashier);
    this.db_staff = new PouchDB(this.database_json.staff);
    this.db_customer = new PouchDB(this.database_json.customer);
    this.db_customer_corporate = new PouchDB(this.database_json.customerCorporate);
    this.db_waiter = new PouchDB(this.database_json.waiter);
    this.db_resto = new PouchDB(this.database_json.restaurant);
    this.db_item = new PouchDB(this.database_json.menulist);
    this.db_currentOrder = new PouchDB(this.database_json.currentorder);
    this.db_currentBotOrder = new PouchDB(this.database_json.currentbotorder);
    this.db_currentHtOrder = new PouchDB(this.database_json.currenthtorder);
    this.db_counter = new PouchDB(this.database_json.counter);
    this.db_Orderfinish = new PouchDB(this.database_json.orderfinished);
    this.db_final = new PouchDB(this.database_json.final);
    // this.db_finalBot = new PouchDB(thisbase_json.finalbot);
    // this.db_finalHt = new PouchDB(this.database_json.finalht);
    this.db_void = new PouchDB(this.database_json.voidBills);
    this.db_error = new PouchDB(this.database_json.errors);
    this.db_stock = new PouchDB(this.database_json.stocks);
    this.db_consumtion = new PouchDB(this.database_json.consumtion);
    this.db_wastage = new PouchDB(this.database_json.wastages);
    this.db_recipes = new PouchDB(this.database_json.recipes);
    this.db_allStocks = new PouchDB(this.database_json.finalstock);
    this.db_liquor_add_item = new PouchDB(this.database_json.liquor_item);
    this.db_kitchen= new PouchDB(this.database_json.kitchen_stocks)
    this.db_finalkitchen = new PouchDB(this.database_json.finalkitchen_stocks);
   // this.db_hookah_add_item = new PouchDB (this.database_json.hookah_item);
    this.db_liquor = new PouchDB(this.database_json.liquor_stock);
    //this.db_finalliquor = new PouchDB(this.database_json.finalliquor_stock);
    this.db_invoice = new PouchDB(this.database_json.invoice);
    this.db_offer = new PouchDB(this.database_json.offer);
    this.db_finalconsumption = new PouchDB(this.database_json.final_consumption);
    


  /** couchDb url  */
 
    this.remote_stock = this.url+ this.database_json.stocks;
    this.remote_consumtion = this.url+ this.database_json.consumtion;
    this.remote_tables = this.url+ this.database_json.tables;
    this.remote_cashier = this.url+ this.database_json.cashier;
    this.remote_staff = this.url + this.database_json.staff;
    this.remote_waiter = this.url+ this.database_json.waiter;
    this.remote_resto = this.url+ this.database_json.restaurant;
    this.restaurenInfo = this.url+this.database_json.restaurenInfo;
    this.remote_item = this.url+ this.database_json.menulist;
    this.remote_currentOrder = this.url+ this.database_json.currentorder;
    this.remote_currentBotOrder = this.url+ this.database_json.currentbotorder;
    this.remote_currentHtOrder = this.url+ this.database_json.currenthtorder;
    this.remote_counter = this.url+ this.database_json.counter;
    this.remote_Orderfinish = this.url+ this.database_json.orderfinished;
    this.remote_final = this.url+ this.database_json.final;
   // this.remote_finalBot = this.url+ this.database_json.finalbot;
    //this.remote_finalHt = this.url+ this.database_json.finalht;
    this.remote_void = this.url+ this.database_json.voidBills;
    this.remote_customer = this.url+ this.database_json.customer;
    this.remote_error = this.url+ this.database_json.errors;
    this.remote_wastage =this.url+ this.database_json.wastages;
    this.remote_allStocks = this.url+ this.database_json.finalstock;
    this.remote_recipes = this.url+ this.database_json.recipes;
    this.remote_liquor_add_item =this.url+ this.database_json.liquor_item;
    this.remote_kitchen = this.url+ this.database_json.kitchen_stocks;
    this.remote_finalkitchen = this.url+ this.database_json.finalkitchen_stocks;
   // this.remote_hookah_add_item = this.url+ this.database_json.hookah_item;
    this.remote_liquor = this.url+ this.database_json.liquor_stock;
   // this.remote_finalliquor = this.url+ this.database_json.finalliquor_stock;
    this.remote_customer_corporate = this.url+ this.database_json.customerCorporate;
    this.remote_invoice = this.url+ this.database_json.invoice;
    this.remote_offer = this.url + this.database_json.offer;
    this.remote_finalconsumption = this.url + this.database_json.final_consumption;
    console.log(this.remote_tables);
  
    let options = {
      live: true,
      retry: true,
      continuous: true,
      checkpoint:'source',
      auth: {
        username: this.username,
        password: this.password
      }
    };


  
  /** sync pouch Db to couch db */
    this.db_tables.sync(this.remote_tables, options).on('change', function (change) {
      // yo, something changed!
      console.log("change happen");
      // this.handleChange(change, this.data);
      // this.events.publish('tables', this.data);
    }).on('error', function (err) {
      console.log(err);
      // yo, we got an error! (maybe the user went offline?)
    });


    this.db_cashier.sync(this.remote_cashier, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });

    this.db_resto.sync(this.remote_resto, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_currentOrder.sync(this.remote_currentOrder, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_currentBotOrder.sync(this.remote_currentBotOrder, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_currentHtOrder.sync(this.remote_currentHtOrder, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_counter.sync(this.remote_counter, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_Orderfinish.sync(this.remote_Orderfinish, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_final.sync(this.remote_final, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_error.sync(this.remote_error, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_recipes.sync(this.remote_recipes, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_stock.sync(this.remote_stock, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_consumtion.sync(this.remote_consumtion, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_wastage.sync(this.remote_wastage, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_allStocks.sync(this.remote_allStocks, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_kitchen.sync(this.remote_kitchen, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_finalkitchen.sync(this.remote_finalkitchen, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_customer.sync(this.remote_customer, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_customer_corporate.sync(this.remote_customer_corporate, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_invoice.sync(this.remote_invoice, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_finalconsumption.sync(this.remote_finalconsumption, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_offer.sync(this.remote_offer, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });
    this.db_staff.sync(this.remote_staff, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });

    // this.db_cashier.sync(this.remote_cashier, options);
    // this.db_waiter.sync(this.remote_waiter,options);
    // this.db_resto.sync(this.remote_resto, options);
    // this.db_item.sync(this.remote_item,options);
   
    this.db_item.sync(this.remote_item, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });

    this.db_waiter.sync(this.remote_waiter, options).on('change', (info)=> {
      // handle change
    }).on('paused', (err) =>{
      // replication paused (e.g. replication up to date, user went offline)
    }).on('active', ()=> {
      // replicate resumed (e.g. new changes replicating, user went back online)
    }).on('denied', (err) =>{
      // a document failed to replicate (e.g. due to permissions)
    }).on('complete',  (info) =>{
      console.log('sync complete');
      // handle complete
    }).on('error',  (err)=> {
      // handle error
    });


    // this.db_restoInfo.sync(this.remote_resoInfo, options).on('change', (info)=> {
    //   // handle change
    // }).on('paused', (err) =>{
    //   // replication paused (e.g. replication up to date, user went offline)
    // }).on('active', ()=> {
    //   // replicate resumed (e.g. new changes replicating, user went back online)
    // }).on('denied', (err) =>{
    //   // a document failed to replicate (e.g. due to permissions)
    // }).on('complete',  (info) =>{
    //   console.log('sync complete');
    //   // handle complete
    // }).on('error',  (err)=> {
    //   // handle error
    // });




    
    // this.db_currentOrder.sync(this.remote_currentOrder, options).then(()=>{

    // }).catch((e)=>{
    //   console.log('error is:',e);
    // })

    // this.db_currentBotOrder.sync(this.remote_currentBotOrder, options);
    // this.db_currentHtOrder.sync(this.remote_currentHtOrder, options);
    // this.db_counter.sync(this.remote_counter, options);
    //  this.db_Orderfinish.sync(this.remote_Orderfinish , options);
    // this.db_final.sync(this.remote_final,options);
    // this.db_error.sync(this.remote_error,options);
    // this.db_recipes.sync(this.remote_recipes,options);
    // this.db_stock.sync(this.remote_stock, options).then(()=>{
    //   console.log('synced');
    //       }).catch((e)=>{
    //   console.log('not synced:',e);
    //       })
  
    // this.db_consumtion.sync(this.remote_consumtion,options);
    // this.db_wastage.sync(this.remote_wastage,options);
    // this.db_allStocks.sync(this.remote_allStocks,options);
    // this.db_liquor_add_item.sync(this.remote_liquor_add_item,options);
    // this.db_kitchen.sync(this.remote_kitchen,options);
    // this.db_finalkitchen.sync(this.remote_finalkitchen,options);
    // this.db_customer.sync(this.remote_customer,options);
    // this.db_customer_corporate.sync(this.remote_customer_corporate,options)
    // this.db_liquor.sync(this.remote_liquor,options);
    // this.db_invoice.sync(this.remote_invoice,options);
    // this.db_finalconsumption.sync(this.remote_finalconsumption,options);
    // this.db_offer.sync(this.remote_offer,options);



    
  }


  /**
 * getUid function ids for get uid of admin ;
 * after admin login or staff registration uid of admin is storing in localstorage this function return that uid,
 * @return { string } -  uid of admin as a string.
 * @param { void } - a void param.
 * this is for seperate database based on admin uid .
 */
async getUid(){
  return new Promise<any>(async (resolve,reject)=>{
    this.storage.get('user').then(async(user)=>{
    console.log("user type",user);
   if(user=='external'){
    await   this.storage.get('userFireKey').then((data)=>{
      console.log("get user's admin  uid",data);
      this.get_uid = data;
      resolve(this.get_uid);
    }).catch((e)=>{
      console.log(e);
      reject(e);
    });

  }
  else {
        await this.storage.get('adminFireKey').then((data)=>{
          console.log("get admin  uid",data);
          this.get_uid = data;
          resolve(this.get_uid);
        }).catch((e)=>{
          console.log(e);
          reject(e);
        });
      
  }

}).catch(e=>{
  console.log(e);
  reject(e);
});
});

  }


  /** fetch bill data */
  getBillsKot() {



    // return new Promise(resolve =>{
    //   // resolve(

    //     this.httpClient.get('http://192.168.1.68:3000/getBillsKot').subscribe(
    //   res => {
    //     console.log("user data exist", res);
    //     resolve(res)

    //   },
    //   error => {

    //     console.log("error", error);
    //   }
    // )
    //   // )
    // });

    // if (this.bill_data_kot) {
    //   return Promise.resolve(this.bill_data_kot);
    // }
    // return new Promise(resolve => {
      return new Promise<any>((resolve, reject) => {
        console.log("db_final:",this.database_json.final+this.uid);
      this.db_final.allDocs({

        include_docs: true

      }).then((result) => {
        this.bill_data_kot = [];
        console.log("Result:", result, typeof (result));
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.bill_data_kot.push(row.doc);
        });
        resolve(this.bill_data_kot);
        this.db_customer.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.bill_data_kot);
          this.events.publish('cashier_data', this.bill_data_kot);

        });

      }).catch((e) => {
        reject(e);
        /** store error on errors database */
        let line = 957;
        let error = "error on getcustomer function";
        //this.registerError(e, line, error, "getBillsKot");
        console.log(e);
      });

    });

  }

  getBillsBot() {
/** not using now  */
    //   return new Promise<any>((resolve, reject) => {
    //   this.db_finalBot.allDocs({

    //     include_docs: true

    //   }).then((result) => {
    //     this.bill_data_bot = [];
    //     console.log("Result:", result, typeof (result));
    //     let docs = result.rows.map((row) => {
    //       console.log(row.doc);
    //       this.bill_data_bot.push(row.doc);
    //     });
    //     resolve(this.bill_data_bot);
    //     this.db_customer.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.bill_data_bot);
    //       this.events.publish('cashier_data', this.bill_data_bot);

    //     });

    //   }).catch((e) => {
    //     reject(e);
    //     /** store error on errors database */
    //     let line = 957;
    //     let error = "error on getcustomer function";
    //     //this.registerError(e, line, error, "getBillsBot");
    //     console.log(e);
    //   });

    // });

  }

  getOffer() {

    // if (this.bill_data_bot) {
    //   return Promise.resolve(this.bill_data_bot);
    // }
    // return new Promise(resolve => {
      return new Promise<any>((resolve, reject) => {
      this.db_offer.allDocs({

        include_docs: true

      }).then((result) => {
        this.offer_data = [];
        console.log("Result:", result, typeof (result));
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.offer_data.push(row.doc);
        });
        resolve(this.offer_data);
        this.db_offer.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.offer_data);
          this.events.publish('offer_data', this.offer_data);

        });

      }).catch((e) => {
        reject(e);
        /** store error on errors database */
        let line = 957;
        let error = "error on getcustomer function";
        //this.registerError(e, line, error, "getBillsBot");
        console.log(e);
      });

    });

  }
  getBillsHookah() {

/** not using this  */
    //   return new Promise<any>((resolve, reject) => {
    //   this.db_finalHt.allDocs({

    //     include_docs: true

    //   }).then((result) => {
    //     this.bill_data_hookah = [];
    //     console.log("Result:", result, typeof (result));
    //     let docs = result.rows.map((row) => {
    //       console.log(row.doc);
    //       this.bill_data_hookah.push(row.doc);
    //     });
    //     resolve(this.bill_data_hookah);
    //     this.db_customer.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.bill_data_hookah);
    //       this.events.publish('cashier_data', this.bill_data_hookah);

    //     });

    //   }).catch((e) => {
    //     reject(e);
    //     /** store error on errors database */
    //     let line = 957;
    //     let error = "error on getcustomer function";
    //     //this.registerError(e, line, error, "getBillsHookah");
    //     console.log(e);
    //   });

    // });

  }



  addImageItem(image: any, file: File) {
    console.log("Inside AddImageItem")
    return new Promise<any>((resolve,reject)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend= ()=>{
          console.log("Reader result:",reader.result);
         // this.myId = new Date().toISOString();
        
          this.db_invoice.put({
            _id: image.path,
            rawInvoicedata:reader.result
          }).then(async()=>{
            console.log('myid is:',image);
            await this.db_invoice.get(image.path,(err,doc)=>{
              if(err){
             reject();
              }else{
                // this.isImageupload=true;
                console.log("image",doc.rawInvoicedata);
                image.url=doc.rawInvoicedata;
              resolve(image);
              }
            })  
          }).catch((e)=>{
            console.log('error is:',e);
          })
        }
      });
    
       } 

   addImage(image:any,file:File){
return new Promise<any>((resolve,reject)=>{
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend= ()=>{
      console.log("Reader result:",reader.result);
     // this.myId = new Date().toISOString();
    
      this.db_invoice.put({
        _id: image.path,
        rawInvoicedata:reader.result
      }).then(async()=>{
        console.log('myid is:',image);
        await this.db_invoice.get(image.path,(err,doc)=>{
          if(err){
         reject();
          }else{
            // this.isImageupload=true;
            console.log("image",doc.rawInvoicedata);
            image.url=doc.rawInvoicedata;
          resolve(image);
          }
        })  
      }).catch((e)=>{
        console.log('error is:',e);
      })
    }
  });

   } 


  getInvoices() {

    // if (this.bill_data_hookah) {
    //   return Promise.resolve(this.bill_data_hookah);
    // }
    // return new Promise(resolve => {
      return new Promise<any>((resolve, reject) => {
      this.db_invoice.allDocs({

        include_docs: true

      }).then((result) => {
        this.invoice_data = [];
        console.log("Result:", result, typeof (result));
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.invoice_data.push(row.doc);
        });
        resolve(this.invoice_data);
        this.db_invoice.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.invoice_data);
          this.events.publish('cashier_data', this.invoice_data);

        });

      }).catch((e) => {
        reject(e);
        /** store error on errors database */
        let line = 957;
        let error = "error on getcustomer function";
        //this.registerError(e, line, error, "getBillsHookah");
        console.log(e);
      });

    });

  }




  putUpdatedBills(data) {

    this.db_final.put(data).then((data) => {
      console.log("Updated Successfully");
      let options = {
        live: true,
        retry: true,
        continuous: true,
        checkpoint: 'source',
        auth: {
          username: this.username,
          password: this.password
        }
      };
      this.db_final.sync(this.remote_final, options);
    }).catch((e) => {
      console.error(e);
      /** store error on errors database */
      let line = 957;
      let error = "error on getcustomer function";
      //this.registerError(e, line, error, "putUpdatedBills");
    });

    // this.db_final.get(data.Transaction_Id).catch(function (err) {
    //   if (err.name === 'not_found') {
    //     return data;
    //     //console.log("Updated Successfully")
    //   } else { // hm, some other error
    //     throw err;
    //   }
    // }).then(function (configDoc) {
    //   // sweet, here is our configDoc
    //   console.log("Updated Successfully");
    //   let options = {
    //     live: true,
    //     retry: true,
    //     continuous: true,
    //     checkpoint:'source',
    //     auth: {
    //       username: this.username,
    //       password: this.password
    //     }
    //   };
    //   this.db_final.sync(this.remote_final,options);
    // }).catch(function (err) {
    //   // handle any errors
    //   console.log(err)
    // });

  }









  putUpdatedBillsToComplimentary(data) {
    this.db_final.put(data).then((data) => {
      console.log("Updated Successfully");
      let options = {
        live: true,
        retry: true,
        continuous: true,
        checkpoint: 'source',
        auth: {
          username: this.username,
          password: this.password
        }
      };
      this.db_final.sync(this.remote_final, options);
    }).catch((err) => {
      console.error(err);
      /** store error on errors database */
      let line = 957;
      let error = "error on getcustomer function";
      //this.registerError(err, line, error, "putUpdatedBillsToComplimentary");
    });

    // this.db_final.get(data.Transaction_Id).catch(function (err) {
    //   if (err.name === 'not_found') {
    //     return data;
    //     //console.log("Updated Successfully")
    //   } else { // hm, some other error
    //     throw err;
    //   }
    // }).then(function (configDoc) {
    //   // sweet, here is our configDoc
    //   console.log("Updated Successfully");
    //   let options = {
    //     live: true,
    //     retry: true,
    //     continuous: true,
    //     checkpoint:'source',
    //     auth: {
    //       username: this.username,
    //       password: this.password
    //     }
    //   };
    //   this.db_final.sync(this.remote_final,options);
    // }).catch(function (err) {
    //   // handle any errors
    //   console.log(err)
    // });

  }
/** final information */
getFinalList() {

   if (this.final_data) {
     return Promise.resolve(this.final_data);
   }

   return new Promise(resolve => {

     this.db_final.allDocs({

       include_docs: true

     }).then((result) => {

       this.final_data = [];

       let docs = result.rows.map((row) => {
         console.log(row.doc);
         this.final_data.push(row.doc);
       });

       resolve(this.final_data);

       this.db_final.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
         this.handleChange(change, this.final_data);
         this.events.publish('final_data', this.final_data);

       });

     }).catch((e) => {
        /** store error on errors database */
        let line=142;
        let error = "error on  getFinalList function";
       this.registerError(e,line,error);
       console.log(e);

     });

   });

 }
/** get data for report  */
 async getDataWithin(start_time:any,end_time:any){
let dataList:Array<any> = [];
//return this.db_final.query('Date', {startkey: start_time, endkey: end_time});
 await this.db_final.query({
  map: function(doc, emit) {
    if (doc.timestamp >= start_time && doc.timestamp <= end_time) {
      console.log(doc) ;
      dataList.push(doc);
      //emit(doc,1);
    }
  },
}).catch((e)=>{
 /** store error on errors database */
 let line=167;
 let error = "error on  getDatawithin function for report";
this.registerError(e,line,error);
console.log(e);
});
return dataList;
 }


 /** get data for report  */
 async getDataliquorWithin(start_time:any,end_time:any){
     console.log("not using this ");
//   let dataList:Array<any> = [];
//   //return this.db_final.query('Date', {startkey: start_time, endkey: end_time});
//    await this.db_finalBot.query({
//     map: function(doc, emit) {
//       if (doc.Date >= start_time && doc.Date <= end_time) {
//         console.log(doc) ;
//         dataList.push(doc);
//         //emit(doc,1);
//       }
//     },
//   }).catch((e)=>{
//    /** store error on errors database */
//    let line=167;
//    let error = "error on  getDatawithin function for report";
//   this.registerError(e,line,error);
//   console.log(e);
//   });
//   return dataList;
// }

//  /** get data for report  */
//  async getDatahookahWithin(start_time:any,end_time:any){
//   let dataList:Array<any> = [];
//   //return this.db_final.query('Date', {startkey: start_time, endkey: end_time});
//    await this.db_finalHt.query({
//     map: function(doc, emit) {
//       if (doc.Date >= start_time && doc.Date <= end_time) {
//         console.log(doc) ;
//         dataList.push(doc);
//         //emit(doc,1);
//       }
//     },
//   }).catch((e)=>{
//    /** store error on errors database */
//    let line=167;
//    let error = "error on  getDatawithin function for report";
//   this.registerError(e,line,error);
//   console.log(e);
//   });
//   return dataList;
}

 async getDatadateWise(start_time:any,end_time:any){
  let dataList:Array<any>=[];
  await this.db_allStocks.query({
    map: function(doc,emit){
      if(doc.date >= start_time && doc.date <= end_time){
        console.log(doc);
        dataList.push(doc);
      }
    },
  }).catch((e)=>{
    let line=167;
    let error = "error on  getDatawithin function for report";
   this.registerError(e,line,error);
   console.log(e);
  });
  return dataList;
 }

 async getConsumtiondata(start_time:any,end_time:any){
  let dataList:Array<any>=[];
  await this.db_consumtion.query({
    map: function(doc,emit){
      if(doc.date >= start_time && doc.date <= end_time){
        console.log(doc);
        dataList.push(doc);
      }
    },
  }).catch((e)=>{
    let line=167;
    let error = "error on  getDatawithin function for report";
   this.registerError(e,line,error);
   console.log(e);
  });
  return dataList;
 }

 async getWastagedata(start_time:any,end_time:any){
  let dataList:Array<any>=[];
  await this.db_wastage.query({
    map: function(doc,emit){
      if(doc.date >= start_time && doc.date <= end_time){
        console.log(doc);
        dataList.push(doc);
      }
    },
  }).catch((e)=>{
    let line=167;
    let error = "error on  getDatawithin function for report";
   this.registerError(e,line,error);
   console.log(e);
  });
  return dataList;
 }
 

/** destroy / clear data fron final database  */
clearHistory(){
this.db_final.destroy().then(()=>{
  console.log("destroyed successssss");
}).catch((e)=>{
  /** store error on errors database */
 let line=181;
 let error = "error on  clearHistory function";
this.registerError(e,line,error);
console.log(e);
});

}

 /** put final tips added order  */
 postFinal(todo) {
console.log("finish ",todo);
// let orderData={
//   "_id":todo.Transaction_Id,
//   "Order_Type":todo.Order_Type,
//   "Date":todo.Date,
//   "Time":todo.Time,
//   "Transaction_Id": todo.Transaction_Id,
//   "Order_History":todo.Order_History,
//   "Total_Amount":todo.Total_Amount,
//   "Discount": todo.Discount,
//   "Discount_Input":todo.Discount_Input,
//   "Discount_Amount":todo.Discount_Amount,
//   "Parcel":todo.Parcel,
//   "Parcel_Amount":todo.Parcel_Amount,
//   "Gst_Amount":todo.Gst_Amount,
//   "Delivery_Charge":todo.Delivery_Charge,
//   "Miscellaneous":todo.Miscellaneous,
//   "Bill_no":todo.Bill_no,
//   "Tips":todo.Tips,
//   "Grand_Total":todo.Grand_Total,
//   "Total_In_Words":todo.Total_In_Words,
//   "Pay_Bill":todo.Pay_Bill,
//   "PaymentType":todo.PaymentType
// };
  return this.db_final.query(function (doc, emit) {
    emit(doc._id);
  }, { key: todo._id }).then(result => {
    console.log("result of final collection is :",result);
    if (result.rows.length == 0) {
      this.db_final.put(todo).then(()=>{
        console.log("order placed on final node ");
      }).catch(e=>{
/** store error on errors database */
let line=220;
let error = "error on  put final order";
this.registerError(e,line,error);
console.log(e);
      });


    }
    else {
     // alert("already present");
    }
    console.log("fount", result);
  }).catch((e) => {
/** store error on errors database */
let line=235;
let error = "error on  query final order function";
this.registerError(e,line,error);
console.log(e);
  });
}

/** push order info in finish node */
  OrderFinish(todo) {
   return this.db_Orderfinish.query(function (doc, emit) {
      emit(doc._id);
    }, { key: todo._id }).then(result => {
      if (result.rows.length == 0) {
        this.db_Orderfinish.put(todo);

      }
      else {
       // alert("already present");
      }
      console.log("fount", result);
    }).catch((e) => {
/** store error on errors database */
let line=257;
let error = "error on  fetch order finished";
this.registerError(e,line,error);
console.log(e);
    });


  }
/** remove ordre finish */
removeOrderFinish(todo) {
  console.log("removeOrderfinish");
/** todo is id od docs  */
  this.db_Orderfinish.get(todo).then((doc)=>{
    //this.events.publish('finish',this.Orderfinish_data);
    return this.db_Orderfinish.remove(doc);

  }).catch((e)=>{
    /** store error on errors database */
let line=276;
let error = "error on  removeOrderFinish function";
this.registerError(e,line,error);
console.log(e);

  });

}

/** get OrderList after  billing  */
  getOrderFinish() {

    if (this.Orderfinish_data) {
      return Promise.resolve(this.Orderfinish_data);
    }

    return new Promise(resolve => {

      this.db_Orderfinish.allDocs({

        include_docs: true

      }).then((result) => {

        this.Orderfinish_data = [];

        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.Orderfinish_data.push(row.doc);
        });

        resolve(this.Orderfinish_data);

        this.db_Orderfinish.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChangeOrderFinish(change);
          this.events.publish('order_finish',this.Orderfinish_data);
          // this.events.publish('current_order', this.current_order);
        });

      }).catch((e) => {
         /** store error on errors database */
let line=316;
let error = "error on  getOrderFinish function";
this.registerError(e,line,error);
console.log(e);
      });

    });

  }
/** handel change of order finish */
  handleChangeOrderFinish(change){
    let changedDoc = null;
    let changedIndex = null;

    this.Orderfinish_data.forEach((doc, index) => {
console.log(change,doc);
      if (doc._id === change.id) {
        changedDoc = doc;
        changedIndex = index;
      }

    });

    //A document was deleted
    if (change.deleted) {
      console.log(this.Orderfinish_data, changedIndex);
      if( changedIndex !=null){
      this.Orderfinish_data.splice(changedIndex, 1);
      console.log(this.Orderfinish_data, changedIndex);
      }
      this.events.publish('order_finish',this.Orderfinish_data);

    }
    else {
      //A document was updated
      if (changedDoc) {
        console.log(this.Orderfinish_data, changedIndex);
        this.Orderfinish_data[changedIndex] = change.doc;

      }

      //A document was added
      else {
        console.log(this.Orderfinish_data, changedIndex);
        this.Orderfinish_data.push(change.doc);

      }

    }

  }
 

/** to get parcel token no */

  getParcelCounter(){

//     if (this.counter_data) {
//       return Promise.resolve(this.counter_data);
//     }

//     return new Promise(resolve => {

//       this.db_counter.allDocs({

//         include_docs: true

//       }).then((result) => {



//         let docs = result.rows.map((row) => {
//           console.log(row.doc);
//           if(row.doc._id=='parcel'){
//             console.log(row.doc.p_counter);
//             this.p_counter = row.doc.parcelno;
//           }
//           else if(row.doc._id == 'billcounter'){
//             this.bill_counter = row.doc.billno
//           }
//           //this.data.push(row.doc);
//         });
// let dataparam={
//   "p_counter":this.p_counter,
//   "bill_counter":this.bill_counter
// }
//         resolve(dataparam);

//         this.db_counter.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
//           //this.handleChange(change, this.counter_data);
//         });

//       }).catch((e) => {
//             /** store error on errors database */
// let line=495;
// let error = "error on get parcel counter ";
// this.registerError(e,line,error);
// console.log(e);

//       });

//     });


  }

/** refresh parcel counter */
refreshParcelCounter(){

//   this.db_counter.get('parcel').then((doc)=>{
//     doc.parcelno = 0;
//     if(doc){
//     this.db_counter.put(doc).then(()=>{
//       console.log("updates parcel counter");
//     }).catch(e=>{
//          /** store error on errors database */
// let line=517;
// let error = "error on put counter";
// this.registerError(e,line,error);
// console.log(e);

//     });

//     }
//     }).catch(e=>{
//         /** store error on errors database */
// let line=527;
// let error = "error on  get parcel counter";
// this.registerError(e,line,error);
// console.log(e);
//     });

}
/** refresh bill no counter */
refreshBillCounter(){
  this.db_counter.get('billcounter').then((doc)=>{
    doc.billno = 0;
    if(doc){
    this.db_counter.put(doc).then(()=>{
      console.log("updates bill counter");
    }).catch(e=>{
           /** store error on errors database */
let line=543;
let error = "error on  put counter";
this.registerError(e,line,error);
console.log(e);
    });

    }
    }).catch(e=>{
         /** store error on errors database */
         let line=550;
         let error = "error on  removeOrderFinish function";
         this.registerError(e,line,error);
         console.log(e);
    });
  }


  /** table information */
  async getTables() {

    // if (this.data) {
    //   return Promise.resolve(this.data);
    // }
 
    // return new Promise(resolve => {
 
    //   this.db_tables.allDocs({

    //     include_docs: true

    //   }).then((result) => {

    //     this.data = [];

    //     let docs = result.rows.map((row) => {
    //      // console.log(row.doc);
    //       this.data.push(row.doc);
    //     });

    //     resolve(this.data);

    //     this.db_tables.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.data);
    //       console.log("table added");
    //       this.events.publish('tables', this.data);
    //     });

    //   }).catch((e) => {
    //      /** store error on errors database */
    //      let line=592;
    //      let error = "error on get tables";
    //      this.registerError(e,line,error);
    //      console.log(e);
    //   });

    // });

  }
  updateCurrentOrder(todo,updatelist) {
    console.log("current update object",todo);
    // this.db_currentOrder.get(todo._id).then((doc)=>{
    //  // doc.OccupiedStatus = flage;
    //  doc.OrderList=updatelist;
    //   if(doc){
    //   this.db_currentOrder.put(doc).then(()=>{
    //     console.log("updates order");
    //   }).catch(e=>{
    //      /** store error on errors database */
    //    let line=823;
    //    let error = "error on  upadate order";
    //    this.registerError(e,line,error);
    //    console.log(e);
    //   });
      
    //   }
    //   }).catch(e=>{
    //     /** store error on errors database */
    //     let line=834;
    //     let error = "error on get table query";
    //     this.registerError(e,line,error);
    //     console.log(e);
    //   });
     
  }







  updateCurrentOrderByCashier(todo) {
    return new Promise((resolve, reject) => {
      console.log("current update object", todo);
      this.db_currentOrder.get(todo._id).then((doc) => {
        // doc.OccupiedStatus = flage;
        //doc.OrderList=updatelist;
        if (doc) {
          this.db_currentOrder.put(todo).then(() => {
            console.log("updates order resolved");
            resolve("Resolved");
          }).catch(e => {
            /** store error on errors database */
            let line = 1405;
            let error = "error on  upadate order";
            reject("Rejected");
            this.registerError(e, line, error);
            console.log(e);
          });
      
        }
      }).catch(e => {
        /** store error on errors database */
        let line = 834;
        let error = "error on get table query";
        this.registerError(e, line, error);
        console.log(e);
      });
    });
  }
  /**
   * postCurrentOrder function is for post current order in current order database 
   * @param { Order details} -a json of order details .
   * @return {boolean } - a boolean return .
   */
 postCurrentOrder(OrderDetails){
   console.log(OrderDetails);
  return new Promise<any>((resolve,reject)=>{
  this.db_currentOrder.query(function (doc, emit) {
    emit(doc._id);
  }, { key: OrderDetails._id }).then(result => {
    if (result.rows.length == 0) {
      this.db_currentOrder.put(OrderDetails).then((data)=>{ 
         resolve(data);
      }).catch(e=>{
        console.log(e);
      });
    }
    else {
      this.db_currentOrder.put(OrderDetails).then((data) => {
        console.log("updates order resolved",data);
        resolve(data);
      }).catch(e => {
        /** store error on errors database */
        let line = 1405;
        let error = "error on  upadate order";
        reject(e);
        this.registerError(e, line, error);
        console.log(e);
      });
      // this.db_currentOrder.put(OrderDetails).then(() => {
      //   console.log("updates order resolved");
      //   resolve(true);
      // }).catch(e => {
      //   /** store error on errors database */
      //   let line = 1405;
      //   let error = "error on  upadate order";
      //   reject(e);
      //   this.registerError(e, line, error);
      //   console.log(e);
      // });
      
      // resolve(false);
      // alert("already present");
    }
    console.log("fount", result);
  }).catch((e) => {
/** store error on errors database */
let line=257;
   reject(e);
let error = "error on  fetch order finished";
this.registerError(e,line,error);
console.log(e);
  });

});


 }
/** create current order */
 CurrentOrder() {

    if (this.current_order) {
      console.log(this.current_order);
      return Promise.resolve(this.current_order);
    }

    return new Promise(resolve => {

      this.db_currentOrder.allDocs({

        include_docs: true

      }).then((result) => {

        this.current_order = [];

        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.current_order.push(row.doc);
        });
console.log(this.current_order);
        
        resolve(this.current_order);

        this.db_currentOrder.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          console.log("current order changes");
          this.handleChangeCurrentOrder(change);
          this.events.publish('current_order', this.current_order);
        });

      }).catch((e) => {
         /** store error on errors database */
         let line=632;
         let error = "error on  get current order";
         this.registerError(e,line,error);
         console.log(e);

      });

    });

  }
/** remove current order */
removeCurrentOrder(todo){
  return this.db_currentOrder.get(todo).then((doc)=>{
     this.db_currentOrder.remove(doc);

  }).catch((e)=>{
 /** store error on errors database */
 let line=649;
 let error = "error on  removeCurrentOrder function";
 this.registerError(e,line,error);
 console.log(e);
  });

}
/** handel changes of current order  */
  handleChangeCurrentOrder(change) {

    let changedDoc = null;
    let changedIndex = null;

    this.current_order.forEach((doc, index) => {
      // console.log(doc, change);


      if (doc._id === change.id) {
        changedDoc = doc;
        changedIndex = index;
      }

    });

    //A document was deleted
    if (change.deleted) {
      console.log(this.current_order, changedIndex);
      if( changedIndex !=null){
      this.current_order.splice(changedIndex, 1);
      console.log(this.current_order, changedIndex);
      }
    }
    else {
      //A document was updated
      if (changedDoc) {
        console.log(this.current_order, changedIndex);
        this.current_order[changedIndex] = change.doc;

      }

      //A document was added
      else {
        console.log(this.current_order, changedIndex);





        console.log("SEE HERE:",change.doc)
      /**Call Notification here */
      let data = {  
        'title': 'A new order has arrived',  
        'alertContent': "Transaction ID:"+change.doc._id
    }
        this._notificationService.generateNotification(data);  
        
        this.current_order.push(change.doc);
        // this.changeDetectorRef.detectChanges();
      }

    }

  }
/** handel any changes */
  handleChange(change, data) {

    let changedDoc = null;
    let changedIndex = null;

    data.forEach((doc, index) => {

      if (doc._id === change.id) {
        changedDoc = doc;
        changedIndex = index;
      }

    });

    //A document was deleted
    if (change.deleted) {
      console.log(data, changedIndex);
      if( changedIndex !=null){
      data.splice(changedIndex, 1);
      console.log(data, changedIndex);
      }

    }
    else {
      //A document was updated
      if (changedDoc) {
        console.log(data, changedIndex);
        data[changedIndex] = change.doc;
        //this.changeDetectorRef.detectChanges();
      }

      //A document was added
      else {
        console.log(data, changedIndex);
        data.push(change.doc);
        // this.changeDetectorRef.detectChanges();
      }

    }

  }

/** create table */
createTable(todo) {
  // let Tables = {
  //   "_id": todo.table.toString(),
  //   "table": todo.table,
  //   "strength": todo.strength,
  //   "OccupiedStatus": false

  // };
  let options = {
    live: true,
    retry: true,
    continuous: true,
    checkpoint:'source',
    auth: {
      username: this.username,
      password: this.password
    }
  };
   console.log(todo);
  return this.db_tables.query(function (doc, emit) {
    emit(doc.table);
  }, { key: todo.table }).then( async (result) => {
    console.log("result ",result);
    if (result.rows.length == 0) {

      this.db_tables.put(todo).then(async (data)=>{
       await this.db_tables.sync(this.remote_tables,options);
        let toast = await this.toastCtrl.create({
          message: ' Table Added Successfully ',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass'

        });
        await toast.present();
      }).catch(e=>{
        /** store error on errors database */
let line=757;
let error = "error on  put tables";
this.registerError(e,line,error);
console.log(e);
reject(e);
      });
      
    }
    else {
    //  alert("already present");
    }
    console.log("fount", result);
  }).catch((e) => {
       /** store error on errors database */
let line=779;
let error = "error on query on table";
this.registerError(e,line,error);
console.log(e);
  reject(e);
  });



}
/** getCurrentOrderById */
getCurrentOrderById(orderId:string){
  return new Promise((resolve,reject)=>{
  // this.db_currentOrder.get(orderId).then((doc) => {
  //       console.log("doc of currnrt order",doc);
  //       resolve(doc);
  //    }).catch(e => {
  //      reject(e);
  //   /** store error on errors database */
  //   let line = 834;
  //   let error = "error on get table query";
  //   this.registerErrors(e, line, error, "updateTableStatus");
  //   console.log(e);
  //  });
  });
}
/** update table */
updateTable(todo) {
  let options = {
    live: true,
    retry: true,
    continuous: true,
    checkpoint:'source',
    auth: {
      username: this.username,
      password: this.password
    }
  };
  //** to serach table by its name */
this.db_tables.query(function (doc, emit) {
    emit(doc.table);
  }, { key: todo.table }).then(result => {
    console.log("fount", result);
  }).catch((e) => {
        /** store error on errors database */
let line=800;
let error = "error on query on table";
this.registerError(e,line,error);
console.log(e);

  });

  this.db_tables.put(todo).catch(async(e) => {
   await this.db_tables.sync(this.remote_tables,options);
         /** store error on errors database */
let line=809;
let error = "error on  put tables";
this.registerError(e,line,error);
console.log(e);
  });
}

/** update table status booked , occupied or vaccant */
updateTableStatus(tableObj: any, status: string) {
  console.log(tableObj,status);
  this.db_tables.get(tableObj._id).then((doc:TableInfo) => {
    if (status == TableStatus[TableStatus.booked]) {
      doc.occupied = true;
      doc.status = status;
      doc.order_id = tableObj.order_id;
    }
    else if (status == TableStatus[TableStatus.occupied]) {
      doc.occupied = true;
      doc.status = status;
    } else if (status == TableStatus[TableStatus.vaccant]) {
      doc.order_id=[];
      doc.status = status;
      doc.occupied = false;
    }
    if (doc) {
      console.log("tables", doc);
      this.db_tables.put(doc).then(() => {
        console.log("updates table");
      }).catch(e => {
        /** store error on errors database */
        let line = 823;
        let error = "error on  upadate table status";
        this.registerErrors(e, line, error,"updateTableStatus");
        console.log(e);
      });

    }
  }).catch(e => {
    /** store error on errors database */
    let line = 834;
    let error = "error on get table query";
    this.registerErrors(e, line, error, "updateTableStatus");
    console.log(e);
  });

}
/** delete table */
  deleteTable(todo) {
   return  this.db_tables.remove(todo).catch((e) => {
       /** store error on errors database */
  let line=855;
  let error = "error on  delete Tbale function";
  this.registerError(e,line,error);
  console.log(e);
    });
  }

/** reject current order  */
deleteCurrentOrder(todo) {
  console.log(todo);
  return this.db_currentOrder.get(todo._id).then((doc)=>{
    console.log(doc);
    this.db_currentOrder.remove(doc).catch((e) => {
        /** store error on errors database */
         let line=895;
         let error = "error on deleted current ";
         this.registerError(e,line,error);
        console.log(e);

      });

  }).catch((e)=>{
          /** store error on errors database */
let line=903;
let error = "error on query cashier";
this.registerError(e,line,error);
console.log(e);
  });

//   return this.db_currentOrder.remove(todo).then(()=>{
    
//     console.log("order delivered removed from current order");
//   }).catch((e) => {
//      /** store error on errors database */
// let line=855;
// let error = "error on  delete Tbale function";
// this.registerError(e,line,error);
// console.log(e);
//   });
}

  /** cashier update */
  updateCashier(todo) {
    this.db_cashier.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(result => {
      console.log("fount", result);
    }).catch((e) => {
        /** store error on errors database */
  let line=872;
  let error = "error on updateCashier function";
  this.registerError(e,line,error);
  console.log(e);
    });

    this.db_cashier.put(todo).catch((e) => {
       /** store error on errors database */
  let line=881;
  let error = "error on put cashier";
  this.registerError(e,line,error);
  console.log(e);

    });
  }


  updateStaff(todo) {
  //  return this.db_staff.query(function (doc, emit) {
  //     emit(doc.phone);
  //   }, { key: todo.phone }).then(result => {
  //     console.log("fount", result);
  //   }).catch((e) => {
  //       /** store error on errors database */
  // let line=872;
  // let error = "error on updateCashier function";
  // this.registerError(e,line,error);
  // console.log(e);
  //   });

  //   this.db_staff.put(todo).catch((e) => {
  //      /** store error on errors database */
  // let line=881;
  // let error = "error on put cashier";
  // this.registerError(e,line,error);
  // console.log(e);

  //   });
  }

   /** put final tips added order -- not using this  */
   postFinalHt(todo) {
    console.log("finish ", todo);
    // return this.db_finalHt.query(function (doc, emit) {
    //   emit(doc._id);
    // }, { key: todo._id }).then(result => {
    //   console.log(result);
    //   if (result.rows.length == 0) {
    //     this.db_finalHt.put(todo).catch(e => {
    //       /** store error on errors database */
    //       let line = 220;
    //       let error = "error on  put final order";
    //       this.registerErrors(e, line, error, "postFinalHt");
    //       console.log(e);
    //     });


    //   }
    //   else {
    //     // alert("already present");
    //   }
    //   console.log("fount", result);
    // }).catch((e) => {
    //   /** store error on errors database */
    //   let line = 235;
    //   let error = "error on  query final order function";
    //   this.registerErrors(e, line, error, "postFinalHt");
    //   console.log(e);
    // });
  }

/** delete cashier data */
  deleteCashier(todo) {
    console.log(todo);
    this.db_cashier.get(todo._id).then((doc)=>{
      console.log(doc);
      return this.db_cashier.remove(doc).catch((e) => {
          /** store error on errors database */
  let line=895;
  let error = "error on deletecashier";
  this.registerError(e,line,error);
  console.log(e);

        });

    }).catch((e)=>{
            /** store error on errors database */
  let line=903;
  let error = "error on query cashier";
  this.registerError(e,line,error);
  console.log(e);
    });


  }


  deleteStaff(todo) {
    console.log(todo);
    this.db_staff.get(todo._id).then((doc)=>{
      console.log(doc);
      return this.db_staff.remove(doc).catch((e) => {
          /** store error on errors database */
  let line=895;
  let error = "error on deletecashier";
  this.registerError(e,line,error);
  console.log(e);

        });

    }).catch((e)=>{
            /** store error on errors database */
  let line=903;
  let error = "error on query cashier";
  this.registerError(e,line,error);
  console.log(e);
    });


  }
/** put final tips added order  */
postFinalBot(todo) {
  /** not using this  */
  console.log("finish ", todo);
  // return this.db_finalBot.query(function (doc, emit) {
  //   emit(doc._id);
  // }, { key: todo._id }).then(result => {
  //   console.log(result);
  //   if (result.rows.length == 0) {
  //     this.db_finalBot.put(todo).catch(e => {
  //       /** store error on errors database */
  //       let line = 220;
  //       let error = "error on  put final order";
  //       this.registerErrors(e, line, error, "postFinalBot");
  //       console.log(e);
  //     });


  //   }
  //   else {
  //     // alert("already present");
  //   }
  //   console.log("fount", result);
  // }).catch((e) => {
  //   /** store error on errors database */
  //   let line = 235;
  //   let error = "error on  query final order function";
  //   this.registerErrors(e, line, error, "postFinalBot");
  //   console.log(e);
  // });
}
/** create cashier data */
  createCashier(todo) {
    console.log(todo);
  return  this.db_cashier.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(async (result) => {
      if (result.rows.length == 0) {
        this.db_cashier.post(todo);
        let toast = await this.toastCtrl.create({
          message: 'Cashier Added Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass',
        });
        // await toast.onDidDismiss(
        // //   () => {
        // //   console.log('Dismissed toast');
        // // }
        // );
        await toast.present();
      }
      else {

        let alert = await this.alertCtrl.create({
          header: 'Oops!',
          message: 'already present',
          cssClass: 'alertCustomCss',
  
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }]
        });
        await alert.present();
      }
      console.log("fount", result);
    }).catch((e) => {
         /** store error on errors database */
  let line=934;
  let error = "error on createCashier function";
  this.registerError(e,line,error);
  console.log(e);

    });


  }


  addStaff(todo) {
    console.log(todo);
  return  this.db_staff.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(async (result) => {
      if (result.rows.length == 0) {
        this.db_staff.post(todo);
        let toast = await this.toastCtrl.create({
          message: 'Staff Added Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass',
        });
        // await toast.onDidDismiss(
        // //   () => {
        // //   console.log('Dismissed toast');
        // // }
        // );
        await toast.present();
      }
      else {

        let alert = await this.alertCtrl.create({
          header: 'Oops!',
          message: 'already present',
          cssClass: 'alertCustomCss',
  
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked');
              }
            }]
        });
        await alert.present();
      }
      console.log("fount", result);
    }).catch((e) => {
         /** store error on errors database */
  let line=934;
  let error = "error on createCashier function";
  this.registerError(e,line,error);
  console.log(e);

    });


  }


  async getkitchendata() {
    // if (this.kitchenstocks_data) {
    //   console.log('kitchen data is:',this.kitchenstocks_data);
    //   return Promise.resolve(this.kitchenstocks_data);
    // }

    // // return new Promise(resolve => {
    //  return new Promise<any>(async(resolve, reject) => {
    //    console.log('path is:',this.db_kitchen);
    //  await this.db_kitchen.allDocs({

    //     include_docs: true

    //   }).then((result) => {

    //     this.kitchenstocks_data = [];

    //     let docs = result.rows.map((row) => {
    //       this.kitchenstocks_data.push(row.doc);
    //     });

    //     resolve(this.kitchenstocks_data);

    //     this.db_kitchen.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.kitchenstocks_data);
    //       this.events.publish('kitchenstocks_data', this.kitchenstocks_data);
    //     });

    //   }).catch((error) => {
    //   reject(error);
    //     console.log(error);

    //   });

    // });
  }

 async getfinalkitchendata() {
    // if (this.finalkitchenstocks_data) {
    //   return Promise.resolve(this.finalkitchenstocks_data);
    // }

    // return new Promise(resolve => {
      return new Promise<any>(async(resolve, reject) => {
    //   await this.db_finalkitchen.allDocs({

    //     include_docs: true

    //   }).then((result) => {

    //     this.finalkitchenstocks_data = [];

    //     let docs = result.rows.map((row) => {
    //       this.finalkitchenstocks_data.push(row.doc);
    //     });

    //     resolve(this.finalkitchenstocks_data);

    //     this.db_finalkitchen.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.finalkitchenstocks_data);
    //       this.events.publish('finalkitchenstocks_data', this.finalkitchenstocks_data);
    //     });

    //   }).catch((error) => {
    // reject(error);
    //     console.log(error);

    //   });

    });
  }
  async getliquordata() {
    // if (this.liquorstock_data) {
    //   return Promise.resolve(this.liquorstock_data);
    // }

    // return new Promise(resolve => {
      return new Promise<any>(async(resolve, reject) => {

     await this.db_liquor.allDocs({

        include_docs: true

      }).then((result) => {

        this.liquorstock_data = [];

        let docs = result.rows.map((row) => {
          this.liquorstock_data.push(row.doc);
        });

        resolve(this.liquorstock_data);

        this.db_liquor.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.liquorstock_data);
          this.events.publish('liquorstock_data', this.liquorstock_data);
        });

      }).catch((error) => {
         reject(error);
        console.log(error);

      });

    });
  }

 async getfinalliquordata() {
   /** not using this  */
    // if (this.finalliquor_data) {
    //   return Promise.resolve(this.finalliquor_data);
    // }

    // return new Promise(resolve => {
    //   return new Promise<any>(async(resolve, reject) => {
    //   await this.db_finalliquor.allDocs({

    //     include_docs: true

    //   }).then((result) => {

    //     this.finalliquor_data = [];

    //     let docs = result.rows.map((row) => {
    //       this.finalliquor_data.push(row.doc);
    //     });

    //     resolve(this.finalliquor_data);

    //     this.db_finalliquor.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
    //       this.handleChange(change, this.finalliquor_data);
    //       this.events.publish('finalliquor_data', this.finalliquor_data);
    //     });

    //   }).catch((error) => {
    //  reject(error);
    //     console.log(error);

    //   });

    // });
  }
/** fetch cahshier data */
 async getCashier() {

    if (this.cashier_data) {
      return Promise.resolve(this.cashier_data);
    }
    return new Promise(async(resolve) => {
      await this.db_cashier.allDocs({

        include_docs: true

      }).then((result) => {
        this.cashier_data = [];
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.cashier_data.push(row.doc);
        });
        resolve(this.cashier_data);
        this.db_cashier.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.cashier_data);
          this.events.publish('cashier_data', this.cashier_data);

        });

      }).catch((e) => {
              /** store error on errors database */
  let line=957;
  let error = "error on getCashier function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }


  async getStaff() {

    if (this.staff_data) {
      return Promise.resolve(this.staff_data);
    }
    return new Promise(async(resolve) => {
      await this.db_staff.allDocs({

        include_docs: true

      }).then((result) => {
        this.staff_data = [];
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.staff_data.push(row.doc);
        });
        resolve(this.staff_data);
        this.db_staff.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.staff_data);
          this.events.publish('cashier_data', this.staff_data);

        });

      }).catch((e) => {
              /** store error on errors database */
  let line=957;
  let error = "error on getCashier function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }

  /** customer data */
  updateCustomer(todo) {
    this.db_customer.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(result => {
      console.log("fount", result);
    }).catch((e) => {
        /** store error on errors database */
  let line=872;
  let error = "error on updatecustomer function";
  this.registerError(e,line,error);
  console.log(e);
    });

    this.db_customer.put(todo).catch((e) => {
       /** store error on errors database */
  let line=881;
  let error = "error on put customer";
  this.registerError(e,line,error);
  console.log(e);

    });
  }

  /** customer data */
  updateCustomerCorporate(todo) {
    this.db_customer_corporate.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(result => {
      console.log("fount", result);
    }).catch((e) => {
        /** store error on errors database */
  let line=872;
  let error = "error on updatecustomer function";
  this.registerError(e,line,error);
  console.log(e);
    });

    this.db_customer_corporate.put(todo).catch((e) => {
       /** store error on errors database */
  let line=881;
  let error = "error on put customer";
  this.registerError(e,line,error);
  console.log(e);

    });
  }

  setIngredient(param){
    return this.db_recipes.query(function (doc, emit) {
      emit(doc._id);
    }, { key: param._id }).then(async (result) => {
      if (result.rows.length == 0) {
        this.db_recipes.post(param);
        let toast = await this.toastCtrl.create({
          message: 'Ingredient Added Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass',
        });
        await toast.present();
      }
      else {
        this.db_recipes.get(param._id).then((doc)=>{
          doc.ingredient = param.ingredient;
          if(doc){
          this.db_recipes.put(doc).then(()=>{
            console.log("updates recipes");
          }).catch(e=>{
             /** store error on errors database */
           let line=823;
           let error = "error on  upadate recipes status";
           this.registerError(e,line,error);
           console.log(e);
          });
          
          }
          }).catch(e=>{
            /** store error on errors database */
            let line=834;
            let error = "error on get recipes query";
            this.registerError(e,line,error);
            console.log(e);
          });
       // alert("already present");
      }
      console.log("fount", result);
    }).catch((e) => {
         /** store error on errors database */
  let line=934;
  let error = "error on ingredient function";
  this.registerError(e,line,error);
  console.log(e);

    });

  
  }
  
  getItemRecipe(id:any){
    return new Promise((resolve,reject) => {
    this.db_recipes.get(id).then(result => {
      console.log("fount", result);
      resolve(result);
    }).catch((e) => {
      reject(e);
         /** store error on errors database */
  let line=934;
  let error = "error on ingredient function";
  this.registerError(e,line,error);
  console.log(e);

    });
  });

  }
  
  /** fetch customer data */
  getIngredient() {

    if (this.recipes_data) {
      return Promise.resolve(this.recipes_data);
    }
    return new Promise(resolve => {
      this.db_recipes.allDocs({

        include_docs: true

      }).then((result) => {
        this.recipes_data = [];
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.recipes_data.push(row.doc);
        });
        resolve(this.recipes_data);
        this.db_recipes.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.recipes_data);
          this.events.publish('recipes_data', this.recipes_data);

        });

      }).catch((e) => {
              /** store error on errors database */
  let line=957;
  let error = "error on getcustomer function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }
/** create cashier data */
  createCustomer(todo) {
    return this.db_customer.query(function (doc, emit) {
      emit(doc.phone);
    }, { key: todo.phone }).then(async (result) => {
      console.log("db_customer");
      
      if (result.rows.length == 0) {
        console.log("createCustomer");
        
        this.db_customer.post(todo);
        let toast = await this.toastCtrl.create({
          message: 'Member Added Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass',
        });
        toast.onDidDismiss(
        //   () => {
        //   console.log('Dismissed toast');
        // }
        );
        await toast.present();
      }
      else {
        this.db_customer.put(todo).then(() => {
          console.log("updates customer resolved");
          // resolve(true);
        }).catch(e => {
          /** store error on errors database */
          let line = 1405;
          let error = "error on  upadate customer";
          reject(e);
          this.registerError(e, line, error);
          console.log(e);
        });
      //  alert("already present");
      }
      console.log("fount", result);
    }).catch((e) => {
         /** store error on errors database */
  let line=934;
  let error = "error on createcustomerfunction";
  this.registerError(e,line,error);
  console.log(e);

    });


  }


/** create cashier data */
createCustomerCorporate(todo) {
  return this.db_customer_corporate.query(function (doc, emit) {
    emit(doc.phone);
  }, { key: todo.phone }).then(async (result) => {
    console.log("db_customer");
    
    if (result.rows.length == 0) {
      console.log("createCustomerCorporate");
      
      this.db_customer_corporate.post(todo);
      let toast = await this.toastCtrl.create({
        message: 'Corporate Member Added Successfully.',
        duration: 2000,
        position: 'top',
        cssClass:'handlerclass',
      });
      toast.onDidDismiss(
      //   () => {
      //   console.log('Dismissed toast');
      // }
      );
      await toast.present();
    }
    else {
    //  alert("already present");
    }
    console.log("fount", result);
  }).catch((e) => {
       /** store error on errors database */
let line=934;
let error = "error on createcustomerfunction";
this.registerError(e,line,error);
console.log(e);

  });


}


/** fetch customer data */
  getCustomer() {

    if (this.customer_data) {
      return Promise.resolve(this.customer_data);
    }
    return new Promise(resolve => {
      this.db_customer.allDocs({

        include_docs: true

      }).then((result) => {
        this.customer_data = [];
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.customer_data.push(row.doc);
        });
        resolve(this.customer_data);
        this.db_customer.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.customer_data);
          this.events.publish('cashier_data', this.customer_data);

        });

      }).catch((e) => {
              /** store error on errors database */
  let line=957;
  let error = "error on getcustomer function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }


  getCustomerCorporate() {

    if (this.customer_data_corporate) {
      return Promise.resolve(this.customer_data_corporate);
    }
    return new Promise(resolve => {
      this.db_customer_corporate.allDocs({

        include_docs: true

      }).then((result) => {
        this.customer_data_corporate = [];
        let docs = result.rows.map((row) => {
          console.log(row.doc);
          this.customer_data_corporate.push(row.doc);
        });
        resolve(this.customer_data_corporate);
        this.db_customer_corporate.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.customer_data_corporate);
          this.events.publish('cashier_data', this.customer_data_corporate);

        });

      }).catch((e) => {
              /** store error on errors database */
  let line=957;
  let error = "error on getcustomer function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }

  /** end customer data */

/** waiter list query  */

 async updateWaiter(todo) {
 await this.db_waiter.query(function (doc, emit) {
    emit(doc.phone);
  }, { key: todo.phone }).then(result => {
    console.log("fount", result);
  }).catch((e) => {
      /** store error on errors database */
let line=872;
let error = "error on updatewaiter function";
this.registerError(e,line,error);
console.log(e);
  });

  await this.db_waiter.put(todo).catch((e) => {
     /** store error on errors database */
let line=881;
let error = "error on put waiter";
this.registerError(e,line,error);
console.log(e);

  });
}
/** delete cashier data */
deleteWaiter(todo) {
  console.log(todo);
  this.db_waiter.get(todo._id).then((doc)=>{
    console.log(doc);
    return this.db_waiter.remove(doc).catch((e) => {
        /** store error on errors database */
let line=895;
let error = "error on deletewaiter";
this.registerError(e,line,error);
console.log(e);

      });

  }).catch((e)=>{
          /** store error on errors database */
let line=903;
let error = "error on query waiter";
this.registerError(e,line,error);
console.log(e);
  });


}

/** create cashier data */
createWaiter(todo) {
  return this.db_waiter.query(function (doc, emit) {
    emit(doc.phone);
  }, { key: todo.phone }).then(async (result) => {
    if (result.rows.length == 0) {
      this.db_waiter.post(todo);
      let toast = await this.toastCtrl.create({
        message: 'Waiter Added Successfully.',
        duration: 2000,
        position: 'top',
        cssClass:'handlerclass',
      });
      await toast.present();
    }
    else {
      const alert = await this.alertCtrl.create({
        cssClass:'alertCustomCss',
        header: 'Oops!!!',
        //subHeader: 'Subtitle',
        message: 'Already Present',
        buttons: ['OK']
      });
  
      await alert.present();
    }
    console.log("found", result);
  }).catch((e) => {
       /** store error on errors database */
let line=934;
let error = "error on createwaiter function";
this.registerError(e,line,error);
console.log(e);

  });


}
/** fetch cahshier data */
getWaiter() {

  if (this.waiter_data) {
    return Promise.resolve(this.waiter_data);
  }
  return new Promise(resolve => {
    this.db_waiter.allDocs({

      include_docs: true

    }).then((result) => {
      this.waiter_data = [];
      let docs = result.rows.map((row) => {
        console.log(row.doc);
        this.waiter_data.push(row.doc);
      });
      resolve(this.waiter_data);
      this.db_waiter.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        this.handleChange(change, this.waiter_data);
        this.events.publish('waiter_data', this.waiter_data);

      });

    }).catch((e) => {
            /** store error on errors database */
let line=957;
let error = "error on getWaiter function";
this.registerError(e,line,error);
console.log(e);
    });

  });

}

  /** restaurant info  update */
  updateResto(todo) {
  //   let RestoData = {
  //     "_id": todo._id,
  //     "_rev": todo._rev,
  //     "rname": todo.rname,
  //     "adminname": todo.adminname,
  //     "raddress": todo.raddress,
  //     "cnumber": todo.cnumber,
  //     "email": todo.email,
  //     "altemail": todo.altemail,
  //     "gstno": todo.gstno
  //   };
  //   this.db_resto.put(todo).catch((e) => {
  //               /** store error on errors database */
  // let line=982;
  // let error = "error on updateResto function";
  // this.registerError(e,line,error);
  // console.log(e);
  //   });
  }
/** delete resto information */
  deleteResto(todo) {
  //   this.db_resto.remove(todo).catch((e) => {
  //       /** store error on errors database */
  // let line=992;
  // let error = "error on deleteResto function";
  // this.registerError(e,line,error);
  // console.log(e);
  //   });
  }
/** create resto information */
 async createResto(RestoData) {
   return new Promise(resolve => {
    //  console.log("put resto info", RestoData);
    //  this.db_resto.post(RestoData).then(async (data) => {
    //   // let toast = await this.toastCtrl.create({
    //   //   message: 'Restaurant added Successfully.',
    //   //   duration: 2000,
    //   //   position: 'top',
    //   //   cssClass: 'handlerclass',
    //   // });
    //   // await toast.onDidDismiss();
    //   //  await toast.present();
    //    resolve("Resolved");
    //  }).catch((e) => {
    //    /** store error on errors database */
    //    let line = 1012;
    //    let error = "error on createResto function";
    //    this.registerError(e, line, error);
    //    console.log(e);
    //    reject("Rejected");

    //  });

   });


  }

/** get Resto info  */
  getResto() {

    if (this.resto_data) {
      return Promise.resolve(this.resto_data);
    }
    return new Promise(resolve => {
      this.db_resto.allDocs({

        include_docs: true

      }).then((result) => {
        //this.resto_data = [];
        let docs = result.rows.map((row) => {
          console.log("got info", row.doc);
          this.resto_data = row.doc;
        });
        resolve(this.resto_data);
        this.db_resto.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChangeResto(change, this.resto_data);
          this.events.publish('resto_data', this.resto_data);
        });

      }).catch((e) => {
          /** store error on errors database */
  let line=1058;
  let error = "error on query on resto info function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }

/** handleChange of Resto info  */
  handleChangeResto(change, data: any) {

    let changedDoc = null;
    let changedIndex = null;
    console.log("change", change, data);
    if (data._id === change.id) {
      changedDoc = data;
      console.log("change", change, data);
    }

    //A document was updated
    if (changedDoc) {
      this.resto_data = change.doc;
      console.log("change", change, data);
    }

    //A document was added
    else {
      this.resto_data = (change.doc);
      console.log("change", change, data);
    }

  }
  /** Item information */
  // async getMenuItems() {

  //   if (this.item_data) {
  //     await this.getCategories(this.item_data);
  //     return Promise.resolve(this.item_data);
  //   }

  //   return new Promise(resolve => {

  //     this.db_item.allDocs({

  //       include_docs: true

  //     }).then((result) => {

  //       this.item = [];

  //       let docs = result.rows.map(async (row) => {
  //         console.log("Row:",row.doc);
  //         this.item.push(row.doc);
  //          /** get cusin category wise */
  //         // row.doc.forEach((value) => {
          
  //           let json = {
  //             "cuisine": null,
  //             "categories": []
  //           };
            
  //           let cu = String(row.doc.cuisine).toLowerCase();
  //           let cate = row.doc.categories;
  //           if (cu || cate) {
  //             let index = await this.cuisineindex(this.cuisineList, cu);
  //             let j = -1;
  //             if (index >= 0) {
  //              // j = await this.CategoriesIndex(this.cuisineList[index].categories, cate);
  //               // console.log("CategoriesIndex",index, j);
  //               // if (j < 0) {
  //               //   this.cuisineList[index].categories.push(cate);
  //               //   console.log("cuisineList :",this.cuisineList);
  //               // }
  //             }
  
  //             else {
  //               json.cuisine = String(row.doc.cuisine).toLowerCase();
  //             //  json.categories.push(row.doc.categories);
  //               let js = {
  //                 "cuisine": cu,
  //                 "categories": row.doc.categories
  //               }
  //               this.cuisineList.push(json);
  //               console.log("cuisineList :",this.cuisineList);
  //           }
  //           }
  //             /** end get cuisin */
  //       });

  //       resolve(this.item);

  //       this.db_item.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
  //         this.handleChange(change, this.item_data);
  //         this.events.publish('menuList', this.item_data);
  //       });

  //     }).catch((e) => {
  //        /** store error on errors database */
  // let line=1136;
  // let error = "error on getMenuList function";
  // this.registerError(e,line,error);
  // console.log(e);
  //     });

  //   });

  // }

getCategories(itemData:any){
     /** get cusin category wise */
          // row.doc.forEach((value) => {
            this.cuisineList[0] ={
              "cuisine": 'all',
              "categories": []
            };
          this.item_data.forEach(async (val)=>{
            let json = {
              "cuisine": null,
              "categories": []
            };
            // let cu = String(val.cuisine).toLocaleLowerCase();
  
            //   if (cu) {
            //     let index = await this.cuisineindex(this.cuisineList, cu);
            //     console.log(index);
            //      if (index < 0) {
            //       let js = {
            //         "cuisine": String(val.cuisine).toLocaleLowerCase(),
            //         "categories": String(val.categories).toLocaleLowerCase()
            //       }
            //       this.cuisineList.push(json);
               
            //    }
            //   }
            let cu = String(val.cuisine).toLowerCase();
            let cate = val.categories;
            if (cu || cate) {
              let index = await this.cuisineindex(this.cuisineList, cu);
              let j = -1;
              if (index >= 0) {
               // j = await this.CategoriesIndex(this.cuisineList[index].categories, cate);
                console.log("CategoriesIndex",index, j);
                // if (j < 0) {
                //   this.cuisineList[index].categories.push(cate);
                //   console.log("cuisineList :",this.cuisineList);
                // }
              }
  
              else {
                json.cuisine =val.cuisine;
               // json.categories.push(val.categories);
                let js = {
                  "cuisine": cu,
                  "categories": val.categories
                }
                this.cuisineList.push(json);
                console.log("cuisineList :",this.cuisineList);
            }
            }
          });

          
              /** end get cuisin */
}
  /** Item information */
  async getMenuList() {

  //   if (this.item_data) {
  //     console.log('here');
  //     this.getCategories(this.item_data);
  //     return Promise.resolve(this.item_data);
  //   }
 
  //   return new Promise<any>((resolve, reject) => {
  //     console.log('i am here also');
  //     //console.log("db_final:",this.database_json.final+this.uid);
  //   this.db_item.allDocs({

  //     include_docs: true

  //   }).then(async(result) => {
  //     console.log('i am here');
  //     this.item_data = [];
  //     console.log("Result:", result, typeof (result));
  //     this.cuisineList[0] ={
  //       "cuisine": 'all',
  //       "categories": []
  //     };
  //     let docs = result.rows.map(async (row) => {
  //      // console.log(row.doc);
  //       this.item_data.push(row.doc);
     
  //        /** get cusin category wise */
  //         // row.doc.forEach((value) => {
          
  //           let json = {
  //             "cuisine": null,
  //             "categories": []
  //           };
            
  //           let cu = String(row.doc.cuisine).toLowerCase();
  //           let cate = row.doc.categories;
  //           if (cu || cate) {
  //             let index = await this.cuisineindex(this.cuisineList, cu);
  //             let j = -1;
  //             if (index >= 0) {
  //              // j = await this.CategoriesIndex(this.cuisineList[index].categories, cate);
  //               // console.log("CategoriesIndex",index, j);
  //               // if (j < 0) {
  //               //   this.cuisineList[index].categories.push(cate);
  //               //   console.log("cuisineList :",this.cuisineList);
  //               // }
  //             }
  
  //             else {
  //               json.cuisine = String(row.doc.cuisine).toLowerCase();
  //             //  json.categories.push(row.doc.categories);
  //               let js = {
  //                 "cuisine": cu,
  //                 "categories": row.doc.categories
  //               }
  //               this.cuisineList.push(json);
  //               console.log("cuisineList :",this.cuisineList);
  //           }
  //           }
  //             /** end get cuisin */
  //     });
  //     await this.getCategories(this.item_data);
  //     resolve(this.item_data);
  //     this.db_item.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
  //       this.handleChange(change, this.item_data);
  //       this.events.publish('menuList', this.item_data);

  //     });

  //   }).catch((e) => {
  //     reject(e);
  //     /** store error on errors database */
  //     let line = 2723;
  //     let error = "error on getMenuList function";
  //     //this.registerError(e, line, error, "getBillsKot");
  //     console.log(e);
  //   });

  // });

  }
 

  /** get menu cuisin  */
  getCuisin(){
return this.cuisineList;
  }
/**
  * CategoriesIndex function is for get index .
  * @param listarray -a list array .
  * @param categories - categories string .
  * @returns { void } -a void return.
  */
  CategoriesIndex(listarray: any, categories: any) {
    var data = listarray;
    var index = -1;
    let filteredObj = data.find(function (item, i) {
      console.log(listarray, categories, item);
      if (item == categories) {
        index = i;
      }
    });
    return index;
  }
  /**
  * cuisineindex function is for get cuisine index .
  * @param listarray - a list array.
  * @param cuisine - cusine string .
  * @param categories - categories string.
  * @returns { void } -a void return .
  */
  async cuisineindex(listarray: any, cuisine: string) {
    var data = listarray;
    var index: any;
    let filteredObj = await data.find(function (item, i) {
      let cu = String(item.cuisine).toLowerCase();
     // console.log(String(item.cuisine).toLowerCase(),item.categories);
      if (cu == cuisine) {

        index = i;
      }
    });
    return index;

  }
/** update Menu List  */
  async updateMenuList(data) {
    //** to serach item by its name */
    // let Menu = {
    //     _id: data._id,
    //   _rev:   data._rev,
    //   itemname: data.itemname,
    //     fullplate: data.fullplate,
    //     gstfullplate: data.gstfullplate,
    //     halfplate: data.halfplate,
    //     gsthalfplate: data.gsthalfplate,
    //     fpics: data.fpics,
    //     cuisine:data.cuisine,
    //     categories:data.categories,
    //     subcategories:data.subcategories,
    //     hpics: data.hpics,
    //     itemtype: data.itemtype,
    //     itemIndex: data.itemIndex,
    //     includedgst: data.includedgst
    // };
    await this.db_item.query( (doc, emit)=> {
      emit(doc.itemname);
    }, { key: data.itemname }).then(async(result) => {
      console.log("fount", result);
     await this.db_item.put(data).then((doc) => {
        console.log("Updated in database:", doc);
      }).catch((e) => {
        console.log(e);
          /** store error on errors database */
          let line=1219;
          let error = "error on  put menu";
         this.registerError(e,line,error);
  
      });
    }).catch((e) => {
      console.log("error", e);
        /** store error on errors database */
        let line=1210;
        let error = "error on createMenuList put";
       this.registerError(e,line,error);

    });
     console.log('updated data is:',data);
  
  }

/** delete Menu List  */
  deleteMenuList(todo) {
    return this.db_item.remove(todo._id, todo._rev).catch((e) => {
      console.log(e);
         /** store error on errors database */
         let line=1243;
         let error = "error on  deleteMenuList";
        this.registerError(e,line,error);

    });
  }
/** register error */
registerError(e,line,error){
  let date = moment().format('YYYY-MM-DD');
  let time = moment().format('h:mm:ss a');
  this.timestamp = date + " at " + time;
  let param={
    "page":"pouch-db-auth",
    "error":e,
    "timestamp":this.timestamp,
    "lineno":line,
    "appVersion":this.appVersion,
    "details":error
  }
  this.postErrors(param);
}

/**  post Error on errors database */
async postErrors(todo) {
        this.db_error.post(todo);
        let toast = await this.toastCtrl.create({
          message: 'Error Stored Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass',
        });
        await toast.onDidDismiss(
        //   () => {
        //   console.log('Dismissed toast');
        // }
        );


  }



  createStock(stock){
    let data ={
      "_id":stock.itemname,
      "itemname":stock.itemname,
      "quantity":stock.quantity,
      "unit":stock.unit,
      "price":stock.price,
      "amount":stock.amount,
      "description":stock.description

    };
    this.db_stock.query(function(doc,emit){
      emit(doc.itemname);
    },{key:stock.itemname}).then((result)=>{
      console.log(result);
      if(result.rows.length == 0){
        this.db_stock.put(data);
        console.log('added');
      }else{
         console.log('item is there');
      }
    }).catch((e)=>{
      console.log('error on quering:',e);
    })
  }


  handlechange(change){
    let changedDoc = null;
    let changedIndex = null;

    this.stock_data.forEach((doc, index) => {

      if(doc._id === change.id){
        changedDoc = doc;
        changedIndex = index;
      }

    });

    //A document was deleted
    if(change.deleted){
      this.stock_data.splice(changedIndex, 1);
    }
    else {

      //A document was updated
      if(changedDoc){
        this.stock_data[changedIndex] = change.doc;
      }

      //A document was added
      else {
        this.stock_data.push(change.doc);
      }

    }
    this.Sync();
  }
Sync(){

  let options = {
    live: true,
    retry: true,
    continuous: true,
    checkpoint:'source',
    auth: {
      username: this.username,
      password: this.password
    }
  };
  this.db_cashier.sync(this.remote_cashier, options);
  this.db_waiter.sync(this.remote_waiter,options);
  this.db_resto.sync(this.remote_resto, options);
  this.db_item.sync(this.remote_item,options);
  this.db_currentOrder.sync(this.remote_currentOrder, options);
  this.db_currentBotOrder.sync(this.remote_currentBotOrder, options);
  this.db_currentHtOrder.sync(this.remote_currentHtOrder, options);
  this.db_counter.sync(this.remote_counter, options);
   this.db_Orderfinish.sync(this.remote_Orderfinish , options);
  this.db_final.sync(this.remote_final,options);
  // this.db_finalBot.sync(this.remote_finalBot,options);
  // this.db_finalHt.sync(this.remote_finalHt,options);
  this.db_error.sync(this.remote_error,options);
  this.db_recipes.sync(this.remote_recipes,options);
  this.db_stock.sync(this.remote_stock, options).then(()=>{
    console.log('synced');
        }).catch((e)=>{
    console.log('not synced:',e);
        })

  this.db_consumtion.sync(this.remote_consumtion,options);
  this.db_wastage.sync(this.remote_wastage,options);
  this.db_allStocks.sync(this.remote_allStocks,options);
  this.db_liquor_add_item.sync(this.remote_liquor_add_item,options);
  this.db_kitchen.sync(this.remote_kitchen,options);
  this.db_finalkitchen.sync(this.remote_finalkitchen,options);
 // this.db_hookah_add_item.sync(this.remote_hookah_add_item,options);
  this.db_customer.sync(this.remote_customer,options);
  // this.db_customer_info.sync(this.remote_customer_info,options);
  this.db_customer_corporate.sync(this.remote_customer_corporate,options)
  this.db_liquor.sync(this.remote_liquor,options);
 // this.db_finalliquor.sync(this.remote_finalliquor,options);
  this.db_invoice.sync(this.remote_invoice,options);
  this.db_finalconsumption.sync(this.remote_finalconsumption,options);
  this.db_offer.sync(this.remote_offer,options);
  this.db_staff.sync(this.remote_staff, options);

}

//   handleCurrentOrderChange(change){
//     let changedDoc = null;
//     let changedIndex = null;

//     this.current_order.forEach((doc, index) => {

//       if(doc._id === change.id){
//         changedDoc = doc;
//         changedIndex = index;
//       }

//     });

//     //A document was deleted
//     if(change.deleted){
//       this.current_order.splice(changedIndex, 1);
//     }
//     else {

//       //A document was updated
//       if(changedDoc){
//         this.current_order[changedIndex] = change.doc;
//       }

//       //A document was added
//       else {
// // console.log("SEE HERE:",change.doc)
// //       /**Call Notification here */
// //       let data = {  
// //         'title': 'Approval',  
// //         'alertContent': JSON.stringify(change.doc)
// //     }
// //       this._notificationService.generateNotification(data);  
//         this.current_order.push(change.doc);
//       }

//     }
//   }



 async getStocks(){
    if (this.stock_data) {
      console.log('data is:',this.stock_data);
      return Promise.resolve(this.stock_data);
    }

    return new Promise(async(resolve) => {
      console.log('uid is:',this.uid);
      await this.db_stock.allDocs({

        include_docs: true

      }).then((result) => {

        this.stock_data = [];

        let docs = result.rows.map((row) => {
          this.stock_data.push(row.doc);
        });

        resolve(this.stock_data);

        this.db_stock.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
          this.handleChange(change,this.stock_data);
          this.events.publish('stock_data', this.stock_data);
        });

      }).catch((error) => {

        console.log(error);

      });

    });
  }

 async getConsumtions(){
    if (this.consumtion_data) {
      return Promise.resolve(this.consumtion_data);
    }

    return new Promise(async(resolve) => {

     await this.db_consumtion.allDocs({

        include_docs: true

      }).then((result) => {

        this.consumtion_data = [];

        let docs = result.rows.map((row) => {
          this.consumtion_data.push(row.doc);
        });

        resolve(this.consumtion_data);

        this.db_consumtion.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
          this.handleChange(change,this.consumtion_data);
          this.events.publish('consumtion_data', this.consumtion_data);
        });

      }).catch((error) => {

        console.log(error);

      });

    });
  }

  async getfinalConsumptions(){
    if (this.finalconsumption_data) {
      return Promise.resolve(this.finalconsumption_data);
    }

    return new Promise(async(resolve) => {

     await this.db_finalconsumption.allDocs({

        include_docs: true

      }).then((result) => {

        this.finalconsumption_data = [];

        let docs = result.rows.map((row) => {
          this.finalconsumption_data.push(row.doc);
        });

        resolve(this.finalconsumption_data);

        this.db_finalconsumption.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
          this.handleChange(change,this.finalconsumption_data);
          this.events.publish('finalconsumption_data', this.finalconsumption_data);
        });

      }).catch((error) => {

        console.log(error);

      });

    });
  }

  async getkitchenDatadateWise(start_time: any, end_time: any) {
    let dataList: Array<any> = [];
    await this.db_finalkitchen.query({
      map: function (doc, emit) {
        if (doc.date >= start_time && doc.date <= end_time) {
          console.log(doc);
          dataList.push(doc);
        }
      },
    }).catch((e) => {
      let line = 167;
      let error = "error on  getDatawithin function for report";
      // this.registerError(e, line, error, "getDatadateWise");
      console.log(e);
    });
    return dataList;
  }

  async getliquorDatadateWise(start_time: any, end_time: any) {
    /** not using this**/
     let dataList: Array<any> = [];
    // await this.db_finalliquor.query({
    //   map: function (doc, emit) {
    //     if (doc.date >= start_time && doc.date <= end_time) {
    //       console.log(doc);
    //       dataList.push(doc);
    //     }
    //   },
    // }).catch((e) => {
    //   let line = 167;
    //   let error = "error on  getDatawithin function for report";
    //   // this.registerError(e, line, error, "getDatadateWise");
    //   console.log(e);
    // });
     return dataList;
  }
  getWastages(){
    if (this.wastage_data) {
      return Promise.resolve(this.wastage_data);
    }

    return new Promise(resolve => {

      this.db_wastage.allDocs({

        include_docs: true

      }).then((result) => {

        this.wastage_data = [];

        let docs = result.rows.map((row) => {
          this.wastage_data.push(row.doc);
        });

        resolve(this.wastage_data);

        this.db_wastage.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
          this.handleChange(change,this.wastage_data);
          this.events.publish('wastage_data', this.wastage_data);
        });

      }).catch((error) => {

        console.log(error);

      });

    });
  }

 async getallStocksdata(){
    if (this.allStocks_data) {
      return Promise.resolve(this.allStocks_data);
    }

    return new Promise(async(resolve) => {
      
      this.db_allStocks = new PouchDB(this.database_json.finalstock+this.uid);
      await this.db_allStocks.allDocs({

        include_docs: true

      }).then((result) => {

        this.allStocks_data = [];

        let docs = result.rows.map((row) => {
          this.allStocks_data.push(row.doc);
        });

        resolve(this.allStocks_data);

        this.db_allStocks.changes({live: true, since: 'now', include_docs: true}).on('change', (change) => {
          this.handleChange(change,this.allStocks_data);
          this.events.publish('allStocks_data', this.allStocks_data);
        });

      }).catch((error) => {

        console.log(error);

      });

    });
  }

  async getLiquorList() {
    if (this.liquor_data) {
      return Promise.resolve(this.liquor_data);
    }

    return new Promise(resolve => {

      this.db_liquor_add_item.allDocs({

        include_docs: true

      }).then((result) => {

        this.liquor_data = [];

        let docs = result.rows.map((row) => {
          //console.log("Row:",row.doc);
          this.liquor_data.push(row.doc);
        });
       //// console.log("Type of item_data:",this.item_data);
        resolve(this.liquor_data);

        this.db_liquor_add_item.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
          this.handleChange(change, this.liquor_data);
          this.events.publish('menuList', this.liquor_data);
        });

      }).catch((e) => {
         /** store error on errors database */
  let line=1136;
  let error = "error on getMenuList function";
  this.registerError(e,line,error);
  console.log(e);
      });

    });

  }





  /** update Menu List  */
  updateLiquorist(data) {
    //** to serach item by its name */
    let Menu = {
      "_id": data._id,
      "_rev": data._rev,
      "itemname": data.itemname,
      "fullplate": data.fullplate,
      "gstfullplate":data.gstfullplate,
       "fpics": data.fpics,
     
      "itemtype": data.itemtype,
      "itemIndex": data.itemIndex,
      "includedgst": data.includedgst
    };
    this.db_liquor_add_item.query(function (doc, emit) {
      emit(doc.itemname);
    }, { key: data.itemname }).then(result => {
      console.log("fount", result);
    }).catch((e) => {
      console.log("error", e);
        /** store error on errors database */
        let line=1210;
        let error = "error on createMenuList put";
       this.registerError(e,line,error);

    });

    this.db_liquor_add_item.put(Menu).catch((e) => {
      console.log(e);
        /** store error on errors database */
        let line=1219;
        let error = "error on  put menu";
       this.registerError(e,line,error);

    });
  }

/** delete Menu List  */
  deleteLiquorList(todo) {
    return this.db_liquor_add_item.remove(todo._id, todo._rev).catch((e) => {
      console.log(e);
         /** store error on errors database */
         let line=1243;
         let error = "error on  deleteMenuList";
        this.registerError(e,line,error);

    });
  }

  /** createMenuList for menu item store in pouchDb */
  async createMenuListBulk(data): Promise<any> {
    await this.getUid().then(data=>{
      console.log("user data",data);
   //   this.uid =data.toLowerCase();
    //this.db_item = new PouchDB(this.database_json.menulist+this.uid);
    

    // let options = {
    //   live: true,
    //   retry: true,
    //   continuous: true,
    //   checkpoint:'source',
    //   auth: {
    //     username: '3d907060-3cdb-4ba5-bbf4-0200bf48e26d-bluemix',
    //     password: '71205a46e8b43ec02b191d61295912779b4868b1a46a16a96240b191f72ac95e'
    //   }
    // };
    console.log("UID createMenuListBulk:",this.uid);
   // this.remote_item='https://3d907060-3cdb-4ba5-bbf4-0200bf48e26d-bluemix.cloudantnosqldb.appdomain.cloud/'+'menulist'+this.uid;
    console.log("Remote Item:",this.remote_item);
    // console.log("Username:",this.username);
    // console.log("Password:",this.password);
    return new Promise((resolve, reject) => {
      console.log("Data in pouch provider:",data);

      // this.db_item.query(function (doc, emit) {
      //   emit(doc.itemname);
      // }, { key: data.itemname }).then(async (result) => {
      //   if (result.rows.length == 0) {
          this.db_item.bulkDocs(data).then((data)=>{
            console.log("Post successfully data");

            // this.db_item.sync(this.remote_item, options).then((data)=>{
            //   console.log("Synced successfully");
            // });
            resolve("done");
          }).catch(e=>{
              /** store error on errors database */
  let line=1167;
  let error = "error on createMenuList put";
  this.registerError(e,line,error);
  console.log(e);
          });
          
          // let toast = await this.toastCtrl.create({
          //   message: ' Table Added Successfully.',
          //   duration: 2000,
          //   position: 'top',
          //   cssClass:'handlerclass',
          // });
          // toast.onDidDismiss();
          // await toast.present();
      //   }
      //   else {
      //   //  alert("already present");
      //   reject("already present");
      //   }
      //   console.log("fount", result);
      // }).catch((e) => {
      //    /** store error on errors database */
      //    let line=1188;
      //    let error = "error on query createMenuList ";
      //   this.registerError(e,line,error);
        
      //   console.log("error", e);
      // });

    });
  });
  }

  /** createMenuList for menu item store in pouchDb */
  createMenuList(todo): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(todo);
    this.db_item.query(function (doc, emit) {
        emit(doc.itemname);
      }, { key: todo.itemname }).then(async (result) => {
        if (result.rows.length == 0) {
          this.db_item.post(todo).then(async (data) => {
            console.log("Final Item ID:", data);
            //Send data.id and todo json to third party service provide
          });
          
          
        }
        else {
  
          let alert = await this.alertCtrl.create({
            header: 'Oops!',
            message: 'already present',
            cssClass: 'alertCustomCss',
    
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
                handler: () => {
                  console.log('Cancel clicked');
                }
              }]
          });
          await alert.present();
          resolve("done");
        }
        console.log("fount", result);
      }).catch((e) => {
           /** store error on errors database */
    let line=934;
    reject(e);
    let error = "error on createCashier function";
    this.registerError(e,line,error);
    console.log(e);
  
      });


    });

  }

  async getHookahList() {
 /** not using this  */
  //   if (this.hookah_data) {
  //     return Promise.resolve(this.hookah_data);
  //   }

  //   return new Promise(resolve => {

  //     this.db_hookah_add_item.allDocs({

  //       include_docs: true

  //     }).then((result) => {

  //       this.hookah_data = [];

  //       let docs = result.rows.map((row) => {
  //         //console.log("Row:",row.doc);
  //         this.hookah_data.push(row.doc);
  //       });
  //      //// console.log("Type of item_data:",this.item_data);
  //       resolve(this.hookah_data);

  //       this.db_hookah_add_item.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
  //         this.handleChange(change, this.hookah_data);
  //         this.events.publish('menuList', this.hookah_data);
  //       });

  //     }).catch((e) => {
  //        /** store error on errors database */
  // let line=1136;
  // let error = "error on getMenuList function";
  // this.registerError(e,line,error);
  // console.log(e);
  //     });

  //   });

  }

  /** delete Menu List  */
  deleteHookahList(todo) {
    /** not using this */
    // return this.db_hookah_add_item.remove(todo._id, todo._rev).catch((e) => {
    //   console.log(e);
    //      /** store error on errors database */
    //      let line=1243;
    //      let error = "error on  deleteMenuList";
    //     this.registerError(e,line,error);

    // });
  }

  /** update Menu List  */
  updateHookahList(data) {
    /** not using this */
    //** to serach item by its name */
    // let Menu = {
    //   "_id": data._id,
    //   "_rev": data._rev,
    //   "itemname": data.itemname,
    //   "fullplate": data.fullplate,
    //   "tobacco_type": data.tobacco_type,
    //   "description": data.description,
    //   "itemIndex": data.itemIndex,
    //   // "includedgst": data.includedgst
    // };
    // this.db_hookah_add_item.query(function (doc, emit) {
    //   emit(doc.itemname);
    // }, { key: data.itemname }).then(result => {
    //   console.log("fount", result);
    // }).catch((e) => {
    //   console.log("error", e);
    //     /** store error on errors database */
    //     let line=1210;
    //     let error = "error on createMenuList put";
    //    this.registerError(e,line,error);

    // });

    // this.db_hookah_add_item.put(Menu).catch((e) => {
    //   console.log(e);
    //     /** store error on errors database */
    //     let line=1219;
    //     let error = "error on  put menu";
    //    this.registerError(e,line,error);

    // });
  }

  deleteCustomer(customer:any){

    this.db_customer.get(customer._id).then((doc)=>{
      console.log(doc);
      return this.db_customer.remove(doc).then(async()=>{
        let toast = await this.toastCtrl.create({
          message: 'Member removed Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass'
        });
        toast.onDidDismiss();
      }).catch((e) => {
          /** store error on errors database */
  let line=895;
  let error = "error on deletewaiter";
  this.registerError(e,line,error);
  console.log(e);

        });

    }).catch((e)=>{
            /** store error on errors database */
  let line=903;
  let error = "error on query waiter";
  this.registerError(e,line,error);
  console.log(e);
    });
   }

   deleteCustomerCorporate(customerCorporate:any){

    this.db_customer_corporate.get(customerCorporate._id).then((doc)=>{
      console.log(doc);
      return this.db_customer_corporate.remove(doc).then(async()=>{
        let toast = await this.toastCtrl.create({
          message: 'Member removed Successfully.',
          duration: 2000,
          position: 'top',
          cssClass:'handlerclass'
        });
        toast.onDidDismiss();
      }).catch((e) => {
          /** store error on errors database */
  let line=895;
  let error = "error on deletewaiter";
  this.registerError(e,line,error);
  console.log(e);

        });

    }).catch((e)=>{
            /** store error on errors database */
  let line=903;
  let error = "error on query waiter";
  this.registerError(e,line,error);
  console.log(e);
    });
   }





   /** createMenuList for menu item store in pouchDb */
//  createHookahList(data): Promise<any> {
//     return new Promise(async(resolve, reject) => {

//       let Menu = {
//         "includedgst":data.includedgst,
//         "itemname": data.itemname,
//         "fullplate": data.fullplate,
//         "tobacco_type":data.tobacco_type,
//         "description":data.description

//       };

//       // this.db_hookah_add_item.createIndex({
//       //   index:{
//       //     fields:['itemname','tobacco_type']
//       //   }
//       // }).then(()=>{
//        await this.db_hookah_add_item.find({
//           selector:{
//             itemname:data.itemname,
//             tobacco_type:data.tobacco_type
//           }
//         }).then(async(result)=>{
//           console.log('result is:',result);
//           console.log('result length is:',result.docs);
//           if(result.docs.length>0){
//             console.log('item present');
//             reject();
//           }else
//           {
//             this.db_hookah_add_item.post(Menu);
//             resolve();
//           let toast = await this.toastCtrl.create({
//             message: ' Item Added Successfully.',
//             duration: 1000,
//             position: 'top',
//             cssClass:'handlerclass',
//           });
//           await toast.present();
//           }
//         }).catch((e)=>{

//         })
  

//     });
//   }

  /** Bot current order */
  updateCurrentBotOrder(todo,updatelist) {

    this.db_currentBotOrder.get(todo._id).then((doc)=>{
     // doc.OccupiedStatus = flage;
     doc.OrderList=updatelist;
      if(doc){
      this.db_currentBotOrder.put(doc).then(()=>{
        console.log("updates order");
      }).catch(e=>{
         /** store error on errors database */
       let line=823;
       let error = "error on  upadate order";
       this.registerError(e,line,error);
       console.log(e);
      });

      }
      }).catch(e=>{
        /** store error on errors database */
        let line=834;
        let error = "error on get table query";
        this.registerError(e,line,error);
        console.log(e);
      });


  }

  /** create current order */
 CurrentBotOrder() {

  if (this.currentBot_order) {
    return Promise.resolve(this.currentBot_order);
  }

  return new Promise(resolve => {

    this.db_currentBotOrder.allDocs({

      include_docs: true

    }).then((result) => {

      this.currentBot_order = [];

      let docs = result.rows.map((row) => {
       // console.log(row.doc);
        this.currentBot_order.push(row.doc);
      });

      resolve(this.currentBot_order);

      this.db_currentBotOrder.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        this.currentBot_order = this.handleChangeCurrentBotOrder(change);
        this.events.publish('currentBot_order', this.currentBot_order);
      });

    }).catch((e) => {
       /** store error on errors database */
       let line=632;
       let error = "error on  get current order";
       this.registerError(e,line,error);
       console.log(e);

    });

  });

}

/** handel changes of current order  */
handleChangeCurrentBotOrder(change) {

  let changedDoc = null;
  let changedIndex = null;
if(this.currentBot_order){
  this.currentBot_order.forEach((doc, index) => {
console.log(doc,change);
    if (doc._id === change.id) {
      changedDoc = doc;
      changedIndex = index;
    }

  });
}
  //A document was deleted
  if (change.deleted) {
    console.log(this.currentBot_order, changedIndex);
    if( changedIndex !=null){
    this.currentBot_order.splice(changedIndex, 1);
    console.log(this.currentBot_order, changedIndex);
    }
  }
  else {
    //A document was updated
    if (changedDoc) {

      this.currentBot_order[changedIndex] = change.doc;

    }

    //A document was added
    else {

      this.currentBot_order.push(change.doc);
      // this.changeDetectorRef.detectChanges();
    }

  }

}

/** create current order */
CurrentHtOrder() {

  if (this.currentHt_order) {
    return Promise.resolve(this.currentHt_order);
  }

  return new Promise(resolve => {

    this.db_currentHtOrder.allDocs({

      include_docs: true

    }).then((result) => {

      this.currentHt_order = [];

      let docs = result.rows.map((row) => {
        console.log(row.doc);
        this.currentHt_order.push(row.doc);
      });

      resolve(this.currentHt_order);

      this.db_currentHtOrder.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        this.handleChangeCurrentHtOrder(change);
        this.events.publish('currentHt_order', this.currentHt_order);
      });

    }).catch((e) => {
       /** store error on errors database */
       let line=632;
       let error = "error on  get current order";
       this.registerError(e,line,error);
       console.log(e);

    });

  });

}

/** handel changes of current Ht order  */
handleChangeCurrentHtOrder(change) {

  let changedDoc = null;
  let changedIndex = null;
if(this.currentHt_order){
  this.currentHt_order.forEach((doc, index) => {
   console.log(doc,change);
    if (doc._id === change.id) {
      changedDoc = doc;
      changedIndex = index;
    }

  });
}
  //A document was deleted
  if (change.deleted) {
    console.log(this.currentHt_order, changedIndex);
    if( changedIndex !=null){
    this.currentHt_order.splice(changedIndex, 1);
    console.log(this.currentHt_order, changedIndex);
    }
  }
  else {
    //A document was updated
    if (changedDoc) {

      this.currentHt_order[changedIndex] = change.doc;

    }

    //A document was added
    else {

      this.currentHt_order.push(change.doc);
      // this.changeDetectorRef.detectChanges();
    }

  }

}

/** fetch cahshier data */
getCounterData() {

  if (this.counter_all_data) {
    return Promise.resolve(this.counter_all_data);
  }
  return new Promise(resolve => {
    this.db_counter.allDocs({

      include_docs: true

    }).then((result) => {
      this.counter_all_data = [];
      let docs = result.rows.map((row) => {
        console.log(row.doc);
        this.counter_all_data.push(row.doc);
      });
      resolve(this.counter_all_data);
      this.db_counter.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        this.handleChange(change, this.counter_all_data);
        this.events.publish('counter_data', this.counter_all_data);

      });

    }).catch((e) => {
            /** store error on errors database */
let line=957;
let error = "error on counter_all_data function";
this.registerError(e,line,error);
console.log(e);
    });

  });

}

/** remove current order */
removeCurrentHtOrder(todo) {
  return this.db_currentHtOrder.get(todo).then((doc) => {
    this.db_currentHtOrder.remove(doc);

  }).catch((e) => {
    /** store error on errors database */
    let line = 649;
    let error = "error on  removeCurrentBotOrder function";
    // this.registerError(e, line, error, "removeCurrentHtOrder");
    console.log(e);
  });
}

 /** remove current order */
 removeCurrentBotOrder(todo) {
  return this.db_currentBotOrder.get(todo).then((doc) => {
    this.db_currentBotOrder.remove(doc);

  }).catch((e) => {
    /** store error on errors database */
    let line = 649;
    let error = "error on  removeCurrentBotOrder function";
    // this.registerError(e, line, error, "removeCurrentBotOrder");
    console.log(e);
  });

}

getPostErrors() {

  // if (this.error_data) {
  //   return Promise.resolve(this.error_data);
  // }

  // return new Promise(resolve => {
    return new Promise<any>((resolve, reject) => {

    this.db_error.allDocs({

      include_docs: true

    }).then((result) => {

      this.error_data = [];

      let docs = result.rows.map((row) => {
        //console.log("Row:",row.doc);
        this.error_data.push(row.doc);
      });
      //// console.log("Type of item_data:",this.item_data);
      resolve(this.error_data);

      this.db_error.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
        this.handleChange(change, this.error_data);
        this.events.publish('error data', this.error_data);
      });

    }).catch((e) => {
      reject(e);
      /** store error on errors database */
      let line = 1136;
      let error = "error on getMenuList function";
      this.registerError(e, line, error);
      console.log(e);
    });

  });

}

/** register error */
registerErrors(e, line, error, function_name) {
  let date = moment().format('YYYY-MM-DD');
  let time = moment().format('h:mm:ss a');
  this.timestamp = date + " at " + time;
  let param = {
    "page": "pouch-db-auth",
    "error": e,
    "timestamp": this.timestamp,
    "lineno": line,
    "appVersion": this.appVersion,
    "details": error,
    "function_name": function_name
  }
  this.postErrors(param);

}
 
/** HOTGSTSET FUNCTION USED FOR SET GST OF HOT ORDER
   * @param { Object } - gst percent .
   * @return { void } - void return
   */
  HOTGstSet(gst) {
    console.log("gst", gst);
    return this.db_counter.query(function (doc, emit) {
      emit(doc._id);
    }, { key: 'HotGst' }).then(result => {
      console.log(result);
      if (result.rows.length == 0) {
        this.db_counter.put({ gst: gst, _id: 'HotGst' }).catch(e => {
          /** store error on errors database */
          let line = 880;
          let error = "error on updates HOT gst";
          // this.registerError(e, line, error, "HOTGstSet");
          console.log(e);
        });


      }
      else {
        // alert("already present");
        this.db_counter.get('HotGst').then((doc) => {
          console.log(doc);
          if (doc) {
            doc.gst = gst;
            this.db_counter.put(doc).then(() => {
              console.log("update HOT gst");
            }).catch(e => {
              /** store error on errors database */
              let line = 843;
              let error = "error on updates HOT gst";
              // this.registerError(e, line, error, "HOTGstSet");
              console.log(e);
            });
          }
        }).catch(e => {
          /** store error on errors database */
          let line = 906;
          let error = "error on  get updates HOT gst";
          // this.registerError(e, line, error, "HOTGstSet");
          console.log(e);
        });
      }
      console.log("fount", result);
    }).catch((e) => {
      /** store error on errors database */
      let line = 915;
      let error = "error on  query updates HOT gst";
      // this.registerError(e, line, error, "HOTGstSet");
      console.log(e);
    });

  }

  /** KOTGSTSET FUNCTION USED FOR SET GST OF KOT ORDER
   * @param { Object } - gst percent .
   * @return { void } - void return
   */
  KOTGstSet(gst) {
    console.log("gst", gst);
    return this.db_counter.query(function (doc, emit) {
      emit(doc._id);
    }, { key: 'KotGst' }).then(result => {
      console.log(result);
      if (result.rows.length == 0) {
        this.db_counter.put({ gst: gst, _id: 'KotGst' }).catch(e => {
          /** store error on errors database */
          let line = 825;
          let error = "error on updates KOT gst";
          // this.registerError(e, line, error, "KOTGstSet");
          console.log(e);
        });


      }
      else {
        // alert("already present");
        this.db_counter.get('KotGst').then((doc) => {
          console.log(doc);
          if (doc) {
            doc.gst = gst;
            this.db_counter.put(doc).then(() => {
              console.log("update KOT gst");
            }).catch(e => {
              /** store error on errors database */
              let line = 843;
              let error = "error on updates KOT gst";
              // this.registerError(e, line, error, "KOTGstSet");
              console.log(e);
            });
          }
        }).catch(e => {
          /** store error on errors database */
          let line = 851;
          let error = "error on  get updates KOT gst";
          // this.registerError(e, line, error, "KOTGstSet");
          console.log(e);
        });
      }
      console.log("fount", result);
    }).catch((e) => {
      /** store error on errors database */
      let line = 860;
      let error = "error on  query updates KOT gst";
      // this.registerError(e, line, error, "KOTGstSet");
      console.log(e);
    });

  }

  /**
   * BOTServiceChargeset function is for set service charges for Bot oreder.
   * @param ServiceCharge - ServiceCharge for BOT ORDERS,
   * @return { void } -a void return .
   */
  BOTServiceChargeSet(ServiceCharge) {
    console.log(ServiceCharge);
    return this.db_counter.query(function (doc, emit) {
      emit(doc._id);
    }, { key: 'BotServiceCharge' }).then(result => {
      console.log(result);
      if (result.rows.length == 0) {
        this.db_counter.put({ ServiceCharge: ServiceCharge, _id: 'BotServiceCharge' }).catch(e => {
          /** store error on errors database */
          let line = 880;
          let error = "error on updates HOT gst";
          // this.registerError(e, line, error, "BOTServiceChargeSet");
          console.log(e);
        });
      }
      else {
        this.db_counter.get('BotServiceCharge').then((doc) => {
          console.log(doc);
          if (doc) {
            doc.ServiceCharge = ServiceCharge;
            this.db_counter.put(doc).then(() => {
              console.log("update BOTServiceCharge");
            }).catch(e => {
              /** store error on errors database */
              let line = 843;
              let error = "error on updates BOT ServiceCharge";
              // this.registerError(e, line, error, "BOTServiceChargeSet");
              console.log(e);
            });
          }
        }).catch(e => {
          /** store error on errors database */
          let line = 906;
          let error = "error on  get updates BOT ServiceCharge";
          // this.registerError(e, line, error, "BOTServiceChargeSet");
          console.log(e);
        });
      }
      console.log("fount", result);
    }).catch((e) => {
      /** store error on errors database */
      let line = 915;
      let error = "error on  query updates BOT ServiceCharge";
      // this.registerError(e, line, error, "BOTServiceChargeSet");
      console.log(e);
    });
  }
 /**
   * KOTServiceChargeset function is for set service charges for Kot oreder.
   * @param ServiceCharge - ServiceCharge for KOT ORDERS,
   * @return { void } -a void return .
   */
  KOTServiceChargeSet(ServiceCharge) {
    console.log(ServiceCharge);
    return this.db_counter.query(function (doc, emit) {
      emit(doc._id);
    }, { key: 'KotServiceCharge' }).then(result => {
      console.log(result);
      if (result.rows.length == 0) {
        this.db_counter.put({ ServiceCharge: ServiceCharge, _id: 'KotServiceCharge' }).catch(e => {
          /** store error on errors database */
          let line = 880;
          let error = "error on updates HOT gst";
          // this.registerError(e, line, error, "KOTServiceChargeSet");
          console.log(e);
        });


      }
      else {
        this.db_counter.get('KotServiceCharge').then((doc) => {
          console.log(doc);
          if (doc) {
            doc.ServiceCharge = ServiceCharge;
            this.db_counter.put(doc).then(() => {
              console.log("update KOTServiceCharge");
            }).catch(e => {
              /** store error on errors database */
              let line = 843;
              let error = "error on updates KOT ServiceCharge";
              // this.registerError(e, line, error, "KOTServiceChargeSet");
              console.log(e);
            });
          }
        }).catch(e => {
          /** store error on errors database */
          let line = 906;
          let error = "error on  get updates KOT ServiceCharge";
          // this.registerError(e, line, error, "KOTServiceChargeSet");
          console.log(e);
        });
      }
      console.log("fount", result);
    }).catch((e) => {
      /** store error on errors database */
      let line = 915;
      let error = "error on  query updates KOT ServiceCharge";
      // this.registerError(e, line, error, "KOTServiceChargeSet");
      console.log(e);
    });
  }

  /**
   * HOTServiceChargeset function is for set service charges for Hot oreder.
   * @param ServiceCharge - ServiceCharge for HOT ORDERS,
   * @return { void } -a void return .
   */
  HOTServiceChargeSet(ServiceCharge) {
    console.log(ServiceCharge);
    return this.db_counter.query(function (doc, emit) {
      emit(doc._id);
    }, { key: 'HotServiceCharge' }).then(result => {
      console.log(result);
      if (result.rows.length == 0) {
        this.db_counter.put({ ServiceCharge: ServiceCharge, _id: 'HotServiceCharge' }).catch(e => {
          /** store error on errors database */
          let line = 880;
          let error = "error on updates HOT gst";
          // this.registerError(e, line, error, "HOTServiceChargeSet");
          console.log(e);
        });


      }
      else {
        this.db_counter.get('HotServiceCharge').then((doc) => {
          console.log(doc);
          if (doc) {
            doc.ServiceCharge = ServiceCharge;
            this.db_counter.put(doc).then(() => {
              console.log("update HOTServiceCharge");
            }).catch(e => {
              /** store error on errors database */
              let line = 843;
              let error = "error on updates HOT ServiceCharge";
              // this.registerError(e, line, error, "HOTServiceChargeSet");
              console.log(e);
            });
          }
        }).catch(e => {
          /** store error on errors database */
          let line = 906;
          let error = "error on  get updates HOT ServiceCharge";
          // this.registerError(e, line, error, "HOTServiceChargeSet");
          console.log(e);
        });
      }
      console.log("fount", result);
    }).catch((e) => {
      /** store error on errors database */
      let line = 915;
      let error = "error on  query updates HOT ServiceCharge";
      // this.registerError(e, line, error, "HOTServiceChargeSet");
      console.log(e);
    });
  }

   /** get data for report  */
   async getBotDataWithin(start_time: any, end_time: any,) {
     /** not using this  */
    // let dataList: Array<any> = [];
    // //return this.db_final.query('Date', {startkey: start_time, endkey: end_time});
    // await this.db_finalBot.query({
    //   map: function (doc, emit) {
    //     if (doc.Date >= start_time && doc.Date <= end_time) {
    //       console.log(doc);
    //       dataList.push(doc);
    //       //emit(doc,1);
    //     }
    //   },
    // }).catch((e) => {
    //   /** store error on errors database */
    //   let line = 167;
    //   let error = "error on  getBotDatawithin function for report";
    //   this.registerError(e, line, error, );
    //   console.log(e);
    // });
    // return dataList;
  }

  /** get data for report  */
  async getHtDataWithin(start_time: any, end_time: any,) {
  /** not using this */
    // let dataList: Array<any> = [];
    // //return this.db_final.query('Date', {startkey: start_time, endkey: end_time});
    // await this.db_finalHt.query({
    //   map: function (doc, emit) {
    //     if (doc.Date >= start_time && doc.Date <= end_time) {
    //       console.log(doc);
    //       dataList.push(doc);
    //       //emit(doc,1);
    //     }
    //   },
    // }).catch((e) => {
    //   /** store error on errors database */
    //   let line = 167;
    //   let error = "error on  getHtDatawithin function for report";
    //   this.registerError(e, line, error, );
    //   console.log(e);
    // });
    // return dataList;
  }

  getstock(itemname:any){
    return new Promise((resolve, reject) => {
      // this.db_stock.find({
      //   selector: {itemname: {$eq: itemname}}
      // }).then((result)=> {
      //   // handle result
      //   console.log('stock data is:',result.docs);
      //   resolve(result.docs);
      // }).catch(function (err) {
      //   console.log(err);
      // });
    });
  }

  getConsumption(date:any,itemname:any){
    return new Promise((resolve, reject) => {
      this.db_consumtion.createIndex({
        index:{
          fields:['date','itemname']
        }
      }).then(()=>{
           return this.db_consumtion.find({
             selector:{
               date:{$eq:date},
               itemname:{$eq:itemname}
             }
           }).then((result)=>{
           console.log('consumption data is:',result.docs);
           resolve(result.docs);
           }).catch((e)=>{
           console.log('error while findinf is:',e);
           })
      }).catch((e)=>{
        console.log('could not find index:',e);
      })
    });
  }

  getItems(billno:any){
    return new Promise(async(resolve, reject) => {
      console.log('db is:',this.db_final);
      await this.db_final.find({
        selector: {Bill_no: {$eq: billno}}
      }).then((result)=> {
        // handle result
        console.log('stock data is:',result.docs);
        resolve(result.docs);
      }).catch(function (err) {
        console.log(err);
      });
    });
  }

  getliqItems(billno:any){
    /** not using this  */
    // return new Promise(async(resolve, reject) => {
    //   console.log('db is:',this.db_final);
    //   await this.db_finalBot.find({
    //     selector: {Bill_no: {$eq: billno}}
    //   }).then((result)=> {
    //     // handle result
    //     console.log('stock data is:',result.docs);
    //     resolve(result.docs);
    //   }).catch(function (err) {
    //     console.log(err);
    //   });
    // });
  }

  gethookahItems(billno:any){
    /** not using this */
    // return new Promise(async(resolve, reject) => {
    //   console.log('db is:',this.db_final);
    //   await this.db_finalHt.find({
    //     selector: {Bill_no: {$eq: billno}}
    //   }).then((result)=> {
    //     // handle result
    //     console.log('stock data is:',result.docs);
    //     resolve(result.docs);
    //   }).catch(function (err) {
    //     console.log(err);
    //   });
    // });
  }

  getFinalstock(itemname:any){
    return new Promise((resolve, reject) => {
      this.db_allStocks.find({
        selector: {itemname: {$eq: itemname}}
      }).then((result)=> {
        // handle result
        console.log('stock data is:',result.docs);
        resolve(result.docs);
      }).catch(function (err) {
        console.log(err);
      });
    });
  }

  getKitchenstock(itemname:any){
    return new Promise((resolve, reject) => {
      this.db_finalkitchen.find({
        selector: {itemname: {$eq: itemname}}
      }).then((result)=> {
        // handle result
        console.log('stock data is:',result.docs);
        resolve(result.docs);
      }).catch(function (err) {
        console.log(err);
      });
    });
  }

  getLiquorstock(itemname:any){
    /** not using this  */
    // return new Promise((resolve, reject) => {
    //   this.db_finalliquor.find({
    //     selector: {itemname: {$eq: itemname}}
    //   }).then((result)=> {
    //     // handle result
    //     console.log('stock data is:',result.docs);
    //     resolve(result.docs);
    //   }).catch(function (err) {
    //     console.log(err);
    //   });
    // });
  }

  /** hukkah current order */
  updateCurrentHtOrder(todo, updatelist) {

    this.db_currentHtOrder.get(todo._id).then((doc) => {
      // doc.OccupiedStatus = flage;
      doc.OrderList = updatelist;
      if (doc) {
        this.db_currentHtOrder.put(doc).then(() => {
          console.log("updates order");
        }).catch(e => {
          /** store error on errors database */
          let line = 823;
          let error = "error on  upadate order";
          // this.registerError(e, line, error, "updateCurrentHtOrder");
          console.log(e);
        });

      }
    }).catch(e => {
      /** store error on errors database */
      let line = 834;
      let error = "error on get table query";
      // this.registerError(e, line, error, "updateCurrentHtOrder");
      console.log(e);
    });


  }



    /** createMenuList for menu item store in pouchDb */
 createHookahList(data) {
   /** not using this */
  // return new Promise(async(resolve, reject) => {

  //   let Menu = {
  //     "includedgst":data.includedgst,
  //     "itemname": data.itemname,
  //     "fullplate": data.fullplate,
  //     "tobacco_type":data.tobacco_type,
  //     "description":data.description

  //   };

  //   // this.db_hookah_add_item.createIndex({
  //   //   index:{
  //   //     fields:['itemname','tobacco_type']
  //   //   }
  //   // }).then(()=>{
  //    await this.db_hookah_add_item.find({
  //       selector:{
  //         itemname:data.itemname,
  //         tobacco_type:data.tobacco_type
  //       }
  //     }).then(async(result)=>{
  //       console.log('result is:',result);
  //       console.log('result length is:',result.docs);
  //       if(result.docs.length>0){
  //         console.log('item present');
  //         reject();
  //       }else
  //       {
  //         this.db_hookah_add_item.post(Menu);
  //         resolve();
  //       let toast = await this.toastCtrl.create({
  //         message: ' Item Added Successfully.',
  //         duration: 1000,
  //         position: 'top',
  //         cssClass:'handlerclass',
  //       });
  //       await toast.present();
  //       }
  //     }).catch((e)=>{

  //     })
 
  // });
}


/** createMenuList for menu item store in pouchDb */
createLiquorList(data): Promise<any> {
  return new Promise(async(resolve, reject) => {

    // let Menu = {
    //   "itemname": data.itemname,
    //   "fullplate": data.fullplate,
    //   "fpics": data.fpics,
    //   "gstfullplate": data.gstfullplate
      
    // };
    // await this.db_liquor_add_item.find({
    //   selector:{
    //     itemname:data.itemname,
    //     gstfullplate:data.gstfullplate
    //   }
    // }).then(async(result)=>{
    //   console.log('result is:',result);
    //   console.log('result length is:',result.docs);
    //   if(result.docs.length>0){
    //     console.log('item present');
    //     reject();
    //   }else
    //   {
    //     this.db_liquor_add_item.post(Menu);
    //     resolve("hi");
    //   let toast = await this.toastCtrl.create({
    //     message: ' Item Added Successfully.',
    //     duration: 1000,
    //     position: 'top',
    //     cssClass:'handlerclass',
    //   });
    //   await toast.present();
    //   }
    // }).catch((e)=>{

    // })

});


//     this.db_liquor_add_item.query(function (doc, emit) {
//       emit(doc.itemname);
//     }, { key: data.itemname }).then(async result => {
//       if (result.rows.length == 0) {
//         this.db_liquor_add_item.post(Menu).catch(e=>{
//             /** store error on errors database */
// let line=1167;
// let error = "error on createMenuList put";
// this.registerError(e,line,error);
// console.log(e);
//         });
//         resolve();
//         let toast = await this.toastCtrl.create({
//           message: ' Table Added Successfully.',
//           duration: 2000,
//           position: 'top',
//           cssClass:'handlerclass',
//         });
//         await toast.present();
//       }
//       else {
//         reject("already present");
//       }
//     }).catch((e) => {
//        /** store error on errors database */
//        let line=1188;
//        let error = "error on query createMenuList ";
//       this.registerError(e,line,error);
//       console.log("error", e);
//     });

  
}


customerInfo(todo) {
//   return this.db_customer_info.query(function (doc, emit) {
//     emit(doc.phone);
//   }, { key: todo.phone }).then(async (result) => {
//     console.log("db_customer_info");
    
//     if (result.rows.length == 0) {
//       console.log("createCustomerInfo");
      
//       this.db_customer_info.post(todo);
//       let toast = await this.toastCtrl.create({
//         message: 'Customer Added Successfully.',
//         duration: 2000,
//         position: 'top',
//         cssClass:'handlerclass',
//       });
//       toast.onDidDismiss(
//       //   () => {
//       //   console.log('Dismissed toast');
//       // }
//       );
//       await toast.present();
//     }
//     else {
//     //  alert("already present");
//     }
//     console.log("fount", result);
//   }).catch((e) => {
//        /** store error on errors database */
// let line=934;
// let error = "error on customerInfo function";
// this.registerError(e,line,error);
// console.log(e);

//   });


}


getCustomerInfo() {

//   if (this.customer_info) {
//     return Promise.resolve(this.customer_info);
//   }
//   return new Promise(resolve => {
//     this.db_customer_info.allDocs({

//       include_docs: true

//     }).then((result) => {
//       this.customer_info = [];
//       let docs = result.rows.map((row) => {
//         console.log(row.doc);
//         this.customer_info.push(row.doc);
//       });
//       resolve(this.customer_info);
//       this.db_customer_info.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
//         this.handleChange(change, this.customer_info);
//         this.events.publish('customerInfo_data', this.customer_info);

//       });

//     }).catch((e) => {
//             /** store error on errors database */
// let line=957;
// let error = "error on getcustomer function";
// this.registerError(e,line,error);
// console.log(e);
//     });

//   });

}



addDelivaryBoy(todo) {
  return this.db_add_delivary_info.query(function (doc, emit) {
    emit(doc.phone);
  }, { key: todo.phone }).then(async (result) => {
    console.log("db_add_delivary_info");
    
    if (result.rows.length == 0) {
      console.log("add delivary info");
      
      this.db_add_delivary_info.post(todo);
      let toast = await this.toastCtrl.create({
        message: 'Delivary Boy Added Successfully.',
        duration: 2000,
        position: 'top',
        cssClass:'handlerclass',
      });
      toast.onDidDismiss(
      //   () => {
      //   console.log('Dismissed toast');
      // }
      );
      await toast.present();
    }
    else {
    //  alert("already present");
    }
    console.log("fount", result);
  }).catch((e) => {
       /** store error on errors database */
let line=934;
let error = "error on add delivary add";
this.registerError(e,line,error);
console.log(e);

  });


}



getCurrentOrder(){

//   if (this.current_order) {
//     return Promise.resolve(this.current_order);
//   }

//   return new Promise(resolve => {

//     this.db_currentOrder.allDocs({

//       include_docs: true

//     }).then((result) => {

//       this.current_order = [];

//       let docs = result.rows.map((row) => {
//         //console.log("Row:",row.doc);
//         this.current_order.push(row.doc);
//       });
//      //// console.log("Type of item_data:",this.item_data);
//      this.currentOrderObj = of (this.current_order);
//       resolve(this.current_order);

//       this.db_currentOrder.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
//         console.log("current Order changes");
//         //Call Notification Function Here
//         this.handleChangeCurrentOrder(change);
//         this.events.publish('menuList', this.current_order);
//       });

//     }).catch((e) => {
//        /** store error on errors database */
// let line=1136;
// let error = "error on getMenuList function";
// this.registerError(e,line,error);
// console.log(e);
//     });

//   });


}

getCurrentOrderDeliveryBoy(todo){
console.log("todo",todo);
//   if (this.current_order) {
//     return Promise.resolve(this.current_order);
//   }

//   return new Promise(resolve => {

//     this.db_currentOrder.get(todo).then((result) => {

//       this.current_order = [];

//       let docs = result.rows.map((row) => {
//         //console.log("Row:",row.doc);
//         this.current_order.push(row.doc);
//         console.log("current order",this.current_order);
//       });
//      //// console.log("Type of item_data:",this.item_data);
//       resolve(this.current_order);

//       this.db_currentOrder.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
//         this.handleChange(change, this.current_order);
//         this.events.publish('current_order', this.current_order);
//       });

//     }).catch((e) => {
//        /** store error on errors database */
// let line=1136;
// let error = "error on getMenuList function";
// this.registerError(e,line,error);
// console.log(e);
//     });

//   });


}


getCurrentOrderByDeliveryBoy(deliveryboy) {

    return new Promise((resolve, reject) => {
      // this.db_currentOrder.find({
      //   selector: {deliveryBoy: {$eq: deliveryboy}}
      // }).then((result)=> {
      //   // handle result
      //   console.log('stock data is:',result.docs);
      //   resolve(result.docs);
      // }).catch(function (err) {
      //   console.log(err);
      // });
    });



}

getOrderHistoryByDeliveryBoy(deliveryboy) {
  console.log(deliveryboy);
  return new Promise((resolve, reject) => {
    // this.db_final.find({
    //   selector: {deliveryBoy: {$eq: deliveryboy}}
      
    // }).then((result)=> {
    //   // handle result
    //   console.log('final data is:',result.docs);
    //   resolve(result.docs);
    // }).catch(function (err) {
    //   console.log(err);
    // });
  });



}

//   return new Promise((resolve, reject) => {
//   //** to serach table by its name */
//   this.db_currentOrder.query(function (doc, emit) {
//     emit(doc.deliveryBoy);
//   }, { key: todo }).then(result => {
//     console.log("fount", result.rows);
//     resolve(result.rows);

//   }).catch((e) => {
//         /** store error on errors database */
// let line=800;
// let error = "error on query on table";
// this.registerError(e,line,error);
// console.log(e);

//   });

// //   this.db_tables.put(todo).catch((e) => {
// //          /** store error on errors database */
// // let line=809;
// // let error = "error on  put tables";
// // this.registerError(e,line,error);
// // console.log(e);
// //   });

// });

// }




/** create resto information */
async restoInfo(RestoData) {
  //this.cloudant_auth = this.appServiceProvider.getCloudantAuth();
  this.url = this.cloudant_auth.url;
    this.username=this.cloudant_auth.username;
    this.password=this.cloudant_auth.password;

    let options = {
      live: true,
      retry: true,
      continuous: true,
      checkpoint:'source',
      auth: {
        username: this.username,
        password: this.password
      }
    };


  this.db_restoInfo = new PouchDB("RestoInfo")
  return new Promise(resolve => {
    // console.log("put resto info", RestoData);
    // this.db_restoInfo.post(RestoData).then(async (data) => {
    //  let toast = await this.toastCtrl.create({
    //    message: 'Restaurant Update Successfully.',
    //    duration: 2000,
    //    position: 'middle',
    //    cssClass: 'handlerclass',
    //  });
    //   await toast.present().then(()=>{
    //     resolve("Resolved");

    //     this.db_restoInfo.sync("RestoInfo", options).on('change', (info)=> {
    //       // handle change
    //     }).on('paused', (err) =>{
    //       // replication paused (e.g. replication up to date, user went offline)
    //     }).on('active', ()=> {
    //       // replicate resumed (e.g. new changes replicating, user went back online)
    //     }).on('denied', (err) =>{
    //       // a document failed to replicate (e.g. due to permissions)
    //     }).on('complete',  (info) =>{
    //       console.log('sync complete');
    //       // handle complete
    //     }).on('error',  (err)=> {
    //       // handle error
    //     });

    //   });
      
    // }).catch((e) => {
    //   /** store error on errors database */
    //   let line = 1012;
    //   let error = "error on createResto function";
    //   this.registerError(e, line, error);
    //   console.log(e);
    //   reject("Rejected");

    // });

  });


 }

/** get Resto info  */
//  getResto() {

//    if (this.resto_data) {
//      return Promise.resolve(this.resto_data);
//    }
//    return new Promise(resolve => {
//      this.db_resto.allDocs({

//        include_docs: true

//      }).then((result) => {
//        //this.resto_data = [];
//        let docs = result.rows.map((row) => {
//          console.log("got info", row.doc);
//          this.resto_data = row.doc;
//        });
//        resolve(this.resto_data);
//        this.db_resto.changes({ live: true, since: 'now', include_docs: true }).on('change', (change) => {
//          this.handleChangeResto(change, this.resto_data);
//          this.events.publish('resto_data', this.resto_data);
//        });

//      }).catch((e) => {
//          /** store error on errors database */
//  let line=1058;
//  let error = "error on query on resto info function";
//  this.registerError(e,line,error);
//  console.log(e);
//      });

//    });

//  }



}
